import React from "react";
import {Prompt, withRouter} from "react-router";
import PropTypes from "prop-types";
import {
    ANNOTATION_TOOL_TYPE, CHARACTERIZATION_TOOL_TYPE,
    CONFIRMATION_TOOL_TYPE,
    IDENTIFICATION_TOOL_TYPE,
    REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED,
    REQUEST_STATUS_SUCCESS,
    SEGMENTATION_TOOL_TYPE,
    SLICE_SELECTOR_TOOL_RANGED,
    SLICE_SELECTOR_TOOL_TYPE,
    SLICE_SELECTOR_WITH_PICKER
} from "../../../../Constants";
import ConfirmationTool from "./ConfirmationTool";
import {ContainerResultsPage} from "../../containers/ContainerResultsPage";
import {ContainerManualTool} from "../../containers/ContainerManualTool";
import {Link} from "react-router-dom";

const DONE = 'DONE';

/**
 * Component providing template for tools.
 * Uses tool type to provide template specific to a given activity.
 * In the future templates are going to be replaced with a single Manual Task Configuration Tool template.
 * Can be used for demos.
 */
class ManualToolDispatcher extends React.Component {

  constructor(props) {
    super(props);
    this.getTool = this.getTool.bind(this);
  }

    /** This is
     *
     * http://localhost:3000/tool/mw1/b9e5860dc809e082903c96e3e1b10374/1276951db03f1254a04e0b6df133baa7 */
    componentDidMount() {
        const {getManualTask, getManualTaskLivePresenter, match} = this.props;
        if (!match.path.includes("playskill")) {
            getManualTask(
                match.params['miniWorkflowSetId'],
                match.params['miniWorkflowKey'],
                match.params['experimentId']);
        }
        else {
            getManualTaskLivePresenter(
                this.props.livePresenterExperimentId,
                this.props.currentStep,
                this.props.miniWorkflowSetId,
                this.props.miniWorkflowNameInSet
            );
        }
    }
    componentDidUpdate(prevProps,prevState,snapshot) {
        const {currentStep,manualTaskState} = this.props;

    }

    componentWillUnmount() {
        const {clearManualToolState,clearManualTaskState} = this.props;
        clearManualToolState();
        clearManualTaskState();
    }

    getTool() {
        const {workload,manualTaskState} = this.props;

        if
            (workload != null && Array.isArray(workload.listOfCases) && workload.listOfCases.every((el)=>{return el.caseStatus.toUpperCase()==="DONE"}))

            return  (<div style={{display: "table",width:"100%",height:"100%"}}>

            <div style={{fontSize:"xx-large", display:"table-cell", verticalAlign:"middle",textAlign:"center"}}>

                    All the cases have been done.
                <br/>Please go to  <Link to="/home">dashboard.</Link>

            </div>

        </div>);

        if (workload != null && workload.miniWorkflow !=null) {
            const currentTool = workload.miniWorkflow.currentTool;


            switch (currentTool['type']) {
                case SLICE_SELECTOR_TOOL_TYPE:
                case SLICE_SELECTOR_TOOL_RANGED:
                case SEGMENTATION_TOOL_TYPE:
                case ANNOTATION_TOOL_TYPE:
                case IDENTIFICATION_TOOL_TYPE:
                case CHARACTERIZATION_TOOL_TYPE:
                case CONFIRMATION_TOOL_TYPE:
                case SLICE_SELECTOR_WITH_PICKER:{
                    return <ContainerManualTool/>;
                }
                // case SEGMENTATION_TOOL_TYPE: {
                //     return <SegmentationTool {...this.props}/>;
                // }
                // case CONFIRMATION_TOOL_TYPE: {
                //     return <ConfirmationTool {...this.props}/>; //Confirmation Tool to remove!
                // }
                default:
                  return <div> Unknown tool type: "{currentTool['type']}". </div>;
            }
        }
        return null;
    }


    render() {

    const {workload, manualTaskState, taskId} = this.props; //taskId used only for DEMO!!!!

    const errorMessage = (workload!=null && workload.response!=null && workload.response.data!=null)?
      workload.response.data.message:"Connection error. Please try again or contact wih Administrator.";

    return (
      <React.Fragment>
        {(manualTaskState === REQUEST_STATUS_SUCCESS || manualTaskState === REQUEST_STATUS_FAIL) && this.getTool()}

          {manualTaskState === REQUEST_STATUS_REQUESTED &&
          <div className="spinner-centered"> Loading data: <i className="fa fa-spinner fa-spin"/></div>
          }
          {manualTaskState === REQUEST_STATUS_FAIL &&
          <div className="spinner-centered"> {errorMessage} <i className="fa fa-exclamation"/></div>
          }
      </React.Fragment>
    )
  }

}

export default withRouter(ManualToolDispatcher);

ManualToolDispatcher.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getAnnotations: PropTypes.func.isRequired,
  updateAnnotationData: PropTypes.func.isRequired,
  rmCallback: PropTypes.func.isRequired,
  saveOverlayData: PropTypes.func,
  clearAll: PropTypes.func.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  setActiveAnnotation: PropTypes.func.isRequired,
  clearAnnotatorState: PropTypes.func.isRequired,
  clearManualToolState:PropTypes.func.isRequired,
  clearManualTaskState:  PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  viewersState: PropTypes.array.isRequired,  //array containing state of all viewers
  updateViewerProperty: PropTypes.func.isRequired,
  updateViewer: PropTypes.func,
  imageOptions: PropTypes.array.isRequired,
  changeImageType: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  overlays: PropTypes.array.isRequired,
  changeOrientation: PropTypes.func.isRequired,
  manualToolConfiguration: PropTypes.object.isRequired,
  manualToolConfigurationState: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,//just for demo!!!!
  manualTaskState: PropTypes.string.isRequired,
  workload: PropTypes.object.isRequired,
  experimentId: PropTypes.string.isRequired,
  livePresenterExperimentId: PropTypes.string.isRequired,
  miniWorkflowSetId: PropTypes.string.isRequired,
  miniWorkflowNameInSet: PropTypes.string.isRequired,
  taskNameInMiniWorkflow: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired
};


