import {connect} from "react-redux";
import {
    clearAnnotatorState, clearManualToolStateAC, goToNextStep, goToPreviousStep,
    initializeTool, saveOutputAndSubmit,
    updateManualTaskState, updateManualToolProperty,
} from "../action/ManualToolAction";
import ManualToolDispatcher from "../component/annotationTool/ManualToolDispatcher";
import {
    clearManualTaskAC,
    getAnotherCase,
    getManualTask,
    getManualTaskLivePresenter, recreateTrainingWorkflowInLP, setTutorialVideoState
} from "../../dashboard/action/ManualTaskAction";
import {
  addAnnotation,
  clearAllAnnotations,
  removeAnnotation,
  setActiveAnnotation,
  setSubAnnotation,
  updateAnnotationData
} from "../action/AnnotationAction";
import {
    changeOrientation,
    clearViewersState,
    handleKeySlicing,
    updateSingleViewer,
    updateViewerProperty, updateViewersState
} from "../action/ViewerAction";
import {
  saveOverlayData
} from "../action/ImageAction";


const mapStateToProps = (state, ownProps) => {
  return {
    columns: state.visu.annotations.annotationsDefinition,
    data: state.visu.annotations.annotationsData,
    images: state.visu.images.images,
    overlays: state.visu.images.overlays,
    imageOptions: state.visu.viewers.imageOptions,
    viewersState: state.visu.viewers.viewersState,
    manualToolConfigurationState: state.visu.annotations.manualToolConfigurationState, //deprecated - just for demo
    manualToolConfiguration: state.visu.annotations.manualToolConfiguration, //deprecated - just for demo
    taskId: ownProps.taskId,
    manualTaskState: state.activity.manualTask.manualTaskState,
    workload: state.activity.manualTask.manualTask,
    manualTool: state.visu.manualTool,
    experimentId: ownProps.experimentId,
    livePresenterExperimentId: ownProps.livePresenterExperimentId,
    miniWorkflowSetId: ownProps.miniWorkflowSetId,
    miniWorkflowNameInSet: ownProps.miniWorkflowNameInSet,
    taskNameInMiniWorkflow: ownProps.taskNameInMiniWorkflow,
    currentStep: ownProps.currentStep
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAnnotations: (id) => {
      dispatch(getAnnotations(id))
    },
    updateAnnotationData: (data) => {
      dispatch(updateAnnotationData(data))
    },
    rmCallback: (data) => {
      dispatch(removeAnnotation(data))
    },
    saveOverlayData: (imageData, id) => {
      dispatch(saveOverlayData(imageData, id))
    },
    clearAll: () => {
      dispatch(clearAllAnnotations())
    },
    addAnnotation: (roi, index) => {
      dispatch(addAnnotation(roi, index))
    },
    setActiveAnnotation: (annotation) => {
      dispatch(setActiveAnnotation(annotation,true))
    },
    setSubAnnotation: (annotation) => {
      dispatch(setSubAnnotation(annotation))
    },
    clearAnnotatorState: () => {
      dispatch(clearAnnotatorState())
    },
    clearViewersState: () => {
      dispatch(clearViewersState())
    },
    initializeTool: () => {
      dispatch(initializeTool())
    },
    // initializeResultsPage: (livePresenterExperimentId)=>{
    //   dispatch(initializeResultsPage(livePresenterExperimentId))
    // },
    updateViewer: (viewer, index) => {
      dispatch(updateSingleViewer(viewer, index))
    },
    updateViewersState: (viewers) => {
          dispatch(updateViewersState(viewers))
    },
    updateViewerProperty: (index, property, value) => {
      dispatch(updateViewerProperty(index, property, value))
    },
    changeOrientation: (index, value) => {
      dispatch(changeOrientation(index, value))
    },
    updateManualTask: (task) => {
      dispatch(updateManualTaskState(task))
    },
    getAnotherCase: (uuid, mfKey, expId, caseNumber) => {
      dispatch(getAnotherCase(uuid, mfKey, expId, caseNumber))
    },
    getManualTask: (uuid, mfKey, expId) => {
      dispatch(getManualTask(uuid, mfKey, expId))
    },
    getManualTaskLivePresenter: (livePresenterExperimentId,
       currentStep,
       miniWorkflowSetId,
       miniWorkflowNameInSet) => {
      dispatch(getManualTaskLivePresenter(livePresenterExperimentId,
        currentStep,
        miniWorkflowSetId,
        miniWorkflowNameInSet))
    },
    saveOutputAndSubmit: (experimentId, miniWorkflowKey, miniWorkflowSetId,onNext) => {
      dispatch(saveOutputAndSubmit(experimentId, miniWorkflowKey, miniWorkflowSetId,onNext))
    },
    goToPreviousStep: () => {
      dispatch(goToPreviousStep())
    },
    goToNextStep: () => {
      dispatch(goToNextStep())  //unused, fetaure for the future
    },
    handleKeySlicing: (viewerId, event) => {
      dispatch(handleKeySlicing(viewerId, event))
    },
    updateManualToolProperty: (key, property, value) => {
      dispatch(updateManualToolProperty(key, property, value))
    },
    clearManualToolState: () => {
      dispatch(clearManualToolStateAC())
    },
    clearManualTaskState: () => {
      dispatch(clearManualTaskAC())
    },
    setTutorialVideoState:(uuid)=>{
      dispatch(setTutorialVideoState(uuid))
    },
    recreateTrainingWorkflowInLP :  (experimentId,miniWorkflowSet,miniWorkflowKey) =>{
      dispatch (recreateTrainingWorkflowInLP (experimentId,miniWorkflowSet,miniWorkflowKey))
    },
  }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerManualToolDispatcher = containerCreator(ManualToolDispatcher);

