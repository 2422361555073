import {
    ACTIVITY_LIST_FAIL,
    ACTIVITY_LIST_REQUEST, ACTIVITY_LIST_SUCCESS, CLEAR_MANUAL_TASK, MANUAL_TASK_ACK_FAIL, MANUAL_TASK_ACK_REQUEST,
    MANUAL_TASK_ACK_SUCCESS, MANUAL_TASK_GET_FAIL,
    MANUAL_TASK_GET_REQUEST,
    MANUAL_TASK_GET_SUCCESS,
    MANUAL_TASK_LIST_GET_FAIL,
    MANUAL_TASK_LIST_GET_REQUEST,
    MANUAL_TASK_LIST_GET_SUCCESS, MANUAL_TASK_UPDATE_SUBTASK,
    SET_ACTIVITY_SORTER,
    SET_ACTIVITY_VISIBILITY_FILTER, SET_MANUAL_TASK_VISIBILITY_FILTER, SET_MANUAL_TASK_VISIBILITY_SORTER
} from '../action/actionType'
import {combineReducers} from "redux";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";



const setVisibilityFilter = (state, action) => {

         return Object.assign({}, state, {
             visibilityFilter: action.filter,
         });

};

const setSorter = (state, action) => {

    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};


const activityListReducer = (state = {activitiesState: '', activities: [], visibilityFilter:'SHOW_ALL', sorter:'SORT_BY_NAME_DESC'}, action) => {
    switch (action.type) {
        case ACTIVITY_LIST_REQUEST:
            return Object.assign({}, state, {
                activitiesState: 'requested',
            });
        case ACTIVITY_LIST_SUCCESS:
            return Object.assign({}, state, {
                activitiesState: 'success',
                activities: action.activities
            });

        case ACTIVITY_LIST_FAIL:
            return Object.assign({}, state, {
                activitiesState: action.err,
            });

        case SET_ACTIVITY_VISIBILITY_FILTER:
            return setVisibilityFilter(state, action);

        case SET_ACTIVITY_SORTER:
            return setSorter(state, action);

        default:
            return state;
    }
};

const activityReducer = (state = {activityState: '', activity: {}}, action) => {
    switch (action.type) {
        case 'ACTIVITY_REQUEST':
            return Object.assign({}, state, {
                activityState: 'requested'
            });
        case 'ACTIVITY_FAIL':
            console.log('Project request failed');
            return Object.assign({}, state, {
                activityState: 'failure',
                activity: null
            });
        case 'ACTIVITY_SUCCESS':
            console.log('Project request succeeded');
            return Object.assign({}, state, {
                activityState: 'success',
                activity: action.activity
            });
        default:
            return state;
    }
};



const setMTVisibilityFilter = (state, action) => {
    return Object.assign({}, state, {
        visibilityFilter: action.filter,
    });

};

const setMTSorter = (state, action) => {
    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};

const manualTasksListReducer = (state = {manualTasksState: '', manualTasks: [], visibilityFilter:'SHOW_ALL', sorter:'SORT_BY_NAME_DESC'}, action) => {
    switch (action.type) {
        case MANUAL_TASK_LIST_GET_REQUEST:
            return Object.assign({}, state, {
                manualTasksState: 'requested',
            });
        case MANUAL_TASK_LIST_GET_SUCCESS:
            return Object.assign({}, state, {
                manualTasksState: 'success',
                manualTasks: action.manualTasks
            });

        case MANUAL_TASK_LIST_GET_FAIL:
            return Object.assign({}, state, {
                manualTasksState: action.err,
            });

        case SET_MANUAL_TASK_VISIBILITY_FILTER:
            return setMTVisibilityFilter(state, action);

        case SET_MANUAL_TASK_VISIBILITY_SORTER:
            return setMTSorter(state, action);

        default:
            return state;
    }
};

/**
 * manualTaskState: '',
 * manualTask: {},
 * ackSignalState:'', values;,'requested', 'failure' - acknowledgment signal after saving manual task (sending output) (success is not used - future feature)
 * nack:{} - not acknowledgment - object keeping error information
 *
 * @param state
 * @param action
 * @return {*}
 */
const manualTaskReducer = (state = {
    manualTaskState: '',
    manualTask: {},
    ackSignalState:'',
    nack:{},
    subTask:null
    }, action) => {
    switch (action.type) {
        case CLEAR_MANUAL_TASK:
            return Object.assign({}, state, {
                manualTaskState: '',
                manualTask: {},
                ackSignalState:'',
                nack:{},
                subTask:null
            });
        case MANUAL_TASK_GET_REQUEST:
            return Object.assign({}, state, {
                manualTaskState: REQUEST_STATUS_REQUESTED
            });
        case MANUAL_TASK_GET_SUCCESS:
            return Object.assign({}, state, {
                manualTaskState: REQUEST_STATUS_SUCCESS,
                manualTask: action.manualTask
            });
        case MANUAL_TASK_GET_FAIL:
            return Object.assign({}, state, {
                manualTaskState: REQUEST_STATUS_FAIL,
                manualTask:action.err
            });
        case MANUAL_TASK_ACK_REQUEST:
            return Object.assign({}, state, {
                ackSignalState: REQUEST_STATUS_REQUESTED
            });
        case MANUAL_TASK_ACK_FAIL:
            return Object.assign({}, state, {
                ackSignalState: REQUEST_STATUS_FAIL,
                nack:action.err
            });
        case MANUAL_TASK_ACK_SUCCESS: //clearing state
        return Object.assign({}, state, {
                ackSignalState: '',
                nack:{}
        });
        case MANUAL_TASK_UPDATE_SUBTASK:
        return Object.assign({}, state, {
                subTask:{"name":action.name}
        });
        default:
            return state;
    }
};

export const ActivityReducer = combineReducers({
    activities: activityListReducer,
    activity: activityReducer,
    manualTasks: manualTasksListReducer,
    manualTask: manualTaskReducer
});