import axios from 'axios'
import {
  UPDATE_FORMS_STATE,
  UPDATE_SINGLE_FORM_PROPERTY,
  UPDATE_SINGLE_FORM_STATE
} from "./actionType";
import {getNested} from "../../helpers/comparators";
import {getNestedProp} from "../../helpers/expressions";

const updateSingleFormState = (form, id) => ({
  type: UPDATE_SINGLE_FORM_STATE,
  form,
  id
});
export const updateFormProperty = (id, property, value) => ({
  type: UPDATE_SINGLE_FORM_PROPERTY,
  id,
  property,
  value
});
export const updateFormsState = (forms) => ({
  type: UPDATE_FORMS_STATE,
  forms
});


const PATH_TO_GET_COLUMN_DEFINITION = "/api/annotation-table-column-definition/";

/**
 * Helper function to use binding between template and forms json configuration.
 * @param formBindings
 * @param originalKey
 * @return {string | undefined}
 */
const getFormKeyForTemplate = (formBindings, originalKey) => {
  return Object.keys(formBindings).find(key => formBindings[key] === originalKey);
};


/**
 *  Parses viewers configuration and updates viewers state in Store.
 */
export const initializeForms = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const formConfiguration = getNested(mTask, "miniWorkflow.currentTool.configuration.forms.renderForms");

    if (!(formConfiguration!=null)) return;

    const formBindings = mTask.miniWorkflow.currentTool.configuration.forms.layout.layoutOrder;
    const iSOF = {};    //initialStateOfForms
    Object.keys(formConfiguration).forEach((el) => {
      const key = getFormKeyForTemplate(formBindings, el);
      iSOF[key] = {
        id:key,
        annotationTableColumnDefinitionKey : formConfiguration[el].annotationTableColumnDefinitionKey
      };
   });
    dispatch(updateFormsState(iSOF));

    Object.values(iSOF).forEach(el=>{dispatch(parseAndLoadForm(mTask,el))}); // Load data in async mode

  }
};



/**
 * ActionCreator for updating only one property in formsState.
 * @returns {function(*)}
 */
export const clearFormsState = () => {
  return (dispatch) => {
    dispatch(updateFormsState({}));
  };
};


/**
 * Parses and loads inputs of form type
 * @param mTask
 * @param {object} element of form state
 */
const parseAndLoadForm = (mTask, element) => {
  return (dispatch,getState) => {
    const inputs = mTask.miniWorkflow.currentMaterializedTask.inputs;
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;

    const url = PATH_TO_GET_COLUMN_DEFINITION + getNestedProp([element.annotationTableColumnDefinitionKey,"value"],inputs);

    return axios.get(url).then(response => {
        console.log('FormsAction.js :: parseAndLoadForm ::  Before success');
        const form = Object.assign(response.data.definition,{id:element.id});
        dispatch(updateSingleFormState(form,element.id));
    }).catch(err => console.log("Error"));

  };
};
