import React from 'react';
import {
    ANNOTATION_OPACITY_TOOL,
    CROSS_HAIR_TOOL,
    LABELMAP_LUT_TOOL,
    LABELMAP_OPACITY_TOOL,
    MAIN_TOOL,
    MAIN_TOOL_PROPERTY_NAME_ACTIVE_VIEWER_ID,
    POINTER_TOOL,
    PIN_TOOL,
    PROJECTION_TOOL,
    CUSTOM_CURSOR_TOOL,
    FIDUCIAL_TOOL,
    ECHO_TOOL, RULER_TOOL, SAMPLER_TOOL, INFO_TOOL
} from "../../../../Constants";
import {calcRulers, createViewerConfiguration} from "../../../vtk/SpineVTKHelper";
import {alignViewers, getCroppingValues, hasCroppingHelper} from "../../action/ViewerAction";
import {SLICING_RANGE} from "../../action/ManualToolAction";

const Viewer2D =  React.lazy(() => import("../../../vtk/Viewer2D")); //dynamic import
const Viewer3D =  React.lazy(() => import("../../../vtk/Viewer3D"));
const Video =  React.lazy(() => import("../../../videoAnnotator/ViewerVideo"));
const Audio =  React.lazy(() => import("../../../audioAnnotator/ViewerAudio"));
const Brain =  React.lazy(() => import("../../../vtk/directedGraph/ViewerGraph"));

/**
 * Factory for creating browser object.
 *
 */
export default class BrowserFactory {

    static build(el, toolProps, toolState) {
        const {alignViewers, addAnnotation, activeROI, annotationsFormData, annotationsData, annotationsReadData, readOnly,
            viewersState,changeCase,demoCase,manualToolState,updateViewer,pickerCallback,
            updateViewerProperty,imageOptions,changeScene,images,changeOrientation,rmCallback,polydatas,
            setSubAnnotation, addSubAnnotationForActiveAnnotation, setActiveAnnotation,updateManualToolProperty,updateAnnotationFormData,updateAnnotationData,
            manualToolConfiguration,handleKeySlicing,updateManualSubTask,wlCallback,manualTask,scenes,updateActiveLayer,updateInteractiveLayer,updateActiveColorInScene,
            updateColorMapInScene, setQuantiles,messageQueue} =toolProps;
        const {activeViewerId,leftButtonMode,annotationsVisible,projectionsVisible} = toolProps.manualToolState[MAIN_TOOL];
        const sceneConfiguration = manualTask.miniWorkflow.currentTool.configuration.scenes;
        const inputs = manualTask.miniWorkflow.currentMaterializedTask.inputs;

        if (!viewersState.hasOwnProperty(el))
            return null;

        let getImage =(key)=>{
            if (images!=null && viewersState[el]['sceneId']!=null && sceneConfiguration[viewersState[el]['sceneId']] !=null) {
                let imgId= inputs[sceneConfiguration[viewersState[el]['sceneId']]['primaryImageInputKey']].value;
                return images[imgId];
            }
                else return null;
        };


        let scene = scenes[viewersState[el]['sceneId']];

        const commonProps = {
            key:el,
            id:el,
            image:getImage(el),
            opacity: (manualToolState[LABELMAP_OPACITY_TOOL]!=null)?manualToolState[LABELMAP_OPACITY_TOOL][LABELMAP_OPACITY_TOOL]*100:100,
            pinOpacity:(manualToolState[ANNOTATION_OPACITY_TOOL]!=null)?manualToolState[ANNOTATION_OPACITY_TOOL][ANNOTATION_OPACITY_TOOL]*100:100,
            activeLayer:scene!=null?scene['activeLayer']:null,
            interactiveLayer:(scene!=null && scene['interactiveLayer']!=null)?scene['interactiveLayer']:0,
            painters:scene!=null?scene['painters']:null,
            colorMaps:scene!=null?scene['colorMaps']:null,
            polydataIds:  scene!=null?scene['polydatas']:null,
            polydatas,
            masks:scene!=null?scene['masks']:null,
            primaryImage:scene!=null?scene['primaryImage']:null,
            imageOptions:viewersState[el]['imageOptions'],
            updateViewer:(viewerState) => updateViewer(el, viewerState),
            updateViewerProperty:(property, value) => updateViewerProperty(el, property, value),
            smoothing:viewersState[el]['smoothing'],
            changeSmoothing:()=>updateViewerProperty(el,'smoothing',!viewersState[el]['smoothing']),
            setLinkViewers:()=>updateViewerProperty(el,'linked',!viewersState[el]['linked']),
            linkViewers: viewersState[el]['linked'],
            showRim:(viewersState[el]['hasRim']!=null)?viewersState[el]['hasRim']:false,
            hasControlPanel:(viewersState[el]['hasControlPanel']!=null)?viewersState[el]['hasControlPanel']:true,
            customCursor:(manualToolState[CUSTOM_CURSOR_TOOL]!=null)?manualToolState[CUSTOM_CURSOR_TOOL]:'crosshair',
            fiducialTool:manualToolState[FIDUCIAL_TOOL],
            pinTool:manualToolState[PIN_TOOL],
            echoTool:manualToolState[ECHO_TOOL],
            rulerTool:manualToolState[RULER_TOOL],
            updateManualToolProperty: updateManualToolProperty,
            alignViewers:alignViewers,
            updateManualSubTask:updateManualSubTask,
            updateAnnotationFormData,
            updateAnnotationData,
            wlCallback:wlCallback,
            updateActiveLayer,
            updateInteractiveLayer,
            updateActiveColorInScene,
            updateColorMapInScene,
            viewerState:viewersState[el],
            scene:scene,
            samplerTool:manualToolState[SAMPLER_TOOL],
            manualToolState,
            setQuantiles,
            messageQueue:messageQueue
        };

        const crosshairWidgetOn = manualToolState[CROSS_HAIR_TOOL]!=null
            ? manualToolState[CROSS_HAIR_TOOL][CROSS_HAIR_TOOL]
            : false;

        const viewerCrosshairInteractionOn = !(viewersState[el][CROSS_HAIR_TOOL]!=null)
            ? true //if configuration does not exist than interaction is on
            : viewersState[el][CROSS_HAIR_TOOL]['controlEnabled']; // else check if  control is "on"

        const crosshairs = ()=>{
            if (crosshairWidgetOn && viewerCrosshairInteractionOn && activeViewerId!=null) {
                //if there is individual configuration then calculate rulers for all elements
                if (viewersState[el][CROSS_HAIR_TOOL]!=null && viewersState[el][CROSS_HAIR_TOOL]['defaultValue']!=null)
                {
                    let viewersStateForRulers = {};
                    //check viewers
                    if (viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['viewers']!=null){
                        Object.keys(viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['viewers']).forEach((vel)=>{
                            let v = Object.assign({},viewersState[vel]);
                               v['color'] = viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['viewers'][vel]['color'];
                               viewersStateForRulers[vel]=v;
                            }
                        )
                    }
                    //check viewers limits
                    if (viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['limits']!=null){
                        Object.keys(viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['limits']).forEach((lel)=>{
                            Object.keys(viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['limits'][lel]).forEach((lim)=>{
                                let lv = createViewerConfiguration(viewersState[el][CROSS_HAIR_TOOL]['defaultValue']['limits'][lel][lim]['color'],
                                    SLICING_RANGE[lim].orientation,
                                    viewersState[lel][lim],
                                    getImage(el));
                                if (lv!=null)
                                    viewersStateForRulers[lel+'_'+lim] = lv;
                            });
                            }
                        )
                    }
                    viewersStateForRulers[el] = Object.assign({},viewersState[el]);
                    return calcRulers(el, activeViewerId, viewersStateForRulers, images);
                }
                else //if no defined configuration than use "standard behavior" logic - calculate rulers for all viewers
                   return calcRulers(el, activeViewerId, viewersState, images);
            }
            else
                return null;
        };


        switch (viewersState[el]['type'].toUpperCase()) {
            case "2D":
                return (<React.Suspense fallback={<p>Loading</p>}>
                    <Viewer2D
                        {...commonProps}
                        hasGlass={true}
                        isOrientationControlDisabled={!viewersState[el]['isOrientationControlEnabled']}
                        isOrientationControlVisible={viewersState[el]['isOrientationControlVisible']}
                        isLinkedControlVisible={viewersState[el]['isLinkedControlVisible']}
                        isResetWLVisible={true}
                        isResetCameraVisible={true}
                        isSequenceControlVisible={viewersState[el]['isSequenceControlVisible']}
                        isContourModeControlVisible={viewersState[el]['isContourModeControlVisible']}
                        hasCropping={hasCroppingHelper(viewersState,el)}
                        croppingSlices={getCroppingValues(viewersState,el)}
                        isActive={activeViewerId===el}
                        setActive={viewersState[el]['setActive']}
                        annotationFormData={annotationsFormData}
                        annotationData={annotationsData}
                        annotationReadData={annotationsReadData}
                        readOnly={readOnly}
                        activeROI={activeROI}
                        addRow={(roi)=>addAnnotation(roi,el)}
                        removeAnnotation={(data)=>rmCallback(data)}
                        setActiveAnnotation={setActiveAnnotation}
                        setSubAnnotation={setSubAnnotation}
                        addSubAnnotationForActiveAnnotation={(roi)=>addSubAnnotationForActiveAnnotation(roi,el)}
                        pinProperties={manualToolState[PIN_TOOL]}
                        tracker={crosshairWidgetOn && viewerCrosshairInteractionOn}
                        leftButtonMode={leftButtonMode}
                        picker={manualToolState[POINTER_TOOL]!=null?manualToolState[POINTER_TOOL]:null}
                        pickerCallback={(ijk) => pickerCallback(el, ijk)}
                        changeOrientation={(v) => changeOrientation(el, v)}
                        changeScene={(e)=>changeScene(el,e)}
                        rulers={crosshairs()}
                        dynamicInitialSliceSetting={viewersState[el]['dynamicSliceValue']}
                        annotationsVisible={annotationsVisible} //only for instantenuous show/hide
                        projectionsVisible={projectionsVisible} // only single slice
                        annotationProjection={manualToolState[PROJECTION_TOOL]?manualToolState[PROJECTION_TOOL][PROJECTION_TOOL]:null} //multiple slices
                        annotationProjectionParameters={manualToolState[PROJECTION_TOOL]} //range for projections
                        handleKeySlicing={handleKeySlicing}
                        infoTool={manualToolState[INFO_TOOL]}
                    />
                </React.Suspense>
                );

            case "3D": {
                return (<React.Suspense fallback={<p>Loading</p>}>
                        <Viewer3D
                            {...commonProps}
                            isActive={false}
                            setActive={() => {}}
                            annotationData={annotationsData}
                            tracker={manualToolState[CROSS_HAIR_TOOL]!=null?manualToolState[CROSS_HAIR_TOOL][CROSS_HAIR_TOOL]:null}
                            leftButtonMode={leftButtonMode}
                            viewersState={viewersState} //object of 3 viewers state
                            pickerCallback={(ijk) => pickerCallback(0, ijk)}
                            changeOrientation={(v) => changeOrientation(0, v)}
                            changeImageType={(e) => changeImageType(0, e)}
                            // rulers={(manualToolState[CROSS_HAIR_TOOL]!=null) ? calcRulers(el, activeViewerId, viewersState, images) : null}
                        /></React.Suspense>
                );
            }
            case "VIDEO": {
                return (<React.Suspense fallback={<p>Loading</p>}>
                      <Video
                        {...commonProps}
                        addRow={(roi)=>addAnnotation(roi,el)}
                        pinProperties={manualToolState[PIN_TOOL]}
                        isActive={false}
                        setActive={() => {}}
                        annotationData={annotationsData}
                        leftButtonMode={leftButtonMode}
                        viewersState={viewersState} //object of 3 viewers state
                        pickerCallback={(ijk) => pickerCallback(0, ijk)}
                        changeOrientation={(v) => changeOrientation(0, v)}
                      /></React.Suspense>
                );
            }
            case "AUDIO": {
                return (<React.Suspense fallback={<p>Loading</p>}>
                      <Audio
                        {...commonProps}
                      /></React.Suspense>
                );
            }
            case "BRAINGRAPH": {
                return (<React.Suspense fallback={<p>Loading</p>}>
                      <Brain
                        {...commonProps}
                      /></React.Suspense>
                );
            }

        }
    }
}