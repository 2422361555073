import { combineReducers } from 'redux'

import { cohortListReducer, cohortReducer } from './reducer/CohortReducer'
import { subjectListReducer, subjectReducer } from './reducer/SubjectReducer'
import { sessionListReducer, sessionReducer } from './reducer/SessionReducer'
import {variableListReducer, variableReducer} from "./reducer/VariableReducer";
import {modelListReducer, modelReducer} from "./reducer/ModelReducer";
import {annotationsReducer, saveAnnotationsReducer} from "./reducer/AnnotationReducer";
import {imageReducer} from "./reducer/ImageReducer";
import {viewersReducer} from "./reducer/ViewerReducer";
import {manualToolReducer} from "./reducer/ManualToolReducer";
import {interactivePlotReducer} from "./reducer/InteractivePlotReducer";
import {sceneReducer} from "./reducer/SceneReducer";
import {lutReducer} from "./reducer/LutReducer";
import {polyReducer} from "./reducer/PolyReducer";
import {ontologyReducer, saveOntologyReducer} from "./reducer/OntologyReducer";
import {questionReducer, saveQuestionReducer} from "./reducer/QuestionReducer";
import {roiReducer} from "./reducer/RoiReducer";
import {formsReducer} from "./reducer/FormReducer";

/**
 * Combines reducers for all visualized items.
 * All "slice reducers" are available with 'visu' postfix, eg.
 * "state.visu.annotations..."
 * "state.visu.viewers..."
 * Splitting up reducer logic is well explained here:
 * @see https://redux.js.org/recipes/structuring-reducers/splitting-reducer-logic
 */
const VisuReducer = combineReducers({
	studies: cohortListReducer,
	study: cohortReducer,
	subjects: subjectListReducer,
	subject: subjectReducer,
	sessions: sessionListReducer,
	session: sessionReducer,
	models: modelListReducer,
	model:modelReducer,
	variables:variableListReducer,
	variable:variableReducer,
	annotations: annotationsReducer,
	saveAnnotations: saveAnnotationsReducer,
	images: imageReducer,
	viewers: viewersReducer,
	forms: formsReducer,
	scenes:sceneReducer,
	manualTool: manualToolReducer,
	interactivePlot:interactivePlotReducer,
	luts:lutReducer,
	polys:polyReducer,
	ontologies:ontologyReducer,
	saveOntologies:saveOntologyReducer,
	questions:questionReducer,
	saveQuestions:saveQuestionReducer,
	roi:roiReducer
});

export default VisuReducer;