//Poly actions********************************************************


import {POLY_REQUEST_FAILED, POLY_REQUEST_SUCCESS, POLY_REQUESTED} from "./actionType";
import {updateScenesOnInputLoaded} from "./SceneAction";
import vtkMath from "vtk.js/Sources/Common/Core/Math/index";
import polyDataReader from "vtk.js/Sources/IO/Legacy/PolyDataReader/index";

const polyReader = polyDataReader.newInstance();


const requestPoly = (polyId) => ({
  type: POLY_REQUESTED,
  polyId
});

const errorPoly = (err,polyId) => ({
  type: POLY_REQUEST_FAILED,
  err,
  polyId
});

const successPoly = (polyId,polyData) => ({
  type: POLY_REQUEST_SUCCESS,
  polyId,
  polyData
});


export const getPoly = (polyId) => {
  return dispatch => {
    dispatch (requestPoly(polyId));
    console.log('PolyAction.js :: getPoly :: Before axios request');

    loadPolyfromFile(polyId)
      .then((data)=>
      {
        dispatch(successPoly(polyId,data));
        dispatch(updateScenesOnInputLoaded(polyId));
      })
      .catch(err=>dispatch (errorPoly(err,polyId)));
  };
};


//These are mock to simulate async loading of data - map with id and name of data file (json)
let mockedPolyMap = null;
loadPolyRegister().then(data=>mockedPolyMap = data); //Load register of mocked data


//------------ToDO Remove this mocks when data collected from DB
async function loadPolyfromFile(poly) {
  // let response = await fetch(`/dist/jsonDocs/data/${mockedPolyMap[poly]}.vtk`);
  let response = await polyReader.setUrl(`/dist/jsonDocs/data/${mockedPolyMap[poly]}.vtk`);
  return polyReader.getOutputData(0);
}

async function loadPolyRegister() {
  let response = await fetch('/dist/jsonDocs/data/polyRegister.json',{cache: "reload"});
  const data = await response.json();
  return await data;
}