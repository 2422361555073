import React from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import {ContainerManualToolDispatcher} from "../../visualization/containers/ContainerManualToolDispatcher";
import {ContainerSkillSummaryLP} from "../../visualization/containers/ContainerSkillSummaryLP";
import {ContainerDynamicHistogramDicePanel} from "../../expDesign/containers/ContainerDynamicHistogramDicePanel";
import {ContainerAvatarScatterPlotPanel} from "../../expDesign/containers/ContainerAvatarScatterPlotPanel";
import {
    ContainerLPControlPanel
} from "../../visualization/containers/ContainerTaskControlPanel";
import {ContainerResultsPage, ContainerResultsPageINOS} from "../../visualization/containers/ContainerResultsPage";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK = 'MINIWORKFLOWTASK';
export const SLIDE_PROPERTY_NAME__SLIDE_TYPE = "slideType";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE = 'IMAGE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO = 'VIDEO';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE = 'WEBSITE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT = 'EXPERIMENT';
export const SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES = 'slideProperties';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_RESULTS_DICE = 'MINIWORKFLOWRESULTSDICE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__SKILL_SUMMARY = 'SKILLSUMMARY';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT_RESULTS_PLOT = 'EXPERIMENTRESULTSPLOT';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT_SCORE_PAGE = 'SCORE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT_SCORE_PAGE_INOS = 'SCORE_INOS';


/**
 * Controller for executing skills.
 * This is "Skill" equivalent to playSlidesManually (since there is no inheritance in React).
 * This version is using Redux to keep the state of "presentation".
 */
class PlaySkill extends React.Component {

    constructor() {
        super();
        ["getRenderCurrentSlide", "keyListener"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    };


    keyListener(event) {
        const {slides,goToNextStep,goToPreviousStep} = this.props;
        if (event.code === "Escape") {
            this.props.history.push("/skills");
            return;
        }
        if (event.code === "PageDown" || event.code === "Comma") {
            goToPreviousStep();
            return;
        }
        let currentSlide = slides[this.props.currentStepIndex];
        if (currentSlide[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !== SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK) {
            if (event.code === "PageUp" || event.code === "Period" ) {
                goToNextStep();
            }
        }
    }

    getRenderCurrentSlide() {
        const {goToNextStep, goToPreviousStep, slides,currentStepIndex,updateCurrentStepIndex} = this.props;
        const currentSlide = slides[currentStepIndex];

        switch (currentSlide[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase()) {

            case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style} className="slide_div fade">
                            <h2 style={title_style}>{currentSlide.title}</h2>
                            <iframe is="x-frame-bypass" src={currentSlide.slideProperties.url}
                                    height="100%" width="100%"/>
                        </div>
                    </React.Fragment>
                );

            case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO: {
                const onEnd = (currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['onEndGoNext'] != null &&
                    currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['onEndGoNext'])
                    ? () => goToNextStep()
                    : () => {
                    };
                let url = new URL(currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']); // i am parsing url here, since i need 1 param only
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style_video} className="slide_div fade">
                            <h2 style={title_style}>{currentSlide.title}</h2>
                            <YouTube videoId={url.searchParams.get('v')}
                                     onEnd={onEnd}
                                     opts={playerOpts}
                                     height="100%"
                                     width="100%"
                                     containerClassName="youtubeVideo"
                            />
                        </div>
                    </React.Fragment>
                );
            }

            case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        {/*<div style={slide_style} className="slide_div fade">*/}
                            {/*<h2 style={title_style}>{currentSlide.title}</h2>*/}
                            {/*<ContainerTabletGameExperiment*/}
                                {/*slideProperties={currentSlide['slideProperties']}*/}
                                {/*active={index === this.props.currentStepIndex}/>*/}
                        {/*</div>*/}
                    </React.Fragment>
                );

            case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style} className="slide_div fade">
                            <h2 style={title_style}>{currentSlide.title}</h2>
                            <img
                                src={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']}
                                height="100%" width="100%"/>
                        </div>
                    </React.Fragment>
                );

            case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style} className="slide_div fade">
                            <ContainerManualToolDispatcher
                                materializedTaskId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['taskId']}
                                livePresenterExperimentId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['livePresenterExperimentId']}
                                miniWorkflowSetId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowSetId']}
                                miniWorkflowNameInSet={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowNameInSet']}
                                taskNameInMiniWorkflow={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['taskNameInMiniWorkflow']}
                                currentStep={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['currentStep']}
                                onNext={()=>goToNextStep()} //only for slide navigation
                                onPrevious={()=>goToPreviousStep()} //only for slide navigation
                            />
                        </div>
                    </React.Fragment>
                );

            case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_RESULTS_DICE:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style} className="slide_div fade">
                            <ContainerDynamicHistogramDicePanel
                                livePresenterExperimentId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['livePresenterExperimentId']}
                            />
                        </div>
                    </React.Fragment>
                );
            case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__SKILL_SUMMARY:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style_summary} className="slide_div fade">
                            <ContainerSkillSummaryLP
                                urlFailure={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['urlFailure']}
                                urlSuccess={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['urlSuccess']}
                                livePresenterExperimentId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['livePresenterExperimentId']}
                                mfKey={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowNameInSet']}
                                miniWorkflowSetId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowSetId']}
                                goToFirstSlide={()=>updateCurrentStepIndex(0)}
                            />
                        </div>
                    </React.Fragment>
                );
            case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT_RESULTS_PLOT:
                return (
                    <React.Fragment key={currentSlide.orderNumber}>
                        <div style={slide_style_summary} className="slide_div fade">
                            <ContainerAvatarScatterPlotPanel
                                slideProperties={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]}/>
                        </div>
                    </React.Fragment>
                );
            case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT_SCORE_PAGE_INOS:{
                return (
                  <React.Fragment key={currentSlide.orderNumber}>
                      <div style={slide_style_summary} className="slide_div fade">
                          <ContainerResultsPageINOS {...this.props}
                                                livePresenterExperimentId={currentSlide.slideProperties.livePresenterExperimentId}
                                                miniWorkflowSetId={currentSlide.slideProperties.miniWorkflowSetId}
                                                miniWorkflowNameInSet={currentSlide.slideProperties.miniWorkflowNameInSet}
                                                message={"There has been an error in communication with server"}/>
                      </div>
                  </React.Fragment>
                );
            }
            case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT_SCORE_PAGE:{
                return (
                  <React.Fragment key={currentSlide.orderNumber}>
                      <div style={slide_style_summary} className="slide_div fade">
                          <ContainerResultsPage {...this.props}
                                                livePresenterExperimentId={currentSlide.slideProperties.livePresenterExperimentId}
                                                miniWorkflowSetId={currentSlide.slideProperties.miniWorkflowSetId}
                                                miniWorkflowNameInSet={currentSlide.slideProperties.miniWorkflowNameInSet}
                                                message={"There has been an error in communication with server"}/>
                      </div>
                  </React.Fragment>
                );
            }
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        document.body.addEventListener("keydown", this.keyListener, false);
        script.src = "https://unpkg.com/x-frame-bypass";
        document.body.appendChild(script);
        const {getSlides} = this.props;
        getSlides(this.props.match.params['id']);
    }

    /** Needed to remove listeners. */
    componentWillUnmount() {
        const {clearSkillState} = this.props;
        clearSkillState();
        document.body.removeEventListener("keydown", this.keyListener, false);
    }

    render() {
        const {currentStepIndex,slides} = this.props;


        console.log('PlaySkill::render');
        return (
            <React.Fragment>
                {slides!=null && slides.length > 0 &&
                <div className="slideshow-div">
                    {this.getRenderCurrentSlide()}
                    {slides[currentStepIndex][SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !== SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK
                    &&   <ContainerLPControlPanel/>}
                </div>}
            </React.Fragment>
        )

    }
};

PlaySkill.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    currentStepIndex:PropTypes.number.isRequired,
    updateCurrentStepIndex:PropTypes.func.isRequired,
    clearSkillState:PropTypes.func.isRequired,
    goToNextStep:PropTypes.func.isRequired,
    goToPreviousStep:PropTypes.func.isRequired
};


const playerOpts = {
    height: '100%',
    width: '100%',
    cc_load_policy:'1',
    host: 'https://www.youtube-nocookie.com',
    playerVars: { // https://developers.google.com/youtube/player_parameters
        rel: 0,
        autoplay: 1,
        cc_load_policy:'1',
        cc_lang_pref:'fr',
        mute: 0
    }
};

const title_style = {
    position: 'fixed',
    left: "60px",
    top: "2px",
    color: "white",
    zIndex: "1001"
};
const slide_style = {
    width: 'inherit',
    height: 'inherit',
    position: 'relative',
    display: "block"
};
const slide_style_summary = {
    width: 'inherit',
    height: 'inherit',
    position: 'relative',
    display: "block",
    backgroundColor: "white"
};
const slide_style_video = {
    width: 'inherit',
    height: 'inherit',
    position: 'relative',
    display: "block",
    overflowX: "hidden",
    overflowY: "hidden"
};


export default PlaySkill;
