import {HOST_URL, ROI_TABLE_TOOL} from "../../../Constants";
import axios from "axios/index";
import {UPDATE_MANUAL_TOOL_SAM, UPDATE_ROI_VISUALIZATION_DATA_SAM} from "./actionType";
import {getNestedProp} from "../../helpers/expressions";
import {
  requestLUT,
  requestLUTDescription,
  successLUT,
  successLUTDescription
} from "../../visualization/action/LUTAction";
import {removeDuplicatesByFilterFunction} from "../../helpers/arrays";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {ORDINAL24, ordinalColors} from "../../helpers/colors";
import {updatePropertySAM} from "./SAMAction";


const ROI_ID_PROPERTY= "uuid"; // "_id" in previous version

export async function getVTStub(type){
  let path = '/dist/jsonDocs/tools/visualizationToolStubEmpty.json';

  if (type==="advanced") // or "contribution"
    path = '/dist/jsonDocs/tools/visualizationToolStubEmpty3D.json';

  if (type==="group")
    path = '/dist/jsonDocs/tools/visualizationOverlappingStub.json';


  let response = await fetch(path,{cache: "reload"});
  const data = await response.json();
  return await data;
}



export const updateManualToolSAM = (tool)=>({
 type: UPDATE_MANUAL_TOOL_SAM,
 tool
});

export const updateRoiVisualizationDataSAM = (data)=>({
  type: UPDATE_ROI_VISUALIZATION_DATA_SAM,
  data
});

/**
 * Function responsible for creating overlapping segmentation and generating
 * TODO Validation of referenceImageEntities - if different then error message
 *
 * @return {function(...[*]=)}
 */

export const getOverlapGroup = () => {
  return async (dispatch, getState) => {

    try {
      const dataPoint = getState().sam.selectedDataPoint;
      const contributions = removeDuplicatesByFilterFunction(dataPoint.contributionsX.concat(dataPoint.contributionsY), x => x["key"]); // remove duplicates
      const config = {headers: {"Authorization": "bearer" + getState().auth.token_bearer}};
      const {rois, questions} = await getROI(config, contributions);
      const selectedPlotSettings = getSelectedPlotSettings(getState());

      const getReferenceImage = () => {
        if (Array.isArray(dataPoint.contributionsX[0].roiId)){
          const roi = rois.find(el => el[ROI_ID_PROPERTY] === dataPoint.contributionsX[0].roiId[0]); // ['roi'];  // new implementation
          return getNestedProp(["reference", "imageEntityId"], roi);
        }
        const roi = rois.find(el => el[ROI_ID_PROPERTY] === dataPoint.contributionsX[0].roiId); // ['roi'];  // new implementation
        return getNestedProp(["reference", "imageEntityId"], roi);
      };

      // get image Ids from may rois that can be contribution
      const getImageId = (roiId) => {
        if (Array.isArray(roiId)){
          let resultArray = [];
          for(let _roi of roiId){
            const roi = rois.find(el => el[ROI_ID_PROPERTY] === _roi);
            if (roi!=null)
              resultArray.push(getNestedProp(["properties", "explicit", "labelMap", "imageEntityId"], roi));
          }
          return resultArray;
        }
        const roi = rois.find(el => el[ROI_ID_PROPERTY] === roiId);
        return [getNestedProp(["properties", "explicit", "labelMap", "imageEntityId"], roi)];
      };

      const payload = {
        group1: dataPoint.contributionsX.map(contributor => getImageId(contributor.roiId)).flat().filter(el=>el!=null),
        group2: dataPoint.contributionsY.map(contributor => getImageId(contributor.roiId)).flat().filter(el=>el!=null),
        threshold: selectedPlotSettings.levelAgreementValue / 100
      };
      if (payload.group1.length<1){
        dispatch(handleToolConfigurationWarning("Cannot calculate group overlapping due to missing segmentations in group1"));
        return;
      }
      if (payload.group2.length<1){
        dispatch(handleToolConfigurationWarning("Cannot calculate group overlapping due to missing segmentations in group2"));
        return;
      }
      dispatch(requestLUT("lut_overlappingGroups"));
      dispatch(successLUT("lut_overlappingGroups", [
        {
          "value": 1,
          "color": "#0f0"
        },
        {
          "value": 2,
          "color": "#ff0"
        },
        {
          "value": 3,
          "color": "#00f"
        },
      ]));
      dispatch(requestLUTDescription("lutDescription_overlappingGroups"));
      dispatch(successLUTDescription("lutDescription_overlappingGroups", [
        {
          "value": 1,
          "label": "Overlapping area"
        },
        {
          "value": 2,
          "label": "Group 1"
        },
        {
          "value": 3,
          "label": "Group 2"
        },
      ]));
      // Needs to be retrieved from the plotSettings
      // The experimentProperties should have the experiemntId, lut_key, lutDescription_key

      let response = await axios.post(`${HOST_URL}/api/overlap-map-group`, payload, config);
      const segmentation_key = response.data.uuid;

      const mTool = await getVTStub("group");

      mTool.miniWorkflow.currentMaterializedTask.inputs.inputImage_key2.value = getReferenceImage();
      mTool.miniWorkflow.currentMaterializedTask.inputs.inputImage_key3.value = segmentation_key;
      console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<TOOL SAM  GROUP UPDATE>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      dispatch(updateManualToolSAM(mTool));
      }catch(err) {
        dispatch(handleToolConfigurationError(err));
    }

  }
};



const randomColor = ()=>"#000000".replace(/0/g, function () {
  return (~~(Math.random() * 16)).toString(16);
});

const parsePrimaryImage = (roi,mTool)=>{
  const primaryKey =getNestedProp(["reference","imageEntityId"],roi);
  mTool.miniWorkflow.currentTool.inputs["input_"+primaryKey] = {
    "name": "Input image",
    "description": "Input image",
    "isList": false,
    "type": "imageEntityInOut",
    "imageEntityInOut_Type": "ANATOMICAL",
    "imageEntityInOut_FileFormat": "nii.gz",
    "required": true
  };
  mTool.miniWorkflow.currentTool.configuration.scenes.sceneKey1.primaryImageInputKey = "input_"+primaryKey;
  mTool.miniWorkflow.currentMaterializedTask.inputs["input_"+primaryKey] = {
    "value": primaryKey
  };
};

const parseSegmentation = (roi,mTool,dispatch,contributionIndex,key,labelForFirstLayer)=>{
  //const segKey = getNestedProp(["properties","explicit","labelMap","imageEntityId"],roi);

  const segKey = key;

  const intensity = getNestedProp(["properties","explicit","labelMap","labelIntensity"],roi)!=null
    ? getNestedProp(["properties","explicit","labelMap","labelIntensity"],roi)
    : 1;

  if (contributionIndex===0){
    labelForFirstLayer["value"] = intensity;
  }

  // no need to put it to document
  // mTool.miniWorkflow.currentTool.inputs["lut_"+segKey] =   {
  //   "name": "Input LUT data",
  //   "description": "Input LUT data for overlay displaying.",
  //   "isList": false,
  //   "type": "lookUpTable",
  //   "required": true
  // };

  dispatch(requestLUT("lut_"+segKey));
  dispatch(successLUT("lut_"+segKey,[
    {
      "value":intensity,
      "color": roi.color
    }
  ]));

  // no need to put it into document
  // mTool.miniWorkflow.currentTool.inputs["lutDescription_"+segKey] = {
  //   "name": "Input description of LUT data",
  //   "description": "Input LUT data for displaying options.",
  //   "isList": false,
  //   "type": "lookUpTableDescription",
  //   "required": true
  // };

  dispatch(requestLUTDescription("lutDescription_"+segKey));
  dispatch(successLUTDescription("lutDescription_"+segKey,[{
    "value": intensity,
    "label": roi.contributorLabel
  }
  ]));

  mTool.miniWorkflow.currentTool.inputs["input_"+segKey] = {
    "name": "Segmentation",
    "description": "Segmentation",
    "isList": false,
    "type": "roiInOut",
    "typeROI": "EXPLICIT",
    "required": true,
    "imageEntityInOut_FileFormat": "nii.gz"
  };

  // Although the lookup tables are not from inputs, mark them as they are - to avoid clearing in ManualTool::initializeNonInputs
  mTool.miniWorkflow.currentTool.configuration.luts["lut_"+segKey] = {fromInputs: true, lutInputKey:"lut_"+segKey};
  mTool.miniWorkflow.currentTool.configuration.lutDescriptions["lutDescription_"+segKey] = {fromInputs: true, lutDescriptionInputKey:"lutDescription_"+segKey};

  mTool.miniWorkflow.currentTool.configuration.rois.overlays["overlay_"+segKey] = {
    "fromInputs": true,
    "imageInputKey": "input_"+segKey,
    "lutKey": "lut_"+segKey,
    "lutDescriptionKey": "lutDescription_"+segKey,
    "label": roi.contributorLabel
  };
  mTool.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlay_"+segKey);


  mTool.miniWorkflow.currentMaterializedTask.inputs["input_"+segKey] = {
    "value": getNestedProp(["properties","explicit","labelMap","imageEntityId"],roi)
  };
  // mTool.miniWorkflow.currentMaterializedTask.inputs["lut_"+segKey] = {
  //   "value": "lut_"+segKey
  // };
  // mTool.miniWorkflow.currentMaterializedTask.inputs["lutDescription_"+segKey] = {
  //   "value": "lutDescription_"+segKey
  // };


};

/**
 * Set up initial slice in the middle of contribution.
 * @param mTool
 * @param label - label for the first layer defined in roi
 */
const parseLabelMapCOG = (mTool,label)=>{
  try {
    mTool.miniWorkflow.currentTool.configuration.viewers.renderWindows[0].initialState.orientationAndSliceNumber["labelMapValue"] = label;
    mTool.miniWorkflow.currentTool.configuration.viewers.renderWindows[0].initialState.orientationAndSliceNumber["layerIndex"] = 0;
    mTool.miniWorkflow.currentTool.configuration.viewers.renderWindows[1].initialState.orientationAndSliceNumber["labelMapValue"] = label;
    mTool.miniWorkflow.currentTool.configuration.viewers.renderWindows[1].initialState.orientationAndSliceNumber["layerIndex"] = 0;
    mTool.miniWorkflow.currentTool.configuration.viewers.renderWindows[2].initialState.orientationAndSliceNumber["labelMapValue"] = label;
    mTool.miniWorkflow.currentTool.configuration.viewers.renderWindows[2].initialState.orientationAndSliceNumber["layerIndex"] = 0;
  }catch(err){
    console.log("Error during initialization of image")
  }
};


const parseImplicitROIs = (roi,mTool)=>{

  // create keys for differnt ROIDatalists to display them with different colors
  // colors assigned here to raters !
  // TODO Other coloring schemas require parameter
  // const compositeKey = getNestedProp(["reference","userId"],roi)!=null
  //   ? "inputROIDataList_"+getNestedProp(["reference","userId"],roi)
  //   : "inputROIDataList_"+getNestedProp(["contributorLabel"],roi);

  const compositeKey = "inputROIDataList_"+roi.color;


  if (!(getNestedProp(["miniWorkflow","currentTool","inputs",compositeKey],mTool)!=null)) {
    mTool["miniWorkflow"]["currentTool"]["inputs"][compositeKey] = {
      "name": "Input ROis data",
      "description": "Input ROI data for annotation table",
      "isList": true,
      "type": "roiInOut",
      "typeROI": "IMPLICIT",
      "required": true
    };
    mTool["miniWorkflow"]["currentMaterializedTask"]["inputs"][compositeKey] = {
      value:[],
      "readOnly": true,
      "color": roi.color
    };
  }
  mTool["miniWorkflow"]["currentMaterializedTask"]["inputs"][compositeKey]["value"].push(roi[ROI_ID_PROPERTY]);
};

/**
 *
 * @param config - axios params, ie. authorization bearer
 * @param contributions
 * @return {Promise<any>} - Promise
 */
async function getROI(config,contributions){
  const roiListPayload = [];
  for (let c = 0; c < contributions.length; c++) {
    if (contributions[c].roiId!=null)
      roiListPayload.push(contributions[c].roiId)
  }
  let response = await axios.post(`${HOST_URL}/api/roi-list?annotation=true`,roiListPayload.flat(), config);
  return await response.data;
}


function handleToolConfigurationWarning (message){
  return (dispatch, getState) => {
    // show message and
    getState().messaging.msgQueue.show(
      {
        sticky: true,
        severity: 'warning',
        summary: "Visualization warning",
        detail: message
      }
    );
    dispatch(updatePropertySAM("visualizationToolDialogVisible",false));
  }
}

function handleToolConfigurationError (err){
  return (dispatch, getState) => {
    // show message and
    getState().messaging.msgQueue.show(
      {
        sticky: true,
        severity: 'error',
        summary: "Visualization error",
        detail: "Cannot prepare configuration due to error:"+err
      }
    );
    dispatch(updatePropertySAM("visualizationToolDialogVisible",false));
  }
}

/**
 * Create dynamically tool configuration for option Visualize contributions
 * @param mType - {"contribution","advanced"}
 * @param force - force recreating the configuration
 * @return {function(...[*]=)}
 */
export function getAllContributions  (mType,force){
  return async (dispatch, getState) => {
    const dataPoint = getState().sam.selectedDataPoint;
    const rawData = getState().sam.rawData;
    const manualToolConfiguration = getState().sam.manualToolConfiguration;

    try {

      const contributions = removeDuplicatesByFilterFunction(dataPoint.contributionsX.concat(dataPoint.contributionsY), x => x["key"]) // remove duplicates
                            .filter(el=>el.type!=="clinicalData");  //remove clinical data (no roi)
      const contributorsIdsArray = Array.from(new Set(contributions.map(el => el.contributorId)));  // generate set of contributors keys
      const contributorsArray = rawData.contributors
        .filter(el => {
          return contributorsIdsArray.includes(el.uuid)
        });

      const config = {headers: {"Authorization": "bearer" + getState().auth.token_bearer}};
      const {rois, questions} = await getROI(config, contributions);  // NEW API here

      if (!(manualToolConfiguration != null) || force) {

        const mTool = await getVTStub(mType); // load template

        const labelForTheFirstLayer = {value: 1}; // needed for LABELMAP_COG initialization

        // loop over all contributions
        for (let i = 0; i < contributions.length; i++) {

          const contributionRoiArray = Array.isArray(contributions[i].roiId)
            ? contributions[i].roiId
            : [contributions[i].roiId]; // if ROI is not an array (new API) then make it

          // loop over all rois in contribution
          for (let roiIdentifier of contributionRoiArray) {
            const roiIndex = rois.findIndex(el => el[ROI_ID_PROPERTY] === roiIdentifier); // new implementation
            const contributorIndex = contributorsArray.findIndex(el => el.uuid === contributions[i].contributorId);
            const roi = rois[roiIndex];

            // get color accordingly to the type of visualization
            const getColorIndex = ()=>{
              if(mType==="roi"){
                 return (roiIndex > -1 && roiIndex <24)
                   ? roiIndex
                   : 23;
                }
              if(mType==="contribution"){
                return i;
              }
              return (contributorIndex > -1 && contributorIndex <24) // trim to only 24 colors
                ? contributorIndex
                : 23; // all raters with no. greater than 24 will use the same color
            };

            const contributorLabel = contributorIndex > -1
              ? contributorsArray[contributorIndex].label
              : "NA";

            roi["color"] = ordinalColors(ORDINAL24)[getColorIndex()];
            roi["contributorLabel"] = contributorLabel;

            // parse Explicit ROIS
            if (getNestedProp(["properties", "explicit", "labelMap"], roi) != null) {
              parsePrimaryImage(roi, mTool);
              parseSegmentation(roi, mTool, dispatch, i, contributions[i].key, labelForTheFirstLayer);
            }
            // parse Implicit ROIS
            if (getNestedProp(["properties", "implicit", "implicitGeometryPointer"], roi) != null
              || getNestedProp(["properties", "implicit", "implicitGemetryPointer"], roi) != null) // because of common typo bug in data
            {
              parsePrimaryImage(roi, mTool);
              parseImplicitROIs(roi, mTool)
            }
          }
        }
        parseLabelMapCOG(mTool, labelForTheFirstLayer.value);
        console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<TOOL SAM UPDATE>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        dispatch(updateManualToolSAM(mTool));
        dispatch(updateRoiVisualizationDataSAM({rois,questions}));
        // });
      }
    }catch(err){
      dispatch(handleToolConfigurationError(err));
    }
  }
};

export const  createManualToolConfiguration = (mType)=>{
  return (dispatch, getState) => {
    if(mType==="contribution" || mType==="advanced" || mType==="roi" || mType==="contributor")
      dispatch(getAllContributions(mType));
    if(mType==="group")
      dispatch(getOverlapGroup());
  }
};