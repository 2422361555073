import React from 'react'
import { connect } from 'react-redux'
import EventPage from "../component/EventPage";
import {recreateTrainingWorkflowInLP} from "../../dashboard/action/ManualTaskAction";

const mapStateToProps = state => {
  return{
    auth : state.auth,
    messageQueue: state.messaging.msgQueue
  }
};

const mapDispatchToProps = dispatch => ({
    recreateTrainingWorkflowInLP : (experimentId,miniWorkflowSet,miniWorkflowKey,eventId) => {
        dispatch(recreateTrainingWorkflowInLP(experimentId,miniWorkflowSet,miniWorkflowKey,eventId))
  }
});

export const ContainerEventPage = connect(
  mapStateToProps,
  mapDispatchToProps)(EventPage);
