import React from "react";
import {run} from "../../helpers/validation/ruleRunner";
import PropTypes from 'prop-types';
import {Button} from 'primereact/components/button/Button';
import { withTranslation } from 'react-i18next';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {RadioButton} from "primereact/components/radiobutton/RadioButton";
import InputTextWithValidation from "../../helpers/validation/InputTextWithValidation";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_API_KEY } from '../../../Constants';
import DisclaimerWithValidation from "../../helpers/validation/DisclaimerWithValidation";
import { CountryDropdown } from "react-country-region-selector";

/**
 * Form to fill out to enroll/participate in a event
 * Renders the event fields as a form and as prop receives a callback to submit the filled form
 */
class EventForm extends React.Component {

    constructor(props){
        super(props);

        // Set the state
        this.state = {
            showErrors: false,
            validationErrors: {},
            password: '',
            timeSlot: '',
            isLoading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.generateFields = this.generateFields.bind(this);

        this.recaptcha = React.createRef();
    }

    componentWillMount() {
        // Run validations on initial state
        const {fieldValidations} = this.props;
        this.setState({validationErrors: run(this.state, fieldValidations)});
    }

    errorFor(field) {
        return this.state.validationErrors[field] || "";
    }

    handleChange(event) {
        this.setState({
            [event.id]: event.value
        });
    }

    handleSubmit(){
        const {enrollUser, fieldValidations} = this.props;
        const {password, timeSlot} = this.state;
        this.setState({
            validationErrors: run(this.state, fieldValidations),
            showErrors: true,
            isLoading: true
        });
        if (Object.keys(this.state.validationErrors).length > 0){
            this.setState({
                isLoading: false
            })
            return null;
        } 
        enrollUser(this.state, timeSlot, password, ()=>this.setState({isLoading:false}), ()=>this.setState({isLoading:false}));
    }

    generateFields(fields, password, timeSlots, termsAndConditions){
        const {t} = this.props;
        const labeledTimeSlots = timeSlots.map((slot)=>{
            const label = slot.name;
            const timeSlot = {label, value: slot.id, disabled: slot.available == 0};
            return timeSlot});
        return <div id='registerEventForm' className="ui-g-6">
                    <div style={{margin: "auto", width: "80%"}}>
                    {Object.entries(fields).map(([key, value])=>{
                        return <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width: "70%"}}>
                                    <label className="reg-plaintext">{value.label} </label>
                                    <div style={{width: '100%'}}>
                                        {value.type == 'country'?
                                            <React.Fragment>
                                            <CountryDropdown
                                                style={{width: "100%"}}
                                                value={this.state[key]}
                                                onChange={(value) => this.handleChange({id:key, value})}
                                                defaultOptionLabel=""
                                            />
                                            {this.state.showErrors?
                                                <div>
                                                    <div class="validation-error">
                                                        <span class="text">{this.errorFor(key)}</span>
                                                    </div>
                                                </div>:null}
                                            </React.Fragment>
                                            :value.type == 'select'?
                                            <React.Fragment>
                                                <Dropdown
                                                id={key}
                                                value={this.state[key]}
                                                required={true}
                                                options={value.options.map(option=>{return {label:option, value:option}})}
                                                style={{width: '100%'}}
                                                onChange={(event)=>this.handleChange({id: key, value:event.value})}
                                                />
                                                {this.state.showErrors?
                                                    <div>
                                                        <div class="validation-error">
                                                            <span class="text">{this.errorFor(key)}</span>
                                                        </div>
                                                    </div>:null}
                                            </React.Fragment>
                                            :
                                            <InputTextWithValidation
                                                id={key}
                                                type={value.type}
                                                showError={this.state.showErrors}
                                                errorText={this.errorFor(key)}
                                                style={{width: '100%'}}
                                                onFieldChanged={(event)=>this.handleChange({id:event.target.id, value:event.target.value})}
                                                {...(value.max!=null? {max: value.max}: {})}
                                                {...(value.min!=null? {min: value.min}: {})}
                                                className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                                            />
                                            }
                                    </div>
                                </div>
                    })}
                    <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width: "70%"}}>
                        <label className="reg-plaintext">{t("registerForm.property.timeSlot.label")}</label>
                        <div style={{width: '100%'}}>
                            {labeledTimeSlots.map((labeledTimeSlot, idx)=>{
                                return <div className="ui-g-12">
                                            <RadioButton
                                                inputId={`timeSlot${idx}`}
                                                name="timeSlot"
                                                disabled={labeledTimeSlot.disabled}
                                                value={labeledTimeSlot.value}
                                                onChange={(event)=>this.handleChange({id: "timeSlot", value: event.value})}
                                                checked={this.state.timeSlot === labeledTimeSlot.value}
                                            >
                                            </RadioButton>
                                            <label htmlFor={`timeSlot${idx}`} className="p-radiobutton-label">{labeledTimeSlot.label}</label>
                                        </div>;
                            })}
                        {this.state.showErrors?
                            <div>
                                <div class="validation-error">
                                    <span class="text">{this.errorFor("timeSlot")}</span>
                                </div>
                            </div>:null}
                        </div>
                    </div>
                    {password?
                        <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width: "70%"}}>
                            <label className="reg-plaintext">Event password</label>
                            <div style={{width: '100%'}}>
                                <InputTextWithValidation
                                    id="password"
                                    type="password"
                                    value={this.state.password}
                                    showError={this.state.showErrors}
                                    errorText={this.errorFor("password")}
                                    style={{width: '100%'}}
                                    onFieldChanged={(event)=>this.handleChange({id:event.target.id, value:event.target.value})}
                                    className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                                />
                            </div>
                        </div>
                    : null}
                    {termsAndConditions?
                        <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width: "70%"}}>
                            <label
                                  className="reg-plaintext">{t("registerForm.property.disclaimer.label")} </label>
                            <DisclaimerWithValidation
                                id="disclaimer"
                                name={"Disclaimer"}
                                onFieldChanged={(event)=>this.handleChange({id: event.target.id, value: event.checked})}
                                showError={this.state.showErrors}
                                errorText={this.errorFor("disclaimer")}
                                className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                                disclaimerContent={termsAndConditions}
                            />
                        </div>
                        :<div></div>}
               </div>
            </div>;
    }

    render() {
        const { t, cancelEnrollUser, eventImage, eventName, eventExtendedDescription, eventDisclaimer, eventTermsAndConditions, fields, timeSlots, password } = this.props;

        return (
            <React.Fragment>
                <div className="header landingPageHeaderTopbar"
                     style={{display:"flex",alignItems:"center"}}>
                    <div className="reg-headtitle" style={{ color: "#A7FFFE",    margin: "auto", display: "inline-flex"}}>
                        {`${eventName}: ${t("events.registration.title")}`}
                    </div>
                </div>
                <div style={{height: '90%'}}>
                    <div style={{
                            marginTop: "3em",
                            marginLeft: "5em",
                            width: "90%",
                            fontSize: "1.2em"
                        }}>
                        <h3>{t("events.description.title")}</h3>
                        <div className="ui-card-content" dangerouslySetInnerHTML={{__html: eventExtendedDescription}}/>
                    </div>
                    <div style={{
                            marginTop: "3em",
                            marginLeft: "5em",
                            width: "90%",
                            fontSize: "1.2em"
                            }}>
                        <h3>{t("events.personalInformation.title")}</h3>
                        </div>
                    <div className="ui-g-12">
                        {this.generateFields(fields, password, timeSlots, eventTermsAndConditions)}
                        <div className="ui-g-6">
                            <img src={eventImage}
                                style={{
                                        width: "30em",
                                        height: "30em",
                                        padding: "6em"
                                    }}/>
                        </div>
                    </div>
                    {eventDisclaimer?    
                    <React.Fragment>
                        <div style={{
                            marginTop: "3em",
                            marginLeft: "5em",
                            width: "90%",
                            fontSize: "1.2em",
                            }}>
                            <h3>{t("events.juridicMentions.title")}</h3>
                            <div style={{
                                height: "20em",
                                overflowY: "scroll"
                            }}>
                                <div className="ui-card-content" dangerouslySetInnerHTML={{__html: eventDisclaimer}}/>
                            </div>
                        </div>
                    </React.Fragment>
                    : <div></div>}
                    <div className="ui-g-12">
                        <div style={{display: "block", margin: "auto", textAlign: "center", marginBottom: "5em"}}>
                            {!this.state.isLoading?
                                <Button
                                    label={t("enrollButton.label")}
                                    title={t("enrollButton.title")}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.recaptcha.current.execute();
                                        }}
                                />
                                :<div> <i className="fa fa-spinner fa-spin"/></div>}
                            <Button
                                label={t("general.button.cancel.label")}
                                onClick={()=>cancelEnrollUser()}
                            />
                        </div>
                    </div>
                    <ReCAPTCHA
                            ref={this.recaptcha}
                            size="invisible"
                            sitekey={RECAPTCHA_API_KEY}
                            onChange={value => 
                                {
                                    if(value){
                                        this.handleSubmit();
                                        this.recaptcha.current.reset();
                                    } else {
                                        this.props.showError(t("recaptcha.invalid"))
                                    }
                                }
                            }
                        />
                </div>
            </React.Fragment>
        );
    }
};

EventForm.propTypes={
    t:PropTypes.func.isRequired,
    eventName:PropTypes.string.isRequired,
    eventExtendedDescription: PropTypes.string.isRequired,
    eventImage:PropTypes.string.isRequired,
    eventTermsAndConditions:PropTypes.string.isRequired,
    eventDisclaimer:PropTypes.string.isRequired,
    enrollUser:PropTypes.func.isRequired,
    cancelEnrollUser: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    password: PropTypes.bool.isRequired,
    timeSlots: PropTypes.array.isRequired,
    fieldValidations: PropTypes.array.isRequired,
    showError: PropTypes.func.isRequired
};

export default withTranslation()(EventForm);