import React from "react";
import {Link} from "react-router-dom";
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import {required} from "../../helpers/validation/rules";
import PropTypes from 'prop-types';
import {Card} from "primereact/components/card/Card";
import {Button} from 'primereact/components/button/Button';
import { withTranslation } from 'react-i18next';
import axios from "axios/index";
import {HOST_URL, USER} from "../../../Constants";
import EventForm from "./EventForm";
import EventDetailsPage from "./EventDetailsPage";
import { SplitButton } from "primereact/components/splitbutton/SplitButton";

/**
 * Component to hold the event enrollment entry and to show the event skills available for the user
 * @returns {*}
 */
class EventPage extends React.Component {

    constructor(props){
        super(props);

        // Set the state
        this.state = {
            isUserEnrolled: false,
            event: undefined,
            directEnroll: false
        };

        // Bind functions for the buttons
        this.enrollUser = this.enrollUser.bind(this);
        this.showError = this.showError.bind(this);
    }

    showError(error) {
        this.props.messageQueue.show({
            severity: 'error',
            summary: 'Error Message',
            detail: error});
    }

    enrollUser(fields, timeSlot, password, onSuccess, onError){

        // Get the authentication from the store
        const {auth} = this.props;

        // Enroll the user and update the state
        // POST /event/{uuid}/signup
        // TODO: Optional password. This code should related to a form shown to the user if it's not enrolled and the form should also have reCAPTCHA validation.
        axios.defaults.headers.common['Authorization'] = auth.token_bearer;
        axios.post(
            `${HOST_URL}/api/event/${this.state.event.uuid}/signup`,
            {fields, timeSlot, password})
            .then(response => {
                if (response.status !== 200) {
                    console.log('Event Enrollment - Error enrolling user', response.data);
                    this.showError(`Unable to participate in the event: ${response.data}`);
                    onError();
                } else {
                    // TODO: User enrolled now he should be able to see the corresponding skill(s)
                    this.setState({isUserEnrolled: true});
                    console.log('User enrolled');
                    onSuccess();
                    location.reload();
                }
            }).catch(error => {
            console.log('Event Enrollment - Error enrolling:', error);
            const message = error.response.data? `: ${error.response.data.message}`: '';
            this.showError(`Unable to participate in the event ${message}`);
            onError();
        });

    }

    componentDidMount(){
        // Get the authentication from the store
        const {auth, match, location, i18n} = this.props;
        const eventId = match.params.eventId;
        const directRegister = new URLSearchParams(location.search).get('directRegister');
        const lang = i18n.language.substring(0,2);
        if(this.state.event==null){
            // Check if the user in enrolled in the event and update state
            axios.defaults.headers.common['Authorization'] = auth.token_bearer;
            axios.get(`${HOST_URL}/api/event/${eventId}?lang=${lang}`)
                .then(response => {
                    if (response.status !== 200) {
                        console.log('Error getting event information')
                        this.showError('Error getting event information')
                    } else {
                        const event = response.data;
                        const eventClosed = new Date(event.closingDate) < new Date();
                        const state = {
                            directEnroll: directRegister,
                            isEventClosed: eventClosed,
                            isUserEnrolled: event.isUserEnrolled,
                            event};
                        if(event.isUserEnrolled){
                            const timeSlotOpen = event.timeSlot.state == 'open';
                            const timeSlotBeforeOpen = new Date(event.timeSlot.openingDate) >= new Date() && event.timeSlot.state == 'close';
                            const timeSlotContributionClosed = new Date(event.timeSlot.contributionClosingDate) < new Date() && event.timeSlot.state == 'close';
                            state.isTimeSlotOpen = timeSlotOpen;
                            state.isTimeSlotBeforeOpen = timeSlotBeforeOpen;
                            state.isTimeSlotContributionClosed = timeSlotContributionClosed;
                        }
                        this.setState(state);
                    }
                }).catch(error => {
                console.log('EventPage.js :: error', error);
            });
        }
    }

    render() {

        //.........................................................................
        const { auth, t, recreateTrainingWorkflowInLP } = this.props;
        const { event, directEnroll, isUserEnrolled, isEventClosed,
        isTimeSlotOpen, isTimeSlotBeforeOpen,  isTimeSlotContributionClosed} = this.state;
        
        if(event==null){
            return (
                <div className="spinner-centered">Loading <i className="fa fa-spinner fa-spin"/></div>
            );
        }

        const fieldValidations = [ruleRunner('timeSlot', t("registerForm.property.timeSlot.label"), required), ruleRunner('disclaimer', t("registerForm.property.disclaimer.label"), required)];
        Object.entries(event.fields||{}).forEach(([key, value])=>{
            if(value.required)
            fieldValidations.push(ruleRunner(key, value.label, required));
        });
        const isUserAdmin = auth.roles!=null && auth.roles.findIndex((r)=>{return r===USER.ROLES.ADMIN})>-1
        //.........................................................................
        // Styles
        const cardStyle = {width: "30em", height: "17em", margin: "4.5em", marginTop:"2em", fontSize: "1.2em", position: "relative"};
        const textStyle = {height: "11em", overflowY: "auto"};
        const textStyleButtons = {height: "4.5em", textAlign: "right", position: "absolute", bottom: "0", width: "90%"};


        //.........................................................................
        return (
            <React.Fragment>
                {isUserEnrolled || (isUserAdmin && !directEnroll) ?
                <React.Fragment>
                    <div style={{
                        marginTop: "3em",
                        marginLeft: "5em",
                        width: "90%",
                        fontSize: "1.2em"
                    }}>
                        <span className="reg-headtitle">
                            {t("event")} {event.name}
                        </span>
                        <h3>{t("events.instructions.title")}</h3>
                        <p className="ui-card-content">
                            <div dangerouslySetInnerHTML={{
                                __html: event.timeSlot && this.state.isUserEnrolled?
                                            event.instructions.replace(/{{session}}/g, event.timeSlot.name):
                                            event.instructions}}/>
                        </p>
                        {!isUserEnrolled && isUserAdmin && !isEventClosed?
                        <div style={{textAlign: "center"}}>
                            <Button
                                label={t("enrollButton.label")}
                                title={t("enrollButton.title")}
                                onClick={()=>this.setState({directEnroll: true})}
                            />
                        </div>: <div></div>}
                    </div>
                    <div style={{
                        marginTop: "3em",
                        marginLeft: "5em",
                        fontSize: "1.2em"
                    }}>
                        <h3>{t("events.skills.title")}</h3>
                    </div>
                    <div style={{
                        flexDirection: "row",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start"
                    }}>
                        {event.skillsAvailable.map((skill)=>{
                            return <Card
                                        title={skill.name}
                                        subtitle={skill.subtitle}
                                        style={cardStyle}
                                    >
                                        <div style={textStyle}>
                                            {skill.description}
                                            <div style={{textAlign: "center"}}>
                                                <img src={'../static/images/skill.png'} style={{height: "9em"}}/>
                                            </div>
                                        </div>
                                        <div style={textStyleButtons}>
                                            <div className="ui-g-7" style={{textAlign: "left"}}>
                                                {isUserAdmin?
                                                <div>
                                                    <SplitButton label="Results Histogram Plot"
                                                                onClick={()=>this.props.history.push(`/eventResultsMultiplot/${skill.livePresenterExperimentId}/histogram`)}
                                                                model={[{label: 'Results Scatter Plot',
                                                                        command: (e) => this.props.history.push(`/eventResultsMultiplot/${skill.livePresenterExperimentId}/avatar`)
                                                                        },
                                                                        {label: 'Results Agreement Plot',
                                                                        command: (e) => this.props.history.push(`/eventResultsMultiplot/${skill.livePresenterExperimentId}/agreement`)
                                                                        }]}>
                                                    </SplitButton>
                                                    {/* <Link to={`/eventResultsMultiplot/${skill.livePresenterExperimentId}/histogram`}>
                                                    <Button
                                                        label={"Results Histogram Plot"}
                                                        title={"Results Histogram Plot"}
                                                    />
                                                    </Link> */}
                                                    {/* <Link to={`/eventResultsMultiplot/${skill.livePresenterExperimentId}/avatar`}>
                                                    <Button
                                                        label={"Results Scatter Plot"}
                                                        title={"Results Scatter Plot"}
                                                    />
                                                    </Link>
                                                    <Link to={`/eventResultsMultiplot/${skill.livePresenterExperimentId}/agreement`}>
                                                    <Button
                                                        label={"Results Agreement Plot"}
                                                        title={"Results Agreement Plot"}
                                                    />
                                                    </Link> */}
                                                </div>: null}
                                            </div>
                                            <div className="ui-g-5">
                                                {isTimeSlotOpen && isUserEnrolled?
                                                    <Link to={`/skills/playskill/${skill.presentationId}`}
                                                        onClick={()=>recreateTrainingWorkflowInLP(
                                                        skill.livePresenterExperimentId,
                                                        skill.miniWorkflowSetId,
                                                        skill.miniWorkflowKey,
                                                        event.uuid)}>
                                                        <Button
                                                            label={t("dashboard.tables.mySkills.property.contribution.value.tryIt")}
                                                            title={t("dashboard.tables.mySkills.property.contribution.value.tryIt")}
                                                        />
                                                    </Link>
                                                :isTimeSlotBeforeOpen && isUserEnrolled ? <span><i>{t("events.skills.notAvailable")}</i></span>
                                                :isTimeSlotContributionClosed && isUserEnrolled ? <span><i>{t("events.skills.closed")}</i></span>
                                                :null}
                                            </div>
                                        </div>
                                </Card>

                        })}
                    </div>
                </React.Fragment>
            : <React.Fragment>
                
                {directEnroll?<EventForm
                            eventName={event.name}
                            eventExtendedDescription={event.extendedDescription}
                            eventImage={event.imageUrl}
                            eventDisclaimer={event.disclaimer}
                            eventTermsAndConditions={event.termsAndConditions}
                            fields={event.fields}
                            fieldValidations={fieldValidations}
                            password={event.password}
                            timeSlots={event.timeSlots}
                            enrollUser={this.enrollUser}
                            cancelEnrollUser={()=>{this.setState({directEnroll: false})}}
                            showError={this.showError}/>
                        :
                        <EventDetailsPage
                            event={event}
                            eventButton={
                                <Button
                                    label={t("enrollButton.label")}
                                    title={t("enrollButton.title")}
                                    onClick={()=>this.setState({directEnroll: true})}
                                />}
                            />}
                </React.Fragment>
            }
        </React.Fragment>
        );
    }
};

EventPage.propTypes={
    t:PropTypes.func.isRequired,
    recreateTrainingWorkflowInLP:PropTypes.func.isRequired
};

export default withTranslation()(EventPage);