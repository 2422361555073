import {connect} from "react-redux";
import {goToNextStep, goToPreviousStep, saveOutputAndSubmit} from "../action/ManualToolAction";
import TaskControlPanel from "../component/annotationTool/TaskControlPanel";
import {withRouter} from "react-router";
import {
    goToNextSkillStep, goToPreviousSkillStep,

} from "../../skills/action/SkillAction";
import {setAcknowledgeSignal} from "../../dashboard/action/ManualTaskAction";
import {MANUAL_TASK_GET_SUCCESS} from "../../dashboard/action/actionType";
import {LPControlPanel} from "../component/annotationTool/LPControlPanel";

/**
 *
 * @param state
 * @param ownProps - property
 * @returns {{images, overlays, imageOptions: *|Array, viewersState: *|Array}}
 */
const mapStateToProps = (state,ownProps) => {
    return {
        manualToolState: state.visu.manualTool.manualToolState,
        manualTask:state.activity.manualTask.manualTask,
        miniWorkflowPath: state.activity.manualTask.manualTask.miniWorkflow.miniWorkflowPath,
        currentStep: state.activity.manualTask.manualTask.miniWorkflow.currentStep,
        skillCurrentStep:state.skill.skill.currentStepIndex,
        ackSignalState: state.activity.manualTask.ackSignalState,
        nack: state.activity.manualTask.nack,
        annotationsData: state.visu.annotations.annotationsData,
        annotationsDefinition: state.visu.annotations.annotationsDefinition,
        annotationsFormData: state.visu.annotations.annotationsFormData,
        annotationsFormDefinition: state.visu.annotations.annotationsFormDefinition
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        saveOutputAndSubmit:(lpMode,experimentId,miniWorkflowKey,miniWorkflowSetId,onNext)=>{
            dispatch(saveOutputAndSubmit(lpMode,experimentId,miniWorkflowKey,miniWorkflowSetId,onNext)) // TODO Remove this HACK when we find reasonable solution for presentation
        },
        goToPreviousStep: ()=>{
            dispatch(goToPreviousStep())
        },
        goToNextStep: ()=>{
            dispatch(goToNextStep())  //unused, feature for the future
        },
        onNextSkillsStep:()=>{
            dispatch(goToNextSkillStep())
        },
        onPreviousSkillsStep:()=>{
            dispatch(goToPreviousSkillStep())
        },
        setAcknowledgeSignal: (signal, err)=>{
            dispatch(setAcknowledgeSignal(signal, err));
        }
    }
};

/**
 * Actions for use in demo mode only (they do not trigger changes in DB).
 * Applied (SliceSelectionToolViewers)
 *
 * @param dispatch
 * @param ownProps - {callbackOnGoToNext}
 * @return {{saveOutputAndSubmit: function(*, *, *, *, *)}}
 */
const mapDispatchToPropsDemo = (dispatch, ownProps) => {
    return {
        saveOutputAndSubmit:(lpMode,experimentId,miniWorkflowKey,miniWorkflowSetId,onNext)=>{
            ownProps['callbackOnGoToNext']("This is just a demo. Your results are not saved.")
        }
    }
};

const mapStateToPropsSkillOnly = (state,ownProps) => {
    return {
        currentStep: state.skill.skill.currentStepIndex,
        slides: state.skill.skill.slides
    }
};

const mapDispatchToPropsSkillOnly = (dispatch, ownProps) => {
    return {
        goToNextStep:()=>{
            dispatch(goToNextSkillStep())
        },
        goToPreviousStep:()=>{
            dispatch(goToPreviousSkillStep())
        }
    }
};


const containerCreator = connect( mapStateToProps, mapDispatchToProps);
const containerCreatorDemo = connect( mapStateToProps, mapDispatchToPropsDemo);
const containerSkillOnly = connect(mapStateToPropsSkillOnly, mapDispatchToPropsSkillOnly);


/** The container can bind many components if they use the same reducer.*/
export const ContainerTaskControlPanel = withRouter(containerCreator(TaskControlPanel));


/** The container can bind many components if they use the same reducer.*/
export const ContainerLPControlPanel = containerSkillOnly(LPControlPanel);

/** Just for demo purposes.*/
export const ContainerTaskControlPanelDemo = withRouter(containerCreatorDemo(TaskControlPanel));