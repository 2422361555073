import React from 'react'
import {Route, withRouter} from 'react-router'
import Dashboard from "./components/Dashboard";
import {ContainerTaskDashboard} from "./containers/ContainerTaskDashboard";
import ManualToolDispatcher from "../visualization/component/annotationTool/ManualToolDispatcher";
import {ContainerTaskView} from "../visualization/containers/ContainerTaskView";
import Authorization from "../authentication/component/Authorization";
import {USER} from "../../Constants";
import {ContainerEventNeuroAgoraPage} from "../event/containers/ContainerEventDiscoverBrighamPage";
import AgreementModel from "../expDesign/component/AgreementModel";



class DashboardRouter extends React.Component {
    render() {
        const {match} = this.props;
        return (
           <React.Fragment>
                <Route exact path={`${match.path}/`} component={Dashboard}/>
                <Route exact path={`${match.path}/task/:taskId`} component={ContainerTaskDashboard}/>
                <Route exact path={`${match.path}/miniwf/:miniWorkflowKey/:miniWorkflowSetId`} component={ContainerTaskDashboard}/>
                <Authorization roles={[USER.ROLES.ADMIN]}>
                 <Route exact path={`${match.path}/agreement/:workflowId/:miniWorkflowSetId/:experimentId/:miniWorkflowKey/:inputAnnotationTableDefinition_key1/:inputAnnotationFormDefinition_key1`} component={AgreementModel}/>
                </Authorization>
               {/*<Route exact path={`${match.path}/task/workload/:taskId`} component={ContainerTaskView}/>*/}
           </React.Fragment>

        )
    }

}

export default withRouter(DashboardRouter)