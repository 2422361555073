import {UPDATE_SINGLE_VIEWER_PROPERTY, UPDATE_SINGLE_VIEWER_STATE, UPDATE_VIEWERS_STATE} from "./actionType";
import {loadImageData} from "./ImageAction";
import {
  ANNOTATION_PROPERTY_NAME__ROI,
  CROSS_HAIR_TOOL,
  MAIN_TOOL, MAIN_TOOL_PROPERTY_NAME_ACTIVE_VIEWER_ID,
  ORIENTATION_LUT,
  POINTER_TOOL,
  REQUEST_STATUS_SUCCESS,
  WINDOW_LEVEL__ALL_TOOL
} from "../../../Constants";
import {slicingKeyHandler} from "../../vtk/SpineVTKHelper";
import {updateManualToolProperty} from "./ManualToolAction";
import {getNestedProp} from "../../helpers/expressions";


const updateSingleViewerState = (viewer, id) => ({
  type: UPDATE_SINGLE_VIEWER_STATE,
  viewer,
  id
});
const updateViewerStateProperty = (id, property, value) => ({
  type: UPDATE_SINGLE_VIEWER_PROPERTY,
  id,
  property,
  value
});
export const updateViewersState = (viewers) => ({
  type: UPDATE_VIEWERS_STATE,
  viewers
});

/**
 * ActionCreator for updating only one row in viewersState.
 * @param index
 * @param viewer
 * @returns {function(*)}
 */
export const updateSingleViewer = (viewer, index) => {
  return (dispatch) => {
    dispatch(updateSingleViewerState(viewer, index));
  };
};

/**
 * ActionCreator for changing orientation.
 * @param key -  unique key of viewer
 * @param value -
 * @returns {function(*,*)}
 */
export const changeOrientation = (key, value) => {
  return (dispatch, getState) => {
    let viewerState = Object.assign({}, getState().visu.viewers.viewersState[key]);
    viewerState['orientation'] = value;
    viewerState['slice'] = 0;
    dispatch(updateSingleViewer(viewerState, key));
  };
};

/**
 * Helper function to use binding between template and viewers json configuration.
 * @param viewerBindings
 * @param originalKey
 * @return {string | undefined}
 */
const getViewerKeyForTemplate = (viewerBindings, originalKey) => {
  return Object.keys(viewerBindings).find(key => viewerBindings[key] === originalKey);
};


/**
 * Provide data for viewer sequence dropdown.
 * @param vc - single viewer configuration
 * @param scenes - scenes state
 * @param toolInputs - inputs of tool and step
 */
const parseImageOptions = (vc, scenes, toolInputs) => {
  const imageOptions = [];
  if (vc["displayScenes"] != null && vc["displayScenes"]["possibleScenesToDisplay"] != null) {
    vc["displayScenes"]["possibleScenesToDisplay"].forEach((inp_key) => {
      imageOptions.push(Object.assign({},
        {
          "label": (scenes != null && scenes[inp_key] != null && scenes[inp_key].label != null) ? scenes[inp_key].label : inp_key,
          "value": inp_key
        }));
    });
  }
  return imageOptions;
};

const parseActiveViewerLogic = (interactions, iSOV, el, dispatch, key) => {
  //---------------- set ActiveViewerId in main tool if this is defined in conf.---------------------------
  if (interactions != null
    && interactions["activeViewer"] != null
    && interactions["activeViewer"]["defaultValue"] === true)
    dispatch(updateManualToolProperty([MAIN_TOOL], [MAIN_TOOL_PROPERTY_NAME_ACTIVE_VIEWER_ID], key));

  //--- set proper callback to click callback in viewer (if ['activeViewer']['controlEnabled'] false => no action) ---
  let setActive = () => dispatch(updateManualToolProperty(MAIN_TOOL, MAIN_TOOL_PROPERTY_NAME_ACTIVE_VIEWER_ID, key));
  if (interactions != null && interactions['activeViewer'] != null) {
    if (!interactions['activeViewer']['controlEnabled'])
      setActive = () => {
      };
  }
  iSOV[key]['setActive'] = setActive;
};

const parseCrosshairInteractions = (interactions, iSOV, el, dispatch, key) => {
  if (interactions != null && interactions[CROSS_HAIR_TOOL] != null) {
    iSOV[key][CROSS_HAIR_TOOL] = interactions[CROSS_HAIR_TOOL];
  }
};

/**
 * Parse interactions of viewers with widgets and activeViewer.
 * @param viewerConfiguration
 * @param iSOV
 * @param el
 * @param dispatch
 * @param key
 */
const parseViewerInteractions = (viewerConfiguration, iSOV, el, dispatch, key) => {
  const interactions = viewerConfiguration[el]['interactions'];
  parseActiveViewerLogic(interactions, iSOV, el, dispatch, key);
  parseCrosshairInteractions(interactions, iSOV, el, dispatch, key);

};

/**
 *  Parses viewers configuration and updates viewers state in Store.
 */
export const initializeViewers = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const inputs = mTask.miniWorkflow.currentMaterializedTask.inputs;
    const viewerConfiguration = mTask.miniWorkflow.currentTool.configuration.viewers.renderWindows;
    const viewerBindings = mTask.miniWorkflow.currentTool.configuration.viewers.layout.layoutOrder;
    const scenesConfiguration = mTask.miniWorkflow.currentTool.configuration.scenes;
    const scenesState = getState().visu.scenes.scenes;

    if (!(scenesConfiguration != null))
      return;
    const iSOV = {};    //initialStateOfViewers


    Object.keys(viewerConfiguration).forEach((el) => {

      const key = getViewerKeyForTemplate(viewerBindings, el);

      if (viewerConfiguration[el]["type"]==="audio"){
        iSOV[key] = {
          id:key,
          src:getNestedProp([viewerConfiguration[el]["src"],"value"],inputs),
          type: viewerConfiguration[el].type,
        };
        dispatch(updateViewersState(iSOV));
        return;
      }

      //find binding from template id to viewers list id
      const displayControls = viewerConfiguration[el]['displayControls'];
      const lookAndFeel = viewerConfiguration[el]["lookAndFeel"];
      const defaultScene = scenesConfiguration[viewerConfiguration[el]["displayScenes"]["defaultSceneToDisplay"]];

      iSOV[key] = {
        id:key,
        smoothing: (displayControls != null) ? displayControls['smoothing']['defaultValue'] : false,
        isWindowLevelControlEnabled: (displayControls != null && displayControls['windowLevel'] != null && displayControls['windowLevel']['controlEnabled'] != null) ? displayControls['windowLevel']['controlEnabled'] : true,
        isSequenceControlVisible: (displayControls != null) ? displayControls['sequence']['controlVisible'] : false,
        isLinkedControlVisible: (displayControls != null && displayControls['linked'] != null) ? displayControls['linked']['controlVisible'] : true,
        isOrientationControlEnabled: (displayControls != null && displayControls['orientation'] != null &&  displayControls['orientation']['controlEnabled']!=null) ? displayControls['orientation']['controlEnabled'] : true,
        isOrientationControlVisible: (displayControls != null && displayControls['orientation'] != null &&  displayControls['orientation']['controlVisible']!=null) ? displayControls['orientation']['controlVisible'] : true,
        isContourModeControlVisible: (displayControls != null && displayControls['contourMode'] != null) ? displayControls['contourMode']['controlVisible'] : true,
        imageOptions: parseImageOptions(viewerConfiguration[el], scenesState, inputs),
        linked: false,
        sceneId: viewerConfiguration[el]["displayScenes"]["defaultSceneToDisplay"],
        imageId: inputs[defaultScene['primaryImageInputKey']].value,
        sliceNumber: null,
        type: viewerConfiguration[el].type,
        color: (lookAndFeel != null
          && lookAndFeel["color"] != null)
          ? lookAndFeel["color"]
          : "#f6f7f9",
        hasRim: (lookAndFeel != null
          && lookAndFeel["hasRim"] != null)
          ? lookAndFeel["hasRim"]
          : false,
        hasControlPanel: (lookAndFeel != null
          && lookAndFeel["hasControlPanel"] != null)
          ? lookAndFeel["hasControlPanel"]
          : true,
        initialWindowLevel: getNestedProp([el,"initialState","initialWindowLevel"],viewerConfiguration)
      };
      //check if there is overlay
      if (viewerConfiguration[el]["displayLabelMaps"] != null) {
        iSOV[key]['hasOverlay'] = true;
        iSOV[key]['overlayId'] =
          inputs[viewerConfiguration[el]["displayLabelMaps"]["defaultImageToDisplay"]].value;
      }

      if (iSOV[key]['type'] === '2D') {
        iSOV[key]['orientation']
          = ORIENTATION_LUT(viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['orientation']);
        // setting strategy
        iSOV[key]['initialSliceStrategy']
          = viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['strategy'];
        //  Initialization with number  moved to "bindingToolInputsToViewersInputs"
        // if (iSOV[key]['initialSliceStrategy']==="NUMBER") {
        //     const input_key = viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['sliceValue']
        //     iSOV[key]['sliceNumber'] = inputs[input_key]['value'];
        // }
        iSOV[key]['dynamicSliceValue']
          = viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['dynamicSliceValue'];

        if (viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['labelMapValue']!=null)
          iSOV[key]['initialLabelMapValue']
            = viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['labelMapValue'];

        if (viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['layerIndex']!=null)
          iSOV[key]['initialLayerIndex']
            = viewerConfiguration[el]['initialState']['orientationAndSliceNumber']['layerIndex'];

        if (viewerConfiguration[el]['initialState']['contoursOnInit']!=null && viewerConfiguration[el]['initialState']['contoursOnInit']['visible']!=null)
        iSOV[key]['contoursOnInit']
            =  viewerConfiguration[el]['initialState']['contoursOnInit']['visible'];
      }
      if (iSOV[key]['type'] === '3D') {
        iSOV[key]['displayImageSlices']
          = viewerConfiguration[el]['displayImageSlices'];
      }
      parseViewerInteractions(viewerConfiguration, iSOV, el, dispatch, key);
    });
    const bindingToolInputsToViewersInputs = mTask.miniWorkflow.currentTool['bindingToolInputsToViewersInputs'];

    if (bindingToolInputsToViewersInputs != null) {
      bindingToolInputsToViewersInputs.forEach(el => {
        iSOV[getViewerKeyForTemplate(viewerBindings, el["inputViewer"]["viewerId"])][el["inputViewer"]["property"]] = inputs[el["inputTool"]]["value"];
      });
    }

    dispatch(updateViewersState(iSOV));
  }
};


/** This function is used for simple viewing (scatter plot) on MRIPanel.
 * FIXME Removed old API for getting images - requires to replace MRiPanel
 * @deprecated
 * @param imageId - workflowResultId (the same for original image and overlay)
 * @returns {function(*, *)}
 */
export const initializeViewersInScatterPlot = (imageId) => {
  return (dispatch, getState) => {
    const images = getState().visu.images.images;
    const overlays = getState().visu.images.overlays;
    //initial State of viewers
    const initialState = [//slice number, orientation (eg. axial), slicing mode (i,j,k),  is linked?
      {
        slice: 0, orientation: 0, linked: false, colorIndex: 0, smoothing: false, type: "2D",
        imageId: imageId
      },
      {
        slice: 0, orientation: 1, linked: false, colorIndex: 1, smoothing: false, type: "2D",
        imageId: imageId
      },
      {
        slice: 0, orientation: 2, linked: false, colorIndex: 2, smoothing: false, type: "2D",
        imageId: imageId
      }
    ];
    //load images if they're not in the memory
    initialState.forEach((element) => {
      const image = images[element.imageId];//.find((el)=>el.uuid===element.imageId); //look for already loaded
      if (element.imageId != null && element.imageId !== "" && !(image != null)) {
        //removed old API here
      }

      const overlay = overlays[element.imageId];//.find((el)=>el.uuid===element.imageId); //look for already loaded
      if (element.imageId != null && element.imageId !== "" && !(overlay != null)) {
        //removed old API here
      }

    });
    dispatch(updateViewersState(initialState));
  };
};
/**
 *  This is for MRIBrowserPanel and AvatarScatterPlot.
 *  Since the viewer need refactoring, this simple solution should be enough for viewer within avatar plot.
 *  After refactoring, this will be removed.
 * @param imageId - original Image
 * @param overlayId - labelmap
 * @return {function(*, *)}
 */
export const displayCase = (imageId, overlayId) => {
  return (dispatch, getState) => {
    dispatch(loadImageData(imageId, "nii.gz", `/api/image-entity/${imageId}/file`));
    dispatch(loadImageData(overlayId, "nii.gz", `/api/image-entity/${overlayId}/file`, "overlay"));
    dispatch(clearViewersState());
    const initialState = {
      "upperLeft": {
        sliceNumber: 0,
        orientation: 0,
        linked: false,
        smoothing: false,
        type: "2D",
        color: "#ffa07a",
        initialSliceStrategy: "DYNAMIC",
        dynamicSliceValue: "LABELMAP_COG",
        imageId: imageId,
        hasOverlay: true,
        overlayId: overlayId
      },
      "upperRight": {
        sliceNumber: 0,
        orientation: 1,
        linked: false,
        smoothing: false,
        type: "2D",
        color: "#fff967",
        initialSliceStrategy: "DYNAMIC",
        dynamicSliceValue: "LABELMAP_COG",
        imageId: imageId,
        hasOverlay: true,
        overlayId: overlayId
      },
      "lowerLeft": {
        sliceNumber: 0,
        orientation: 2,
        linked: false,
        smoothing: false,
        type: "2D",
        color: "#90ee90",
        initialSliceStrategy: "DYNAMIC",
        dynamicSliceValue: "LABELMAP_COG",
        imageId: imageId,
        hasOverlay: true,
        overlayId: overlayId
      },
      "lowerRight": {
        sliceNumber: 74, orientation: 2, type: "3D", linked: false, smoothing: true,
        sliceVisibility: 1, imageId: imageId, hasOverlay: true, overlayId: overlayId
      }
    };
    dispatch(updateViewersState(initialState));
  }
};


/**
 * ActionCreator for updating only one property in viewersState.
 * @param key - id of the source viewer
 * @param property
 * @param value
 * @returns {function(*, *)}
 */
export const updateViewerProperty = (key, property, value) => {
  return (dispatch, getState) => {
    if (property === "sliceNumber") {
      const vState = getState().visu.viewers.viewersState;
      const images = getState().visu.images.images;
      if (vState[key] != null && vState[key]['linked'] === true && vState[key]["sliceNumber"]!=null) {
        let delta = value - vState[key]["sliceNumber"];
        const srcImage = images[vState[key]['imageId']];//.find((el)=>el.uuid===vState[key]['imageId']);
        Object.keys(vState).forEach((el) => {
          let previousValue = vState[el]["sliceNumber"];
          if (el !== key && previousValue!=null) {
            const imId = vState[el]['imageId'];
            const targetImage = images[imId];//.find((el)=>el.uuid === imId);
            if (vState[el]['linked'] && vState[el]['orientation'] === vState[key]['orientation'] && targetImage != null && targetImage['properties'] != null) {
              if (srcImage['properties']['slicingDirections'][vState[el]['orientation']] !== targetImage['properties']['slicingDirections'][vState[el]['orientation']])
                delta = -delta;
              let newValue = previousValue + delta;
              dispatch(updateViewerStateProperty(el, property, newValue));
            }
          }
        })
      }
    }
    dispatch(updateViewerStateProperty(key, property, value));
  };
};

/**
 * ActionCreator for updating only one property in viewersState.
 * @returns {function(*)}
 */
export const clearViewersState = () => {
  return (dispatch) => {
    dispatch(updateViewersState({}));
  };
};


/**
 * ActionCreator for changing the scene in the viewer.
 * In previous versions this was changing primary image only.
 *
 * @param id
 * @param value
 * @returns {function(*,*)}
 */
export const changeScene = (id, value) => {
  return (dispatch) => {
    // dispatch(updateViewerProperty(id,'slice',0));
    dispatch(updateViewerProperty(id, 'sceneId', value));

    //TODO Add here cndition
    //dispatch(loadImageData(value, "nii.gz",  `/api/image-entity/${value}/file`));
  };
};

const lowerBoundaryPropertyName = (orient) => {
  if (orient === 0) return "slicingRanges_SAGITTAL_leftmostSliceNumber";
  if (orient === 1) return "slicingRanges_CORONAL_posteriormostSliceNumber";
  else return "slicingRanges_AXIAL_inferiormostSliceNumber";
};
const upperBoundaryPropertyName = (orient) => {
  if (orient === 0) return "slicingRanges_SAGITTAL_rigthmostSliceNumber";
  if (orient === 1) return "slicingRanges_CORONAL_anteriormostSliceNumber";
  else return "slicingRanges_AXIAL_superiormostSliceNumber";
};


/**
 * Helper to generate cropping parameters.
 *
 */
export const hasCroppingHelper = (viewersState, viewerId) => {
  return [
    viewersState[viewerId]["slicingRanges_SAGITTAL_leftmostSliceNumber"] != null,
    viewersState[viewerId]["slicingRanges_SAGITTAL_rigthmostSliceNumber"] != null,
    viewersState[viewerId]["slicingRanges_CORONAL_posteriormostSliceNumber"] != null,
    viewersState[viewerId]["slicingRanges_CORONAL_anteriormostSliceNumber"] != null,
    viewersState[viewerId]["slicingRanges_AXIAL_inferiormostSliceNumber"] != null,
    viewersState[viewerId]["slicingRanges_AXIAL_superiormostSliceNumber"] != null
  ];
};

export const getCroppingValues = (viewersState, viewerId) => {
  return [
    viewersState[viewerId]["slicingRanges_SAGITTAL_leftmostSliceNumber"] != null ? viewersState[viewerId]["slicingRanges_SAGITTAL_leftmostSliceNumber"]["value"] : 0,
    viewersState[viewerId]["slicingRanges_SAGITTAL_rigthmostSliceNumber"] != null ? viewersState[viewerId]["slicingRanges_SAGITTAL_rigthmostSliceNumber"]["value"] : 0,
    viewersState[viewerId]["slicingRanges_CORONAL_posteriormostSliceNumber"] != null ? viewersState[viewerId]["slicingRanges_CORONAL_posteriormostSliceNumber"]["value"] : 0,
    viewersState[viewerId]["slicingRanges_CORONAL_anteriormostSliceNumber"] != null ? viewersState[viewerId]["slicingRanges_CORONAL_anteriormostSliceNumber"]["value"] : 0,
    viewersState[viewerId]["slicingRanges_AXIAL_inferiormostSliceNumber"] != null ? viewersState[viewerId]["slicingRanges_AXIAL_inferiormostSliceNumber"]["value"] : 0,
    viewersState[viewerId]["slicingRanges_AXIAL_superiormostSliceNumber"] != null ? viewersState[viewerId]["slicingRanges_AXIAL_superiormostSliceNumber"]["value"] : 0
  ];
};
/**
 * ActionCreator for changing the image type.
 * @param viewerId - active Viewer id
 * @param event - keyEvent
 * @returns {function(*,*)}
 */
export const handleKeySlicing = (viewerId, event) => {
  return (dispatch, getState) => {
    const images = getState().visu.images.images;
    const activeViewer = getState().visu.viewers.viewersState[viewerId];
    const image = images[activeViewer.imageId];//.find((el) => el.uuid === activeViewer.imageId);
    let slice = activeViewer.sliceNumber;

    if (image != null && image['state'] === REQUEST_STATUS_SUCCESS && viewerId != null) {
      const orientation = activeViewer['orientation'];
      const slicingDirection = image['properties']['slicingDirections'][orientation];
      const {minSlice, maxSlice} = getBoundarySlices(activeViewer, image);
      const result = slicingKeyHandler(event, slice, slicingDirection, minSlice, maxSlice);
      if (slice !== result)
        dispatch(updateViewerProperty(viewerId, 'sliceNumber', result));//has to be dispatched, otherwise not passing params
    }

  };
};


/**
 * ActionCreator for picker handling in the tool.
 *
 * Picker set slice number in viewers if:
 *  - they are not the source of picking (remaining)
 *  - they display the same image (IJK issue)
 *  - they are not in the same orientation (otherwise if linking is set then it may cause feedback on original one).
 *
 * @param viewerId - source of generated pick event
 * @param ijk - picked voxel coordinates
 * @returns {function(*,*)}
 */
export const pickerCallbackAC = (viewerId, ijk) => {
  return (dispatch, getState) => {
    const images = getState().visu.images.images;
    const viewersState = getState().visu.viewers.viewersState;
    const image = images[viewersState[viewerId].imageId];
    const slicingModes = image['properties']['slicingModes'];
    let copyCis = {};
    Object.keys(viewersState).forEach((el) => {
      copyCis[el] = Object.assign({}, viewersState[el])
    });
    const id = copyCis[viewerId].sceneId;
    dispatch(updateManualToolProperty(POINTER_TOOL, 'value', {src: viewerId, ijk: Object.assign({}, {ijk})}));
    Object.keys(copyCis).forEach((el, i) => {
      if (viewersState[el].sceneId === id  //the same image
        && viewerId !== el   // not the same viewer (avoid loop when linked)
        && viewerId['orientation'] !== viewersState[el]['orientation'])//not the same orientation (reason as above)
        dispatch(updateViewerProperty(el, 'sliceNumber',
          ijk[slicingModes[viewersState[el]['orientation']]])); // set ijk depending on current orientation

    });
  };
};

/**
 * Regular helper method returning boundary values for a given viewer.
 * This is NOT dispatched within Redux!!!
 * @param viewer - viewer object
 * @param image - SPINE image object with properties
 */
export const getBoundarySlices = (viewer, image) => {

  const orientation = viewer['orientation'];
  const slicingMode = image['properties']['slicingModes'][orientation];
  const slicingDirection = image['properties']['slicingDirections'][orientation];
  let minSlice = 0;
  let maxSlice = 0;

  if (slicingDirection > 0) {
    // if there is limit set it, otherwise set 0
    minSlice = (viewer[lowerBoundaryPropertyName(orientation)] != null)
      ? viewer[lowerBoundaryPropertyName(orientation)]
      : 0;

    // if there is limit set it, otherwise set dimensionality of image
    maxSlice = (viewer[upperBoundaryPropertyName(orientation)] != null)
      ? viewer[upperBoundaryPropertyName(orientation)]
      : image.data.getDimensions()[slicingMode] - 1;
  } else {
    // if there is limit, set it otherwise set 0 (min is greater than max)
    minSlice = (viewer[lowerBoundaryPropertyName(orientation)] != null)
      ? viewer[lowerBoundaryPropertyName(orientation)]
      : image.data.getDimensions()[slicingMode] - 1;

    // if there is limit, set it otherwise set dimensionality of image
    maxSlice = (viewer[upperBoundaryPropertyName(orientation)] != null)
      ? viewer[upperBoundaryPropertyName(orientation)]
      : 0;
  }
  return {minSlice, maxSlice}
};


export const alignViewers = () => {
  return (dispatch, getState) => {
    const images = getState().visu.images.images;
    const vs = getState().visu.viewers.viewersState;

    const activeViewerKey = getState().visu.manualTool.manualToolState[MAIN_TOOL]['activeViewerId'];
    if (!(activeViewerKey != null))
      return;
    const activeViewerRenderer = getState().visu.viewers.viewersState[activeViewerKey]['renderer'];
    Object.keys(vs).forEach((v) => {
        if (activeViewerKey !== v) {
          if (vs[v]['renderer'] != null && activeViewerRenderer != null) {
            vs[v]['renderer'].getActiveCamera().setFocalPoint(...activeViewerRenderer.getActiveCamera().getFocalPoint());
            vs[v]['renderer'].getActiveCamera().setParallelScale(activeViewerRenderer.getActiveCamera().getParallelScale());
            vs[v]['renderer'].getActiveCamera().setPosition(...activeViewerRenderer.getActiveCamera().getPosition());
            vs[v]['renderer'].getActiveCamera().setViewUp(...activeViewerRenderer.getActiveCamera().getViewUp());
            vs[v].renderer.getRenderWindow().render();
          }
        }
      }
    );


  };
};


export const applyWL = (viewer, w, l) => {
  return (dispatch, getState) => {
    const vs = getState().visu.viewers.viewersState;
    const activeViewerKey = getState().visu.manualTool.manualToolState[MAIN_TOOL]['activeViewerId'];
    const wlTool = getState().visu.manualTool.manualToolState[WINDOW_LEVEL__ALL_TOOL];
    if (!(activeViewerKey != null) || !(wlTool != null))
      return;

    if (!wlTool[WINDOW_LEVEL__ALL_TOOL])
      return;


    const activeViewerRenderer = getState().visu.viewers.viewersState[activeViewerKey]['renderer'];
    Object.keys(vs).forEach((v) => {
        if (activeViewerKey !== v) {
          vs[v].renderer.getActors()[0].getProperty().setColorWindow(w);
          vs[v].renderer.getActors()[0].getProperty().setColorLevel(l);
          vs[v].renderer.getVTKWindow().render();
        }
      }
    );


  };
};


/**
 *  Function to use to reset Views
 * @return {function(...[*]=)}
 */
export const resetAllViewersCameras = () => {
  return (dispatch, getState) => {
    const vs = getState().visu.viewers.viewersState;
    Object.keys(vs).forEach((v) => {
          if (vs[v]['reference'] != null) {
            vs[v]['reference'].resetCamera();
          }
      }
    );
  };
};



/**
 *  Function setting viewers slice numbers to IJK
 *  without checking the primary image concordance.
 * @return {function(...[*]=)}
 */
export const setSliceNumbersToIJK = (ijk) =>{
  return (dispatch, getState) => {
    let images = getState().visu.images.images;
    let viewersState = getState().visu.viewers.viewersState;
    let scenes = getState().visu.scenes.scenes;


    Object.keys(viewersState).forEach((el) => {
      const image = images[scenes[viewersState[el]['sceneId']]['primaryImage']];
      dispatch(updateViewerProperty(el,   'sliceNumber',   ijk[image['properties']['slicingModes'][viewersState[el]['orientation']]]));
    });
  };

};