import React from "react";
import PropTypes from "prop-types";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {MOCK_CODE, MOCK_LINK_TO_KEYS} from "../../visualization/tests/Mocks";
import {COHORT_PROPERTY_NAME__CREATION_DATE} from "../../../Constants";
import {getNestedProp} from "../../helpers/expressions";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Dialog} from "primereact/components/dialog/Dialog";
import {ContainerManualTool} from "../../visualization/containers/ContainerManualTool";
import {Button} from "primereact/components/button/Button";
import {Panel} from "primereact/components/panel/Panel";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Tree} from "primereact/components/tree/Tree";
import imagePlaceholder from "../../sass/images/iconforcohort.jpg";
import {ContainerChartsPanel} from "../containers/ContainerChartsPanel";
import {convertISODateToLocaleString} from "../../helpers/dates";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import BlinkingText from "../../root/component/BlinkingText";
import {getNested} from "../../helpers/comparators";
import {ContainerAnnotationTabPanel} from "../containers/ContainerAnnotationTabPanel";



class CohortsDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      manualToolVisible: false,
      expandedAcquisitionRows:null,
      spaceFilterValue:"all",
      filteredImagesCounter:0, // counter of filtered images
      filtersImages:{},    // state of DT filters
      filteredSubjectsCounter:0,  // // counter of filtered subjects
      filtersSubjects:{}, // state of DT filters
      filteredVisitsCounter:0,
      filtersVisits:{}, // state of DT filters
      filteredROIsCounter:0,
      filtersROIs:{}, // state of DT filters
      switchFilterAcquisitions:true,
      switchFilterSubjects:true,
      switchFilterVisits:true,
      switchFilterROIs:true,
      visualToolLabel:null
    };
    ["visualizeImage","visualizeROI","acquisitionRowExpansionTemplate","spaceBody","spaceFilter","idBody","setFilters"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidMount() {
    const {getCohort, match} = this.props;
    if (getCohort != null && match.params.cohortId != null) {
      getCohort(match.params.cohortId);
    }
  }
  componentWillUnmount() {
    const {clearCohort} = this.props;
    if (clearCohort!=null)
      clearCohort();
  }

  componentDidUpdate(prevProps,prevState,ss) {
    const {cohort} = this.props;
    if (prevProps.cohort !== cohort) {
      this.setState(
        {
          filteredImagesCounter : getNestedProp(['images', 'list','length'], cohort),
          filteredSubjectsCounter : getNestedProp(['subjects', 'list','length'], cohort),
          filteredVisitsCounter : getNestedProp(['visits', 'list','length'], cohort),
          filteredROIsCounter : getNestedProp(['rois', 'list','length'], cohort)
        });
    }

    if (prevState.switchFilterAcquisitions !== this.state.switchFilterAcquisitions)
      this.setState({switchFilterAcquisitions:true});
    if (prevState.switchFilterSubjects !== this.state.switchFilterSubjects)
      this.setState({switchFilterSubjects:true});
    if (prevState.switchFilterVisits !== this.state.switchFilterVisits)
      this.setState({switchFilterVisits:true});
    if (prevState.switchFilterROIs !== this.state.switchFilterROIs)
      this.setState({switchFilterROIs:true});

  }

  async visualizeImage(imagesArray,selected){
    const {createManualToolConfiguration} =this.props;
    createManualToolConfiguration(imagesArray);
    this.setState({
      manualToolVisible:true,
      visualToolLabel : `Acquisition: ${getNestedProp(['name'],selected)} (id:${getNestedProp(['uuid'],selected)})`
    })
  }
  async visualizeROI(roi){
    const {cohort,createManualToolConfigurationWithROI} =this.props;
    const primary = getNestedProp(['reference', 'imageEntityId'], roi);
    if (primary!=null) {
      const imageRecord  = getNestedProp(['images', 'list'], cohort).find(el=>primary===el.uuid);
      if (imageRecord!=null){
        createManualToolConfigurationWithROI(imageRecord,[roi] );
        this.setState({
          manualToolVisible:true,
          visualToolLabel : `ROI: ${getNestedProp(['uuid'],roi)} (Acquisition id:${primary})`
        })
      }
    }
  }


  setFilters(filtersId, property, value,counter){
    const stateCopy = this.state;
    const filterCopy= stateCopy[filtersId];
    filterCopy[property]={value: value, matchMode: 'contains'}; // remember to add conditions to that rule if filter is in mode 'custom' (for a given properties as "native")
    stateCopy[filtersId]=filterCopy;
    this.setState(stateCopy);
  }

  acquisitionRowExpansionTemplate(row) {
    const {cohort} = this.props;
    const subject  = getNestedProp(['subjects', 'list'], cohort).find(el=>getNestedProp(['reference', 'subjectId'], row)===el.uuid);
    const visit  = getNestedProp(['visits', 'list'], cohort).find(el=>getNestedProp(['reference', 'visitId'], row)===el.uuid);

    const visitAsArray=visit!=null ? [
      {name:"Id",value:visit.uuid},
      {name:"Type",value:visit.visitType},
      {name:"Label",value:visit.label},
      // {name:"Subject",value:getNestedProp(['reference', 'subjectId'], visit)},
      {name:"Visit date",value:convertISODateToLocaleString(visit.date)},
      {name:"Created on",value:convertISODateToLocaleString(visit.creationDate)}
    ]:[];
    const subjectAsArray=subject!=null ? [
      {name:"Id",value:subject.uuid},
      {name:"Date of birth",value:subject.dob},
      {name:"Sex",value:subject.sex},
      {name:"Original cohort id",value:subject.originalCohortId},
      {name:"Created on",value:convertISODateToLocaleString(subject.creationDate)}
    ]:[];

    let templateTo = null;
    let templateFrom = null;
    if (row.space!=null && row.space.native!=null) {
      if (row.space.native)
        templateTo = getNestedProp(['images', 'list'], cohort).filter(el=>{
          return getNestedProp(['space', 'uuid'], el)!=null && getNestedProp(['space', 'uuid'], el)===row.uuid
        });
      else
        templateFrom = getNestedProp(['images', 'list'], cohort).find(el=>{ return getNestedProp(['uuid'], el)!=null ===row.uuid});
    }
    const templatesTo = templateTo!=null ? templateTo.map(el=><a title={el.uuid}> {el.label} </a>):{};


    return (
      <div style={{display:"flex",justifyContent:"start"}}>
        <Panel header={"Acquisition details"} style={{width:"50%"}}>
        <div title="Created on">
                  <span
                    style={labelStyle}>Creation date:</span>{convertISODateToLocaleString(cohort[COHORT_PROPERTY_NAME__CREATION_DATE])}
        </div>
        <div title="Stored in">
                  <span  style={labelStyle}>Stored in:</span>{row.pathToNifti}
        </div>
          {row.space!=null && row.space.native && <div title="Image is target to:">
            <span  style={labelStyle}>Target to:</span>{templatesTo}
        </div>}
          {row.space!=null && !row.space.native && templateFrom!=null && <div title="Image with target space:">
            <span  style={labelStyle}>Image with target space:</span><a title={templateFrom.uuid}> {templateFrom.label} </a>
          </div>}

          {getNestedProp(["pathToDicoms"],row)&&
          <div title="Stored in">
            <span  style={labelStyle}>Stored in:</span>{row.pathToDicoms}
          </div>}

          {getNestedProp(["dicomHeaderSummary","all"],row)&&
            <React.Fragment>
              <h5>Dicom headers</h5>
              {  Object.keys(getNestedProp(["dicomHeaderSummary","all"],row)).map(prop=>{
                let value = getNestedProp(["dicomHeaderSummary","all",prop],row);
                if (Array.isArray(value))
                  value=value.join(", ");
                if (prop!=="PatientBirthDate" && prop!=="PatientName" && prop!=="PatientSex" && prop!=="PatientID" && prop!=="InstitutionName" && prop!=="StationName")
                  return <div title="Dicom header property"><span  style={labelStyle}>{prop}</span>{value}</div>;
                else
                  return <React.Fragment/>;
              })}
            </React.Fragment>
          }

        </Panel>
        {visit !=null &&
        <Panel header={"Related visit"}  style={{width:"25%"}}>
          <DataTable
            value={visitAsArray}>
            <Column header={"Property"} field={"name"}/>
            <Column header={"Value"}  field={"value"}/>
          </DataTable>
        </Panel>}
        {subject !=null &&
        <Panel header={"Related subject"}  style={{width:"25%"}}>
          <DataTable
            value={subjectAsArray}>
            <Column header={"Property"} field={"name"}/>
            <Column header={"Value"} field={"value"}/>
          </DataTable>
        </Panel>}
      </div>
    );
  }

  spaceBody(row){
    const {cohort} = this.props;

    if (row.space!=null && row.space.native!=null){
      if (row.space.native)
        return <div>{"native"}</div>;
      else
        return <div title={"Id of template: " +row.space.uuid}>{"non-native"} <i className={"fa fa-clipboard"}
                                                           onClick={()=>navigator.clipboard.writeText(row.space.uuid)}
                                                           title={"Copy id of target to clipboard"}
                                                           style={{color:"#00D2D0"}}/>
          <i className={"fa fa-balance-scale"}
             onClick={()=>{
               if (row.space.uuid!=null){
                 const images = getNestedProp(['images', 'list'], cohort);
                 const native = images!=null ? images.find(el=>el.uuid===row.space.uuid):null;
                 if (native!=null)
                   this.visualizeImage([native, row],row);
               }
             }}
             title={"Compare to target"}
             style={{color:"#00D2D0"}}/>
      </div>;
    }
    return <React.Fragment/>;
  }

  spaceFilter(value,filter){

    if (filter==="all" || filter === undefined || filter === null || (typeof filter !== 'string')) {
        return true;
    }
    if (filter==="native")
        return value!=null && value.native===true;
    if (filter==="non-native")
        return value!=null && value.native===false;
    return false;
  }

  idBody(propertyValue){
    return (
      <div title={propertyValue}>
        {propertyValue!=null
          ? propertyValue.substring(0,5) + '...'
          : ""
        }
        {propertyValue!=null &&
        <i className={"fa fa-clipboard"}
           onClick={()=>navigator.clipboard.writeText(propertyValue)}
           title={"Copy to clipboard"}
           style={{color:"#00D2D0"}}
        />}
      </div>
    );
  }


  render() {
    const {manualToolVisible,expandedAcquisitionRows} = this.state;
    const {cohort,updateManualToolSAM} = this.props;
    const dialogStyle =  {
        maxHeight: (window.innerHeight),
        width: "80vw",
        height: "35vw",
        overflowWrap: "break-word"
      };

    const contentStyle =  {
      maxHeight: (window.innerHeight),
      width: "80vw",
      height: "35vw"
    };

    let imageSrc = (cohort['logo']!=null && (cohort['logo']['type']==='url' || cohort['logo']['type']==='data') && cohort['logo']['value']!=null)
      ? cohort['logo']['value']
      : imagePlaceholder;


    return (
      <React.Fragment>
        <TabView >
          <TabPanel header={"Details"} headerClassName={"tabHeaderDetails"} >
            {
              cohort != null &&
              <React.Fragment>
              <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                <div style={{width:"33.3%"}}>
                  <h1>{cohort.name}</h1>
                  <a
                    target="_blank"
                    {...(cohort['logo'] != null && cohort['logo']['url'] != null) ? {href: cohort['logo']['url']} : {}}>
                    <img alt={' Logo'} className="cohortCardIcon" src={imageSrc} title={"Logo"}/>
                  </a>
                </div>
                <div style={{width:"33.3%",padding:"1em",lineHeight:"1.5em"}}>
                  <div title={"International Classification of Diseases"}>
                    <span style={labelStyle}>ICD:</span>
                    {getNestedProp(['diseases'],cohort)!=null && Array.isArray(getNestedProp(['diseases'],cohort)) &&
                    getNestedProp(['diseases'],cohort).map(el=>
                      <a target="_blank" style={{marginRight:"1em",backgroundColor:"azure"}}
                         href={el.url!=null? el.url : MOCK_LINK_TO_KEYS + el.icdCode}>{el.label} ({el.icdCode})</a>
                    )}
                  </div>
                  <div title={"Keywords"}>
                    <span style={labelStyle}>Keywords:</span>
                    {getNestedProp(['keywords'],cohort)!=null && Array.isArray(getNestedProp(['keywords'],cohort)) &&
                    getNestedProp(['keywords'],cohort).map(el=>
                      <a target="_blank" style={{marginRight:"1em",backgroundColor:"azure"}}
                         href={el.iri!=null? el.iri : "#"}>{el.label}</a>
                    )}
                  </div>
                  <div title="Created on">
                  <span
                    style={labelStyle}>Creation date:</span>{convertISODateToLocaleString(cohort[COHORT_PROPERTY_NAME__CREATION_DATE])}
                  </div>
                  <div title="PI">
                    <span style={labelStyle}>Principal Investigator:</span>{cohort.pi}
                  </div>
                  <div title="CI">
                    <span style={labelStyle}>Co-investigators:</span>
                    {cohort['co-investigators'] != null && Array.isArray(cohort['co-investigators']) && cohort['co-investigators'].join(", ")}
                  </div>
                </div>
                <div style={{width:"33.3%",padding:"1em"}}>
                  <InputTextarea value={cohort.description != null ? cohort.description : "No description yet"} rows={6} disabled={true} style={{opacity:"0.9",width:"100%",fontSize:"1em"}}/>
                </div>
              </div>
               <ContainerChartsPanel onClickCallback={this.setFilters}/>
              </React.Fragment>
            }
          </TabPanel>
          <TabPanel header={"Subjects"} headerClassName={"tabHeaderSubjects"}>
            <div style={filterNumbersStyle}>Filtered subjects:  <BlinkingText value={this.state.filteredSubjectsCounter}/>
              / {getNestedProp(['subjects', 'count'], cohort)} total</div>
            <Button label={"Clear filters"}
                    onClick={()=>{
                      this.setState(
                        {
                          filtersSubjects:{},
                          filteredSubjectsCounter:getNestedProp(['subjects', 'count'], cohort),
                          switchFilterSubjects:false
                        });
                      this.subDt.setState({filters:{}});
                      this.subDt.processData();
                    }}/>
            <DataTable
              ref={(el) => {
                this.subDt = el;
                if (el != null){
                  const rowCounter = el.processData().length;
                  if (rowCounter!=null && rowCounter!==this.state.filteredSubjectsCounter)
                    this.setState({filteredSubjectsCounter : rowCounter})
                }
              }}
              filters={this.state.filtersSubjects}
              onFilter={(e)=>{
                const data = this.subDt.processData();
                if (data!==null )
                  this.setState(
                    {
                      filteredSubjectsCounter : data.length,
                      filtersSubjects: Object.assign(e.filters,{})
                    }
                    )
              }}
              value={getNestedProp(['subjects', 'list'], cohort) != null ? getNestedProp(['subjects', 'list'], cohort) : []}>
              <Column header={"Id"} field={"uuid"} sortable={true} filter={this.state.switchFilterSubjects} filterMatchMode={"contains"} body={(row)=>this.idBody(getNestedProp(['uuid'],row))}/>
              <Column header={"Date of birth"} field={"dob"} sortable={true} filter={this.state.switchFilterSubjects}
                      filterMatchMode={"contains"}/>
              <Column header={"Sex"} field={"sex"} sortable={true} filter={this.state.switchFilterSubjects} filterMatchMode={"contains"}/>
              <Column header={"Folder"} field={"folderName"} sortable={true} filter={this.state.switchFilterSubjects}
                      filterMatchMode={"contains"}/>
              <Column header={"Original Id"} field={"originalCohortId"} sortable={true} filter={this.state.switchFilterSubjects}
                      filterMatchMode={"contains"}/>
              <Column header={"Created"} field={"creationDate"} sortable={true} filter={this.state.switchFilterSubjects}
                      filterMatchMode={"contains"}
                      body={(row) => convertISODateToLocaleString(row[COHORT_PROPERTY_NAME__CREATION_DATE])}/>
            </DataTable>

          </TabPanel>
          <TabPanel header={"Acquisitions"} headerClassName={"tabHeaderAcquisitions"}>

            <div style={filterNumbersStyle}>Filtered Acquisitions: <BlinkingText value={this.state.filteredImagesCounter}/>
             / {getNestedProp(['images', 'count'], cohort)} total</div>
            <Button label={"Clear filters"}
                    onClick={()=>{
                      this.setState(
                        {
                          filtersImages:{},
                          filteredImagesCounter:getNestedProp(['images', 'count'], cohort),
                          switchFilterAcquisitions:false,
                          spaceFilterValue:"all"
                        });
                      this.acqDt.setState({filters:{}});
                      this.acqDt.processData();
                    }}/>

            <DataTable
              ref={(el) => {
                this.acqDt = el;
                if (el != null){
                  const rowCounter = el.processData().length;
                  if (rowCounter!=null && rowCounter!==this.state.filteredImagesCounter)
                    this.setState({filteredImagesCounter : rowCounter})
                }
              }
              }
              filters={this.state.filtersImages}
              onFilter={(e)=>{
                const data = this.acqDt.processData();
                if (data!==null )
                this.setState(
                  {
                    filteredImagesCounter : data.length,
                    filtersImages:Object.assign(e.filters,{})
                })
              }}
              // scrollable={true}
              // style={{width:"calc(100vw - 50px)"}}
              expandedRows={expandedAcquisitionRows}
              onRowToggle={(e) => this.setState({expandedAcquisitionRows: e.data})}
              rowExpansionTemplate={this.acquisitionRowExpansionTemplate}
              value={getNestedProp(['images', 'list'], cohort) != null ? getNestedProp(['images', 'list'], cohort) : []}>
              <Column header={"Id"} field={"uuid"} body={(row)=>this.idBody(getNestedProp(['uuid'],row))} sortable={true} filter={this.state.switchFilterAcquisitions} filterMatchMode={"contains"}/>
              <Column header={"Modality"} field={"modality"} sortable={true} filter={this.state.switchFilterAcquisitions}
                      filterMatchMode={"contains"}/>
              <Column header={"Type"} field={"acquisitionType"} sortable={true} filter={this.state.switchFilterAcquisitions}
                      filterMatchMode={"contains"}/>
              <Column header={"Sequence"} field={"sequence_id"} sortable={true} filter={this.state.switchFilterAcquisitions}
                      filterMatchMode={"contains"}/>
              <Column header={"Space"} field={"space"} filter={this.state.switchFilterAcquisitions}  filterMatchMode="custom"
                      body={this.spaceBody}
                      filterElement={<Dropdown style={{width: '100%'}} className="ui-column-filter"
                                               value={this.state.spaceFilterValue}
                                               options={[{value:"all",label:"all"},{value:"native",label:"native"},{value:"non-native",label:"non-native"}]}
                                               onChange={(e) => {
                                                 this.setState({spaceFilterValue:e.value});
                                                 this.acqDt.filter(e.value, 'space', 'custom');
                                               }}/>}
                      filterFunction={this.spaceFilter}
              />
              <Column header={"Label"} field={"label"} sortable={true} filter={this.state.switchFilterAcquisitions} filterMatchMode={"contains"}/>
              <Column header={"Name"} field={"name"} sortable={true} filter={this.state.switchFilterAcquisitions} filterMatchMode={"contains"}/>

              {/*<Column header={<span title={"Echo time"}>{"TE"}</span>} body={row=>getNested(row,"dicomHeaderSummary.all.EchoTime")}/>*/}
              {/*<Column header={<span title={"Repetition time"}>{"TR"}</span>} body={row=>getNested(row,"dicomHeaderSummary.all.RepetitionTime")}/>*/}
              {/*<Column header={"Dimensions"} body={row=>getNested(row,"dicomHeaderSummary.all.DIM")} />*/}
              {/*<Column header={"Flip angle"} body={row=>getNested(row,"dicomHeaderSummary.all.FlipAngle")} />*/}
              {/*<Column header={"Echo Train Length"} body={row=>getNested(row,"dicomHeaderSummary.all.EchoTrainLength")} />*/}
              {/*<Column header={"Inversion Time"} body={row=>getNested(row,"dicomHeaderSummary.all.InversionTime")} />*/}
              {/*<Column header={"MR Acq. Type"} body={row=>getNested(row,"dicomHeaderSummary.all.MRAcquisitionType")} />*/}
              {/*<Column header={"Manufacturer"} body={row=>getNested(row,"dicomHeaderSummary.all.Manufacturer")} />*/}
              {/*<Column header={"Model Name"} body={row=>getNested(row,"dicomHeaderSummary.all.ManufacturerModelName")} />*/}
              {/*<Column header={"Pixel Bandwidth"} body={row=>getNested(row,"dicomHeaderSummary.all.PixelBandwidth")} />*/}
              {/*<Column header={"Pixel Spacing"} body={row=>getNested(row,"dicomHeaderSummary.all.PixelSpacing")} />*/}
              {/*<Column header={"Protocol"} body={row=>getNested(row,"dicomHeaderSummary.all.ProtocolName")} />*/}
              {/*<Column header={"Receive Coil"} body={row=>getNested(row,"dicomHeaderSummary.all.ReceiveCoilName")} />*/}
              {/*<Column header={"Samples Per Pixel"} body={row=>getNested(row,"dicomHeaderSummary.all.SamplesPerPixel")} />*/}
              {/*<Column header={"Scan options"} body={row=>getNested(row,"dicomHeaderSummary.all.ScanOptions")} />*/}
              {/*<Column header={"ScanningSequence"} body={row=>getNested(row,"dicomHeaderSummary.all.ScanningSequence")} />*/}
              {/*<Column header={"Sequence Name"} body={row=>getNested(row,"dicomHeaderSummary.all.SequenceName")} />*/}
              {/*<Column header={"Sequence Variant"} body={row=>getNested(row,"dicomHeaderSummary.all.SequenceVariant")} />*/}
              {/*<Column header={"Series Description"} body={row=>getNested(row,"dicomHeaderSummary.all.SeriesDescription")} />*/}
              {/*<Column header={"Series Number"} body={row=>getNested(row,"dicomHeaderSummary.all.SeriesNumber")} />*/}
              {/*<Column header={"Series Time"} body={row=>getNested(row,"dicomHeaderSummary.all.SeriesTime")} />*/}
              {/*<Column header={"Slice Thickness"} body={row=>getNested(row,"dicomHeaderSummary.all.SliceThickness")} />*/}
              {/*<Column header={"Study Date"} body={row=>getNested(row,"dicomHeaderSummary.all.StudyDate")} />*/}
              {/*<Column header={"Study Description"} body={row=>getNested(row,"dicomHeaderSummary.all.StudyDescription")} />*/}

              <Column header={"Subject"} field={"reference.subjectId"} sortable={true} filter={this.state.switchFilterAcquisitions}
                      filterMatchMode={"contains"} body={(row)=>this.idBody(getNestedProp(['reference','subjectId'],row))}/>
              <Column header={"Visit"} field={"reference.visitId"} sortable={true} filter={this.state.switchFilterAcquisitions}
                      filterMatchMode={"contains"} body={(row)=>this.idBody(getNestedProp(['reference','visitId'],row))}/>
              <Column header={"Created"} field={"creationDate"} sortable={true} filter={this.state.switchFilterAcquisitions}
                      filterMatchMode={"contains"}
                      body={(row) => convertISODateToLocaleString(row[COHORT_PROPERTY_NAME__CREATION_DATE])}/>
              <Column header={"See"}
                      style={{width:"4em"}}
                      body={(row) => <Button icon={"fa fa-eye"}
                                             onClick={()=>this.visualizeImage([row],row)}/>}
              />
              <Column header={"Details"}
                      style={{width:"4em"}}
                      expander={true}
              />
            </DataTable>
          </TabPanel>
          <TabPanel header={"Visits"}  headerClassName={"tabHeaderVisits"}>
            <div style={filterNumbersStyle}>Filtered visits:  <BlinkingText value={this.state.filteredVisitsCounter}/>
              / {getNestedProp(['visits', 'count'], cohort)} total</div>
            <Button label={"Clear filters"}
                    onClick={()=>{
                      this.setState(
                        {
                          filtersVisits:{},
                          filteredVisitsCounter:getNestedProp(['visits', 'count'], cohort),
                          switchFilterVisits:false
                        });
                      this.visDt.setState({filters:{}});
                      this.visDt.processData();
                    }}/>
            <DataTable
              filters={this.state.filtersVisits}
              onFilter={(e)=>{
                const data = this.visDt.processData();
                if (data!==null )
                  this.setState({
                    filteredVisitsCounter : data.length,
                    filtersVisits:Object.assign(e.filters,{})
                  })
              }}
              ref={(el) =>{
                this.visDt = el;
                if (el != null){
                  const rowCounter = el.processData().length;
                  if (rowCounter!=null && rowCounter!==this.state.filteredVisitsCounter)
                    this.setState({filteredVisitsCounter : rowCounter})
                }
              }}
              value={getNestedProp(['visits', 'list'], cohort) != null ? getNestedProp(['visits', 'list'], cohort) : []}>
              <Column header={"Id"} field={"uuid"} sortable={true} filter={this.state.switchFilterVisits} filterMatchMode={"contains"} body={(row)=>this.idBody(getNestedProp(['uuid'],row))}/>
              <Column header={"Type"} field={"visitType"} sortable={true} filter={this.state.switchFilterVisits} filterMatchMode={"contains"}/>
              <Column header={"Label"} field={"label"} sortable={true} filter={this.state.switchFilterVisits} filterMatchMode={"contains"}/>
              <Column header={"Subject"} field={"reference.subjectId"} sortable={true} filter={this.state.switchFilterVisits}
                      filterMatchMode={"contains"} body={(row)=>this.idBody(getNestedProp(['reference','subjectId'],row))}/>
              <Column header={"Visit date"} field={"date"} sortable={true} filter={this.state.switchFilterVisits} filterMatchMode={"contains"}
                      body={(row) => convertISODateToLocaleString(row['date'])}/>
              <Column header={"Created"} field={"creationDate"} sortable={true} filter={this.state.switchFilterVisits}
                      filterMatchMode={"contains"}
                      body={(row) => convertISODateToLocaleString(row[COHORT_PROPERTY_NAME__CREATION_DATE])}/>
            </DataTable>
          </TabPanel>
          <TabPanel header={"ROIs"} headerClassName={"tabHeaderROIs"}>
            <div style={filterNumbersStyle}>Filtered ROIs:  <BlinkingText value={this.state.filteredROIsCounter}/>
              / {getNestedProp(['rois', 'count'], cohort)} total</div>
            <Button label={"Clear filters"}
                    onClick={()=>{
                      this.setState(
                        {
                          filtersROIs:{},
                          filteredROIsCounter:getNestedProp(['rois', 'count'], cohort),
                          switchFilterROIs:false
                        });
                      this.roiDt.setState({filters:{}});
                      this.roiDt.processData();
                    }}/>
            <DataTable
              filters={this.state.filtersROIs}
              onFilter={(e)=>{
                const data = this.roiDt.processData();
                if (data!==null )
                  this.setState(
                    {
                      filteredROIsCounter : data.length,
                      filtersROIs: Object.assign(e.filters,{})
                    })
              }}
              ref={(el) => {
                this.roiDt = el;
                if (el != null){
                  const rowCounter = el.processData().length;
                  if (rowCounter!=null && rowCounter!==this.state.filteredROIsCounter)
                    this.setState({filteredROIsCounter : rowCounter})
                }
              }}
              value={getNestedProp(['rois', 'list'], cohort) != null ? getNestedProp(['rois', 'list'], cohort) : []}>
              <Column header={"Id"} field={"uuid"} sortable={true} filter={this.state.switchFilterROIs} filterMatchMode={"contains"} body={(row)=>this.idBody(getNestedProp(['uuid'],row))}/>
              <Column header={"Type"} field={"typeROI"} sortable={true} filter={this.state.switchFilterROIs} filterMatchMode={"contains"} />
              <Column header={"Created"} field={"creationDate"} sortable={true} filter={this.state.switchFilterROIs}
                      filterMatchMode={"contains"}
                      body={(row) => convertISODateToLocaleString(row[COHORT_PROPERTY_NAME__CREATION_DATE])}/>
              <Column header={"See"}
                      style={{width:"4em"}}
                      body={(row) => <Button icon={"fa fa-eye"}
                                             onClick={()=>this.visualizeROI(row)}/>}
              />

            </DataTable>

          </TabPanel>
          <TabPanel header={"Annotations"} headerClassName={"tabHeaderAnnotations"}>
            <ContainerAnnotationTabPanel/>
          </TabPanel>
          <TabPanel header={"Files"} headerClassName={"tabHeaderFiles"}>
            <div style={{width:"50%"}}>
              <h1>Cohort Files (Physical view)</h1>
              {cohort.structure != null &&
              <Tree id={"physicalViewTree"+cohort.uuid} value={cohort.structure} style={{width: "100%",height:"50%"}}
                    selectionChange={() => {
                    }}/>
              }
            </div>
          </TabPanel>
        </TabView>
        <Dialog
          onHide={()=>{
            this.setState({manualToolVisible:false,visualToolLabel:null});
            updateManualToolSAM(null);
          }}
          header={this.state.visualToolLabel}
          closeOnEscape={true}
          resizable={true}
          visible={manualToolVisible}
          modal={false}
          style={dialogStyle}
          contentStyle={contentStyle}
        >
          {manualToolVisible  && getNestedProp(["miniWorkflow"],this.props.manualToolConfiguration) !=null &&
          <ContainerManualTool
            predefinedToolConfiguration={this.props.manualToolConfiguration}
          />}


        </Dialog>
      </React.Fragment>
    )
  }
}

const labelStyle = {
  width: "10em",
  display: "inline-block"
};

const filterNumbersStyle={
  fontSize:"larger",
  display:"inline-block",
  marginRight:"1em"
};
export default CohortsDetails;

CohortsDetails.propTypes = { //ALL props are passed from CohortSelectionMenu via  {...this.props}
  cohort: PropTypes.object,
  getCohort: PropTypes.func,
  clearCohort: PropTypes.func,
  createManualToolConfigurationWithROI: PropTypes.func,
  updateManualToolSAM:PropTypes.func
};