//Constantes for actions

//Study (Cohorts) **********************************************************************
export const STUDY_REQUEST = 'STUDY_REQUEST'; //Loading the studies
export const STUDY_FAIL = 'STUDY_FAIL';
export const STUDY_SUCCESS = 'STUDY_SUCCESS';
export const STUDY_CLEAR = 'STUDY_CLEAR';

export const STUDY_LIST_REQUEST = 'STUDY_LIST_REQUEST';
export const STUDY_LIST_FAIL = 'STUDY_LIST_FAIL';
export const STUDY_LIST_SUCCESS = 'STUDY_LIST_SUCCESS';

export const SET_COHORT_VISIBILITY_FILTER = 'SET_COHORT_VISIBILITY_FILTER';
export const SET_COHORT_SORTER = 'SET_COHORT_SORTER';

//Session ********************************************************************
export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_FAIL = 'SESSION_FAIL';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';

export const SESSION_LIST_REQUEST = 'SESSION_LIST_REQUEST';
export const SESSION_LIST_FAIL = 'SESSION_LIST_FAIL';
export const SESSION_LIST_SUCCESS = 'SESSION_LIST_SUCCESS';

//Subject ********************************************************************
export const SUBJECT_REQUEST = 'SUBJECT_REQUEST';
export const SUBJECT_FAIL = 'SUBJECT_FAIL';
export const SUBJECT_SUCCESS = 'SUBJECT_SUCCESS';

export const SUBJECT_LIST_REQUEST = 'SUBJECT_LIST_REQUEST';
export const SUBJECT_LIST_FAIL = 'SUBJECT_LIST_FAIL';
export const SUBJECT_LIST_SUCCESS = 'SUBJECT_LIST_SUCCESS';


//Image **********************************************************************
export const IMAGE_INFO_REQUEST = 'IMAGE_INFO_REQUEST';
export const IMAGE_INFO_FAIL = 'IMAGE_INFO_FAIL';
export const IMAGE_INFO_SUCCESS = 'IMAGE_INFO_SUCCESS';

export const IMAGE_RAW_REQUEST = 'IMAGE_RAW_REQUEST';
export const IMAGE_RAW_FAIL = 'IMAGE_RAW_FAIL';
export const IMAGE_RAW_SUCCESS = 'IMAGE_RAW_SUCCESS';

//Filters ********************************************************************
export const STUDY_FILTER = 'STUDY_FILTER';
export const STUDY_FILTER_PRECISE = 'STUDY_FILTER_PRECISE';
export const STUDY_FILTER_TOGGLE = 'STUDY_FILTER_TOGGLE';
export const STUDY_FILTER_NBRPATIENTS = 'STUDY_FILTER_NBRPATIENTS';

//Variables ********************************************************************
export const VARIABLES_REQUEST = "VARIABLES_REQUEST";
export const VARIABLES_FAIL = "VARIABLES_FAIL";
export const VARIABLES_SUCCESS = "VARIABLES_SUCCESS";


export const CLINICAL_VARIABLES_REQUEST = "CLINICAL_VARIABLES_REQUEST";
export const CLINICAL_VARIABLES_FAIL  = "CLINICAL_VARIABLES_FAIL";
export const CLINICAL_VARIABLES_SUCCESS  = "CLINICAL_VARIABLES_SUCCESS";

export const ANATOMICAL_VARIABLES_REQUEST = "ANATOMICAL_VARIABLES_REQUEST";
export const ANATOMICAL_VARIABLES_FAIL  = "ANATOMICAL_VARIABLES_FAIL";
export const ANATOMICAL_VARIABLES_SUCCESS  = "ANATOMICAL_VARIABLES_SUCCESS";

export const PATHOLOGICAL_VARIABLES_REQUEST = "PATHOLOGICAL_VARIABLES_REQUEST";
export const PATHOLOGICAL_VARIABLES_FAIL  = "PATHOLOGICAL_VARIABLES_FAIL";
export const PATHOLOGICAL_VARIABLES_SUCCESS  = "PATHOLOGICAL_VARIABLES_SUCCESS";

export const META_VARIABLES_REQUEST = "META_VARIABLES_REQUEST";
export const META_VARIABLES_FAIL  = "META_VARIABLES_FAIL";
export const META_VARIABLES_SUCCESS  = "META_VARIABLES_SUCCESS";

export const MEASUREMENT_VARIABLES_REQUEST = "MEASUREMENT_VARIABLES_REQUEST";
export const MEASUREMENT_VARIABLES_FAIL  = "MEASUREMENT_VARIABLES_FAIL";
export const MEASUREMENT_VARIABLES_SUCCESS  = "MEASUREMENT_VARIABLES_SUCCESS";

export const VARIABLE_REQUEST = "VARIABLE_REQUEST";
export const VARIABLE_FAIL = "VARIABLE_FAIL";
export const VARIABLE_SUCCESS = "VARIABLE_SUCCESS";


//Models ***********************************************************************
export const MODELS_REQUEST = "MODELS_REQUEST";
export const MODELS_FAIL = "MODELS_FAIL";
export const MODELS_SUCCESS = "MODELS_SUCCESS";

export const SET_MODELS_VISIBILITY_FILTER = 'SET_MODELS_VISIBILITY_FILTER';
export const SET_MODELS_SORTER = 'SET_MODELS_SORTER';

export const MODEL_REQUEST = "MODEL_REQUEST";
export const MODEL_FAIL = "MODEL_FAIL";
export const MODEL_SUCCESS = "MODEL_SUCCESS";

//Operators ***********************************************************************
export const OPERATORS_REQUEST = "OPERATORS_REQUEST";
export const OPERATORS_FAIL = "OPERATORS_FAIL";
export const OPERATORS_SUCCESS = "OPERATORS_SUCCESS";



//Annotations******************************************************************
export const ANNOTATION_DEFINITIONS_LIST_UPDATE = "ANNOTATION_DEFINITIONS_LIST_UPDATE";
export const ANNOTATION_DEFINITIONS_LIST_REQUEST = "ANNOTATION_DEFINITIONS_LIST_REQUEST";
export const ANNOTATION_DEFINITIONS_LIST_FAIL = "ANNOTATION_DEFINITIONS_LIST_FAIL";
export const ANNOTATION_DEFINITIONS_LIST_SUCCESS = "ANNOTATION_DEFINITIONS_LIST_SUCCESS";
export const ANNOTATION_DATA_UPDATE = "ANNOTATION_DATA_UPDATE";

export const ANNOTATION_LIST_REQUEST = "ANNOTATION_LIST_REQUEST";
export const ANNOTATION_LIST_FAIL = "ANNOTATION_LIST_FAIL";
export const ANNOTATION_LIST_SUCCESS = "ANNOTATION_LIST_SUCCESS";


export const ANNOTATION_DEFINITION_SAVE_REQUEST = "ANNOTATION_DEFINITION_SAVE_REQUEST";
export const ANNOTATION_DEFINITION_SAVE_FAIL = "ANNOTATION_DEFINITION_SAVE_FAIL";
export const ANNOTATION_DEFINITION_SAVE_SUCCESS = "ANNOTATION_DEFINITION_SAVE_SUCCESS";
export const CLEAR_ANNOTATION_DEFINITION_SAVE = "CLEAR_ANNOTATION_DEFINITION_SAVE";

export const ANNOTATION_SAVE_REQUEST = "ANNOTATION_SAVE_REQUEST";
export const ANNOTATION_SAVE_FAIL = "ANNOTATION_SAVE_FAIL";
export const ANNOTATION_SAVE_SUCCESS = "ANNOTATION_SAVE_SUCCESS";
export const CLEAR_ANNOTATION_SAVE = "CLEAR_ANNOTATION_SAVE";



export const PIN_UNDO = "PIN_UNDO";
export const PIN_REDO = "PIN_REDO";
export const UPDATE_PIN_HISTORY = "UPDATE_PIN_HISTORY";


export const ANNOTATION_DEFINITION_REQUEST = "ANNOTATION_DEFINITION_REQUEST";
export const ANNOTATION_DEFINITION_FAIL = "ANNOTATION_DEFINITION_FAIL";
export const ANNOTATION_DEFINITION_SUCCESS = "ANNOTATION_DEFINITION_SUCCESS";

export const ANNOTATION_DATA_REQUEST = "ANNOTATION_DATA_REQUEST";
export const ANNOTATION_DATA_FAIL = "ANNOTATION_DATA_FAIL";
export const ANNOTATION_DATA_SUCCESS = "ANNOTATION_DATA_SUCCESS";

export const SUCCESS_ANNOTATION_DATA_SAVE = "SUCCESS_ANNOTATION_DATA_SAVE";

export const ANNOTATION_FORM_DEFINITION_REQUEST="ANNOTATION_FROM_DEFINITION_REQUEST";
export const ANNOTATION_FORM_DEFINITION_FAIL="ANNOTATION_FROM_DEFINITION_FAIL";
export const ANNOTATION_FORM_DEFINITION_SUCCESS="ANNOTATION_FROM_DEFINITION_SUCCESS";

export const ANNOTATION_FORM_DATA_REQUEST = "ANNOTATION_FORM_DATA_REQUEST";
export const ANNOTATION_FORM_DATA_FAIL = "ANNOTATION_FORM_DATA_FAIL";
export const ANNOTATION_FORM_DATA_SUCCESS = "ANNOTATION_FORM_DATA_SUCCESS";

export const ANNOTATION_FORM_DATA_UPDATE = "ANNOTATION_FORM_DATA_UPDATE";

export const UPDATE_ANNOTATION_FORM_DOCUMENT_ID="UPDATE_ANNOTATION_FORM_DOCUMENT_ID";

export const ROI_LIST_REQUEST = "ROI_LIST_REQUEST";
export const ROI_LIST_FAIL = "ROI_LIST_FAIL";
export const ROI_LIST_SUCCESS = "ROI_LIST_SUCCESS";

export const ROI_GET_REQUESTED = "ROI_GET_REQUESTED";
export const ROI_GET_FAIL = "ROI_GET_FAIL";
export const ROI_GET_SUCCESS = "ROI_GET_SUCCESS";
export const CLEAR_ROI = "CLEAR_ROI";


export const ANNOTATION_IMAGE_SUCCESS ="ANNOTATION_IMAGE_SUCCESS";
export const ANNOTATION_IMAGE_FAIL ="ANNOTATION_IMAGE_FAIL";
export const ANNOTATION_IMAGE_REQUESTED="ANNOTATION_IMAGE_REQUESTED";
export const ANNOTATION_IMAGE_SET_QUANTILES="ANNOTATION_IMAGE_SET_QUANTILES";

export const UPDATE_ACTIVE_ROI="UPDATE_ACTIVE_ROI";
export const UPDATE_ANNOTATION_DOCUMENT_ID="UPDATE_ANNOTATION_DOCUMENT_ID";


export const LABELMAP_IMAGE_SUCCESS ="LABELMAP_IMAGE_SUCCESS";
export const LABELMAP_IMAGE_REQUESTED ="LABELMAP_IMAGE_REQUESTED";
export const LABELMAP_IMAGE_FAIL ="LABELMAP_IMAGE_FAIL";
export const CLEAR_ANNOTATOR_STATE="CLEAR_ANNOTATOR_STATE";


export const CLEAR_VIEWERS_STATE="CLEAR_VIEWERS_STATE";
export const UPDATE_VIEWERS_STATE="UPDATE_VIEWERS_STATE";
export const UPDATE_SINGLE_VIEWER_STATE="UPDATE_SINGLE_VIEWER_STATE";
export const UPDATE_SINGLE_VIEWER_PROPERTY="UPDATE_SINGLE_VIEWER_PROPERTY";

export const UPDATE_IMAGE_OPTIONS = "UPDATE_IMAGE_OPTIONS";

// ---------------Forms ---------------------------------
export const CLEAR_FORMS_STATE="CLEAR_FORMS_STATE";
export const UPDATE_FORMS_STATE="UPDATE_FORMS_STATE";
export const UPDATE_SINGLE_FORM_STATE="UPDATE_SINGLE_FORM_STATE";
export const UPDATE_SINGLE_FORM_PROPERTY="UPDATE_SINGLE_FORM_PROPERTY";

// ----------------Manual Tool---------------------------
export const MANUAL_TOOL_CONFIGURATION_REQUESTED="MANUAL_TOOL_CONFIGURATION_REQUESTED";
export const MANUAL_TOOL_CONFIGURATION_SUCCESS="MANUAL_TOOL_CONFIGURATION_SUCCESS";
export const MANUAL_TOOL_CONFIGURATION_FAIL="MANUAL_TOOL_CONFIGURATION_FAIL";

export const UPDATE_MANUAL_TASK_STATE="UPDATE_MANUAL_TASK_STATE";

// ----------------Manual Tool---------------------------
export const UPDATE_MANUAL_TOOL_PROPERTY="UPDATE_MANUAL_TOOL_PROPERTY";
export const UPDATE_MANUAL_TOOL_STATE="UPDATE_MANUAL_TOOL_STATE";
export const CLEAR_MANUAL_TOOL_STATE="CLEAR_MANUAL_TOOL_STATE";
export const UPDATE_MANUAL_TOOL_CONFIGURATION="UPDATE_MANUAL_TOOL_CONFIGURATION";


// ----------------Interactive Plot --------------------------
export const UPDATE_PLOT_SETTINGS = "UPDATE_PLOT_SETTINGS";
export const UPDATE_PLOT_DATA = "UPDATE_PLOT_DATA";
export const UPDATE_RAW_PLOT_DATA = "UPDATE_RAW_PLOT_DATA";
export const UPDATE_PLOT_DATA_STATE = "UPDATE_PLOT_DATA_STATE";
export const CLEAR_INTERACTIVE_PLOT = "CLEAR_INTERACTIVE_PLOT";

//-------------------Scenes-------------------------------------
export const UPDATE_SCENES = "UPDATE_SCENES";
export const UPDATE_SCENE_ACTIVE_LAYER = "UPDATE_SCENE_ACTIVE_LAYER";
export const UPDATE_SCENE_INTERACTIVE_LAYER = "UPDATE_SCENE_INTERACTIVE_LAYER";
export const UPDATE_SCENE_ACTIVE_COLOR = "UPDATE_SCENE_ACTIVE_COLOR";

export const UPDATE_SCENE_LAYER_COLOR = "UPDATE_SCENE_LAYER_COLOR";

//-------------------LUT----------------------------------------
export const LUT_REQUEST_SUCCESS = "LUT_REQUEST_SUCCESS";
export const LUT_REQUESTED = "LUT_REQUESTED";
export const LUT_REQUEST_FAILED = "LUT_REQUEST_FAILED";


//-------------------LUT DESCRIPTIONS---------------------------------------
export const LUT_DESCRIPTION_REQUEST_SUCCESS = "LUT_DESCRIPTION_REQUEST_SUCCESS";
export const LUT_DESCRIPTION_REQUESTED = "LUT_DESCRIPTION_REQUESTED";
export const LUT_DESCRIPTION_REQUEST_FAILED = "LUT_DESCRIPTION_REQUEST_FAILED";


//-------------------POLY (DATA)---------------------------------------
export const POLY_REQUEST_SUCCESS = "POLY_REQUEST_SUCCESS";
export const POLY_REQUESTED = "POLY_REQUESTED";
export const POLY_REQUEST_FAILED = "POLY_REQUEST_FAILED";



//-------------------ONTOLOGY ---------------------------------------
export const ONTOLOGY_LIST_REQUEST_SUCCESS = "ONTOLOGY_LIST_REQUEST_SUCCESS";
export const ONTOLOGY_LIST_REQUESTED = "ONTOLOGY_LIST_REQUESTED";
export const ONTOLOGY_LIST_REQUEST_FAILED = "ONTOLOGY_LIST_REQUEST_FAILED";



export const ONTOLOGY_SAVE_REQUEST = "ONTOLOGY_SAVE_REQUEST";
export const ONTOLOGY_SAVE_FAIL = "ONTOLOGY_SAVE_FAIL";
export const ONTOLOGY_SAVE_SUCCESS = "ONTOLOGY_SAVE_SUCCESS";
export const CLEAR_ONTOLOGY_SAVE = "CLEAR_ONTOLOGY_SAVE";



//-------------------QUESTION ---------------------------------------
export const QUESTION_LIST_REQUEST_SUCCESS = "QUESTION_LIST_REQUEST_SUCCESS";
export const QUESTION_LIST_REQUESTED = "QUESTION_LIST_REQUESTED";
export const QUESTION_LIST_REQUEST_FAILED = "QUESTION_LIST_REQUEST_FAILED";

export const QUESTION_SAVE_REQUEST = "QUESTION_SAVE_REQUEST";
export const QUESTION_SAVE_FAIL = "QUESTION_SAVE_FAIL";
export const QUESTION_SAVE_SUCCESS = "QUESTION_SAVE_SUCCESS";
export const CLEAR_QUESTION_SAVE = "CLEAR_QUESTION_SAVE";

