import React from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {Card} from "primereact/components/card/Card";
import {InputText} from "primereact/components/inputtext/InputText";
import {DemoCard} from "./DemoCard";


export async function loadRegister() {
    let response = await fetch('/dist/jsonDocs/tools/register.json');
    const data = await response.json();
    return await data;
}


/**
 * This is just demo to present Tools available in SPINE.
 * Uses /jsonDocs/tools/register.json with all registered demos.
 * The routings are handled by TaskView - all manual task configurations are handled by one component there.
 * This needs to be connected with "Demo" version of images.router in backend.
 * @returns {*}
 */
class DemosPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            register: {},
            filter:""
        }
        ;
    }
    componentDidMount() {
        loadRegister().then((data) =>
            this.setState({isLoading: false, register: data})
        );
    }

    render() {
        const {register} = this.state;
        const footer = <i className="fa fa-check" title="You can play with it"/>;
        const footerCrashed = <i className="fa fa-close" title="Temporarily unavailable"/>;

        return (
            <React.Fragment>
                <div style={{    padding: "10px", position: "fixed",  backgroundColor: "aquamarine"}}>
                    <label>Search:</label><InputText onChange={(e)=>this.setState({filter:e.target.value})} value={this.state.filter}/>
                </div>
                <div style={{
                    flexDirection: "row",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    paddingTop:"40px"
                }}>
                    {!this.state.isLoading && this.state.register != null &&
                    Object.keys(register)
                        .filter(el=>{return register[el]['title'].toLowerCase().includes(this.state.filter.toLowerCase()) || register[el]['description'].toLowerCase().includes(this.state.filter.toLowerCase())})
                        .map(el => <Link to={(register[el]['status'] === 'ENABLED')
                            ?{pathname:`/demo/${el}`, demoProps:register[el].title}
                            :'/demo'}>
                        <DemoCard
                            footer={(register[el]['status'] === 'ENABLED') ? footer : footerCrashed}
                            headerClassName={register[el]['style']['icon']}
                            title={register[el]['title']}
                            subTitle={register[el]['subTitle']}
                            description={register[el]['description']}
                            filter={this.state.filter}
                        />
                        </Link>)
                    }
                </div>
            </React.Fragment>
        );
    };
}

export default DemosPage;
DemosPage.propTypes = {
    miniWorkflowPath: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired
};