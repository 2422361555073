import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";
import PropTypes from "prop-types";

/**
 * Layout component for displaying six squares in a grid.
 *
 */
class SixPackLayout extends React.Component {
  static getLabel(){
    return "Six square panels in 2 rows with widget Toolbar"
  }
  static getClassName(){
    return "birdsDemo"
  }
  static getViewersPortKeys(){
    return ["upperLeft","upperMiddle","upperRight","lowerLeft","lowerMiddle","lowerRight"];
  }

  constructor(props) {
    super(props);
    this.state={
      viewportHeight:null
    };
    ["calcLayout"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }
  componentDidMount() {
    this.calcLayout();
    window.addEventListener("resize",this.calcLayout);
  }
  calcLayout() {

    const mainHeight = document.getElementsByClassName("mainContents")[0].clientHeight;
    const widgetsHeight = document.getElementById("manualToolContainer").clientHeight;

      this.setState({
        viewportHeight:(mainHeight-widgetsHeight)/2 - controlPanelHeight-4,
      });


  }
  render() {
    const {toolComponentProps, toolComponentState,widgetPanel,importExportPanel} = this.props;
    const viewersState = toolComponentProps.viewersState;

    const upperMiddle = (viewersState != null && viewersState.hasOwnProperty("upperMiddle"))
      ? BrowserFactory.build("upperMiddle", toolComponentProps, toolComponentState)
      : <div title="Middle viewer here."/>;
    const upperLeft = (viewersState != null && viewersState.hasOwnProperty("upperLeft"))
      ? BrowserFactory.build("upperLeft", toolComponentProps, toolComponentState)
      : <div title="Upper-left reference viewer here."/>;
    const upperRight = (viewersState != null && viewersState.hasOwnProperty("upperRight"))
      ? BrowserFactory.build("upperRight", toolComponentProps, toolComponentState)
      : <div title="Upper-right reference viewer here."/>;

    const lowerLeft = (viewersState != null && viewersState.hasOwnProperty("lowerLeft"))
      ? BrowserFactory.build("lowerLeft", toolComponentProps, toolComponentState)
      : <div title="Lower-left reference viewer here."/>;
    const lowerMiddle = (viewersState != null && viewersState.hasOwnProperty("lowerMiddle"))
      ? BrowserFactory.build("lowerMiddle", toolComponentProps, toolComponentState)
      : <div title="Lower-middle reference viewer here."/>;
    const lowerRight = (viewersState != null && viewersState.hasOwnProperty("lowerRight"))
      ? BrowserFactory.build("lowerRight", toolComponentProps, toolComponentState)
      : <div title="Lower-right reference viewer here."/>;

    return (
      <React.Fragment>
        <div>{widgetPanel}{importExportPanel}</div>
        <div className="ui-g-12">
          {this.state.viewportHeight != null &&
          <div style={sixPackGrid(this.state.viewportHeight)}>
            <div>{upperLeft}</div>
            <div>{upperMiddle}</div>
            <div>{upperRight}</div>
            <div>{lowerLeft}</div>
            <div>{lowerMiddle}</div>
            <div>{lowerRight}</div>
          </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

export default SixPackLayout;

SixPackLayout.defaultProps = {};

SixPackLayout.propTypes = {
  widgetPanel:PropTypes.object.isRequired
};


const sixPackGrid =(h)=> {return {
  display: "grid",
  gridTemplateColumns: `repeat(3, ${h}px)`,
  gridTemplateRows: `repeat(2, ${h}px)`,
  gridGap: `${controlPanelHeight}px 4px`,
  justifyContent: "left"
}
};

const controlPanelHeight = 36;