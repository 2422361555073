import {getNestedProp} from "../../helpers/expressions";
import {getMeasurementShortLabel} from "./SAMAction";


/**
 * Creates CSV format for dataset. Contributions are presented in rows.
 * @param fullState - dataset JSON as defined in https://github.com/SPINEProject/SPINE_V.2.0/issues/962
 * @param separator - separator (comma, semicolon, tab)
 * @return {string} - CSV format data
 */
export function getLongFormatData(fullState,separator){
  let csv = '\uFEFF';
  const quote = "\'";
  const c = getNestedProp(["rawData", "contributions"], fullState);

  const rMap = generateRaterIdToNameMap(fullState);
  csv += '"CASE ID"'+separator+'"DATE"'+separator+'"RATER_ID"'+separator+'"RATER_NAME"'+separator+'"MEASUREMENT_NAME"'+separator+'"VALUE"'+separator+'"ROI_ID"'+separator+'"WORKFLOW_ID"\n';
  if (Array.isArray(c)) {
    return csv.concat(c.map(el => {
      return quote+el.caseId+quote + separator +el.contributionDate +separator + el.contributorId + separator + rMap.get(el.contributorId) + separator + getMeasurementName(el.measurementConfigurationId,fullState) + separator + el.value + separator + el.roiId + separator + el.workflowId;
    }).join("\n"))
  } else return csv + "Empty dataset";
}

/**
 * Creates CSV format for dataset. Cases are presented in rows.
 * @param fullState - dataset JSON as defined in https://github.com/SPINEProject/SPINE_V.2.0/issues/962
 * @param separator - separator (comma, semicolon, tab)
 * @param treatment - flag indicating whether additional columns duplicating treatment should be created
 * @return {string} - CSV format data
 */
export function getWideFormatData(fullState,separator, treatment = false){
  let csv = '\uFEFF';
  const quote = "\'";
  const TREATMENT_NAME = "Treatment_side";
  const c = getNestedProp(["rawData", "contributions"], fullState);


  const rMap = generateRaterIdToNameMap(fullState);

  const cases = getNestedProp(["rawData", "cases"], fullState);
  if (Array.isArray(c) && Array.isArray(cases)) {

    const jointKeys =new Map();
    const trArray =[];
    const repetitionsArray=[]; // handle repetitions
    trArray.push(["CASE_ID", ...cases.map(el=>quote+el.value+quote)]); // generate transposed table with cases as columns

    if (treatment) {
      trArray.push([{c:undefined,m:TREATMENT_NAME,w:undefined}, ...new Array(cases.length)]); // generate transposed table with cases as columns
      jointKeys.set(undefined+TREATMENT_NAME+undefined,{objectKey:{c:undefined,m:TREATMENT_NAME,w:undefined},index:1});
    }

    c.forEach(el=>{
      const objectKey = {c:el.contributorId, m: el.measurementConfigurationId,  w:el.workflowId};
      const key = el.contributorId+el.measurementConfigurationId+el.workflowId;
      let keyIndex = jointKeys.get(key);
      let index = 0;
      let casesIndex = trArray[0].findIndex(cs=>cs===quote+el.caseId+quote);
      if (keyIndex === undefined){ // if rater-measurement-workflow row not created
        index = jointKeys.size + 1;
        jointKeys.set(key,{objectKey:objectKey,index:index});
        const row = new Array(cases.length+1);
        row[0] = objectKey;
        row[casesIndex] = el.value;
        trArray.push(row);
      } else {
        index=keyIndex.index;
        if (trArray[index][casesIndex]!=null){ // if rater-measurement-workflow row  created check for repetitions
          repetitionsArray.push({el,key,casesIndex});
        }
        else
          trArray[index][casesIndex] = el.value;
      }
    });

    if (repetitionsArray.length > 0){  // add repetitions as next occurrences of cases
      const repeatedKeys = new Map();
      do{
        const rep = repetitionsArray.pop();
        let batchIndex = 2;
        let stopCriterion = false;
        do{
          const cKey = rep.key+"_batch_"+batchIndex;
          const objectKey = {c:rep.el.contributorId, m: rep.el.measurementConfigurationId,  w:rep.el.workflowId, b:"_batch_"+batchIndex};
          let keyIndex = repeatedKeys.get(cKey);
          if (keyIndex===undefined){
            repeatedKeys.set(cKey,trArray.length);
            const row = new Array(cases.length+1);
            row[0] = objectKey;
            row[rep.casesIndex] = rep.el.value;
            trArray.push(row);
            stopCriterion = true;
          }
          else {
            if (!(trArray[keyIndex][rep.casesIndex]!=null)){ // if empty, then fill in with value
              trArray[keyIndex][rep.casesIndex] = rep.el.value;
              stopCriterion = true;
            }
            else {  // continue while loop if value in a batch is already filled in (slot in table is taken)
              stopCriterion = false;
            }
          }
          batchIndex+=1;
        }while(!stopCriterion);
      }while (repetitionsArray.length>0);
    }

    if (treatment){
      const treatmentDups = [];
      const lateralityValues = Array.from(new Set(trArray[1])).splice(1); // get only range of values and remove title
      for (let dup=2; dup < trArray.length; dup++){
        const msrId = trArray[dup][0]['m'];
        const msrName = getMeasurementName(msrId,fullState);
        const direction = checkTreatmentConditionByName(msrName,lateralityValues);
        if (direction){
          let treatRow = getTreatmentValues(trArray[dup], trArray[1],msrName,direction);
          if (!findMatchingTreatment(treatmentDups,treatRow)) // if matching is found, use it
            treatmentDups.push(treatRow);  // else add new row
        }
      }
      trArray.push(...treatmentDups);
    }

    const wideArray = new Array(cases.length+1);

    for (let i=0;i<wideArray.length;i++){
      let temp = new Array(trArray.length);
      for(let j=0; j<trArray.length;j++){
        temp[j] = trArray[j][i];
      }
      if (i>0)
        wideArray[i]= temp.join(separator);
      else
        wideArray[i]= temp;
    }

    const cName = (el)=>rMap.get(el.c)!==undefined
      ? rMap.get(el.c)
      :"";

    const wName = (el)=>(el.w!=null)?el.w:"";

    const headerRow = wideArray[0]
      .map((el,index)=>{
        return index===0
        ?"CASE_ID"
        : (el.hasOwnProperty("t")
            ? cName(el)+" "+el.t+" "+ wName(el) + ((el.b!=null)?el.b:"")
            : cName(el)+" "+getMeasurementName(el.m,fullState)+" "+ wName(el) + ((el.b!=null)?el.b:"")
          );
      });


    if (treatment)
      headerRow[1] = TREATMENT_NAME;

    wideArray.splice(0,1,headerRow.join(separator));

    return csv + wideArray.join('\n');
  }
  else
    return csv + "Empty dataset";
}


function generateRaterIdToNameMap(d){
  const r = getNestedProp(["rawData", "contributors"], d);
  return new Map(
    r.map(c => {
      if (c.type === "non-user")
        return [c.uuid, c.name];
      else
        return [c.uuid, c.label];
    }),
  );
}

function getMeasurement(id,d){
  const meas = getNestedProp(["rawData", "measurementConfigurations"], d);
  return meas.find(el=>el.id===id);
}


function getMeasurementName(id,d){
  const mc = getMeasurement(id,d);
  if (mc!=null)
    return getMeasurementShortLabel(mc,d.rawData);
  else return "";
}

/**
 * @param name - full name of variable
 * @param treatments -
 */
function checkTreatmentConditionByName(name,treatments){

 let direction = undefined;
 let hasTreatment =  treatments.some(element => {
      if (element === undefined || element === null)
        return false;
      if (name.toLowerCase().includes(element.toLowerCase())) {
        direction = element.toLowerCase();
        return true;
      }
      return false;
    });
  if (hasTreatment)
    return direction;
  return false;
}

function getTreatmentValues(array,treatments,name,direction){
  const dup = new Array(array.length);
  for (let i=1; i<array.length;i++){
    if (treatments[i]!=null && treatments[i].toLowerCase() === direction)
      dup[i] = array[i];
  }

  dup[0] = Object.assign({},array[0]);
  dup[0].t = name.replace(direction, "TREATMENT");
  return dup;
}

/**
 *  Find if there is already matching treatment for a given workflow, contributor and measurement, eg. whether
 *  is already created treatment row for LEFT direction if RIGHT direction is processed
 * @param tr
 * @param t
 */
function findMatchingTreatment(tr,t){
  let length = tr.length;

  for (let i = 0; i<length; i++){
     if (
       tr[i][0].c === t[0].c
       && tr[i][0].w === t[0].w
       && tr[i][0].t === t[0].t
     ) {
       for (let j = 1; j < t.length; j++) { // copy values
         if (t[j] != null)
           tr[i][j] = t[j];
       }
       return true
     }
  }
  return  false;
}