import React from "react";
import {Prompt, withRouter} from "react-router";
import {Button} from "primereact/components/button/Button";
import AnnotationToolPortal from "./AnnotationToolPortal";
import PropTypes from "prop-types";
import {
    annotationRadiusChangeKeys,
    fiducialKeyHandler,
    toggleAnnotations,
    togglePinToolShortcut,
    toggleProjections,
    toggleSegmentations, toggleSmoothingToolShortcut
} from "../../../vtk/SpineVTKHelper";
import {ContainerWidgetToolbar} from "../../containers/ContainerWidgetToolbar";
import {
    ANNOTATION_PROPERTY_NAME__ROI,
    ANNOTATION_TABLE_TOOL,
    MAIN_TOOL,
    MAIN_TOOL_PROPERTY_NAME_ANNOTATIONS_VISIBLE,
    MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE,
    MAIN_TOOL_PROPERTY_NAME_PROJECTIONS_VISIBLE,
    MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE,
    PIN_TOOL
} from "../../../../Constants";
import LayoutManager from "../../../root/component/LayoutManager";
import {ContainerImportExportToolbar} from "../../containers/ContainerImportExportToolbar";
import {ContainerCaseComponent} from "../../containers/ContainerCaseComponent";
import {MiniWorkflowStepMonitor} from "./MiniWorkflowStepMonitor";
import {ContainerTaskControlPanel, ContainerTaskControlPanelDemo} from "../../containers/ContainerTaskControlPanel";
import {ContainerAnnotationTable} from "../../containers/ContainerAnnotationTable";
import {TutorialVideo} from "../TutorialVideo";
import {getParameterFromUrl} from "../../../helpers/urls";
import ManualToolHelpPage from "./ManualToolHelpPage";
import {setTutorialVideoState} from "../../../dashboard/action/ManualTaskAction";
import {ContainerAnnotationForm} from "../../containers/ContainerAnnotationForm";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import { isEqual } from "lodash";
import {areAllViewersSmoothed, setAllViewersSmoothing} from "../../../vtk/SmoothingAllTool";
import ManualToolHelpPageImagePanel from "./ManualToolHelpPageImagePanel";
import {Dialog} from "primereact/components/dialog/Dialog";
import {getNestedProp} from "../../../helpers/expressions";

/**
 * Component to display manual tool.
 * Still under development.
 *
 * It uses Store for keeping:
 * - state of manual tool
 * - configuration of manual tool (definition of widgets)
 * - state of viewers
 * - state of images and their properties
 */
class ManualTool extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            videoVisible : props.match.path.toLowerCase().includes('playskill'),//if skills mode than show video on load
            helpVisible : props.match.path.toLowerCase().includes('playskill'),
            videoLink : null,  //videoLink set by ManualToolHelpPage
            showWindowPortal : false,
            imageDialogVisible : false,
            image : null,  // image from help panel
            imageArray:null,  // image list from help panel
            imageWindowWidth:600,
            imageWindowHeight:400,
        };
        ["viewCallback","dragStart","dragStop","keyListenerDown","keyListenerUp","getWidgetToolState","imagePanelMutationHandler"]
            .forEach(name => { this[name] = this[name].bind(this);});
    }

    componentDidUpdate(prevProps, prevState){
        const {predefinedToolConfiguration,initializePredefinedConfiguration,manualToolConfiguration}=this.props;
        if (predefinedToolConfiguration!=null && !isEqual(prevProps.predefinedToolConfiguration, predefinedToolConfiguration) && this.props.readOnly) {
            initializePredefinedConfiguration(predefinedToolConfiguration);
        }
        if (prevProps.manualToolConfiguration!==manualToolConfiguration){
            if (!(this.autosaving!=null) && getNestedProp(["mainTool","autosaving","interval"],this.props.manualToolConfiguration)!=null){
                this.autosaving =  setInterval(this.props.saveOutputs, 1000*getNestedProp(["mainTool","autosaving","interval"],this.props.manualToolConfiguration));
            }
        }
    }

    componentDidMount(){
        const {initializeTool, initializeTemporaryResults, predefinedToolConfiguration,initializePredefinedConfiguration,manualToolConfiguration}=this.props;
        window.onbeforeunload = function(event) { // this handler is used to prevent from reloading with accidental refresh
            // this.onUnload(); //this can be used to provide additional functionality
            return ""; //here message can be provided (by defaut is "Changes you made may not be saved.")
        }.bind(this);
        if (predefinedToolConfiguration!=null) {
            initializePredefinedConfiguration(predefinedToolConfiguration);
            this.setState({videoVisible:false,helpVisible:false})
        }
        else
            initializeTool();

        // Add temporary results
        initializeTemporaryResults();
        document.body.addEventListener("keydown", this.keyListenerDown, true);
        document.body.addEventListener("keyup", this.keyListenerUp, true);
        this.resizeObserver = new ResizeObserver( this.imagePanelMutationHandler);
        this.resizeObserver.observe(document.getElementById("image-view-window"));

        if (getNestedProp(["mainTool","autosaving","interval"],manualToolConfiguration)!=null) // if configuration is already loaded
            this.autosaving =  setInterval(this.props.saveOutputs, 30000);
    }
    imagePanelMutationHandler(mutationsList){
        mutationsList.forEach(mutation => {
            const titlebarHeight = document.getElementById( 'image-view-window' ).getElementsByTagName( 'div' )[0].getBoundingClientRect().height;
            const width = mutation.contentRect.width;
            const height = mutation.contentRect.height;
            if (!Number.isNaN(width) && !Number.isNaN(height))
                 this.setState({
                     imageWindowWidth:width,
                     imageWindowHeight:height - titlebarHeight
                 })
        })
    }

    componentWillUnmount(){
        const {clearAnnotatorState,clearViewersState,clearManualToolState,updateManualSubTask,updateScenes}=this.props;
        clearAnnotatorState();
        clearViewersState();
        clearManualToolState();
        updateManualSubTask(null);
        updateScenes({});

        document.body.removeEventListener("keydown", this.keyListenerDown, true);
        document.body.removeEventListener("keyup", this.keyListenerUp, true);

        this.resizeObserver.disconnect();

        if (this.autosaving!=null)
            clearInterval(this.autosaving);
    }

    /**
     * This method is intended to provide manual tool props for collaborative components (painter).
     * @param tool - widget {BRUSH_TOOL,ERASER_TOOL}
     * @param prop - property name {'size','labelmap'}
     * */
    getWidgetToolState(tool,prop){
        const {manualToolState} = this.props;
        if(manualToolState[tool]!=null)
             return manualToolState[tool][prop];
        else
             return null;
    }

    keyListenerDown(event) {
        const {activeViewerId} = this.props.manualToolState[MAIN_TOOL];
        const {handleKeySlicing, annotationsData, annotationsReadData, readOnly, updateManualToolProperty,activeROI,rmCallback,manualToolConfiguration,manualToolState, viewersState, updateViewerProperty,updateAnnotationData} = this.props;
        if (activeViewerId !=null) {
            handleKeySlicing(activeViewerId,event);
        }
        let data = annotationsData;
        if(readOnly){
            data = annotationsReadData;
        }

        fiducialKeyHandler(event, activeROI,  this.viewCallback, data,rmCallback,this.getWidgetToolState);
        toggleAnnotations(event,()=> updateManualToolProperty(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_ANNOTATIONS_VISIBLE , false));
        toggleSegmentations(
          event,
          viewersState,
          manualToolState[MAIN_TOOL][MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE],
          (value)=> updateManualToolProperty(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE ,value));
        toggleProjections(event,()=> updateManualToolProperty(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_PROJECTIONS_VISIBLE , true));
        if (manualToolConfiguration!=null && manualToolConfiguration[PIN_TOOL]!=null && manualToolConfiguration[PIN_TOOL]['controlEnabled'])
        togglePinToolShortcut(event, ()=> updateManualToolProperty(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE ,
          (manualToolState[MAIN_TOOL][MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE]===LEFT_BUTTON_MODE.PIN)?LEFT_BUTTON_MODE.NONE:LEFT_BUTTON_MODE.PIN));
        toggleSmoothingToolShortcut(event,()=> setAllViewersSmoothing(viewersState,updateViewerProperty,!areAllViewersSmoothed(viewersState)));
        annotationRadiusChangeKeys(event, ()=> {
            if (activeROI!=null){
                const roi = data.find(el=>el._id === activeROI);
                if ("CIRCLE" === getNestedProp([ANNOTATION_PROPERTY_NAME__ROI,"roiType"],roi) && roi[ANNOTATION_PROPERTY_NAME__ROI]["radius"]!=null){
                    if (event.key === "+")
                      roi[ANNOTATION_PROPERTY_NAME__ROI]["radius"]+=1;
                    else
                      roi[ANNOTATION_PROPERTY_NAME__ROI]["radius"]-=1;
                    updateAnnotationData(data);
                }
            }

        })
    }

    keyListenerUp(event) {
        const {updateManualToolProperty} = this.props;
        toggleAnnotations(event,()=> updateManualToolProperty(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_ANNOTATIONS_VISIBLE , true));
        toggleProjections(event,()=> updateManualToolProperty(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_PROJECTIONS_VISIBLE , false));
    }


    viewCallback(row){
        this.props.setActiveAnnotation(row,true);
    };

    dragStart(event){
        console.log("Dragging")
    }

    dragStop(event){
        console.log("Stopping")
    }


    render(){
        const {showWindowPortal,videoVisible,helpVisible}=this.state;
        const {manualToolState,manualToolConfiguration,readOnly,updateManualToolProperty, manualTask, match,subTask,messageQueue} =this.props;

        const navigationModeLabel = manualToolState[MAIN_TOOL]['navigationModeLabel'];

        const importExportPanel =  <ContainerImportExportToolbar
            showWindowPortal={this.state.showWindowPortal}
            onChangeWindowPortal={(st)=>this.setState({showWindowPortal:st})}
            materializedTaskId = {manualTask!=null
            && manualTask.miniWorkflow!=null
            && manualTask.miniWorkflow.currentMaterializedTask !=null?manualTask.miniWorkflow.currentMaterializedTask.uuid:null}
        />;

        const widgetPanel = <ContainerWidgetToolbar/>;

        const viewersPanel = <LayoutManager
            toolComponentProps={this.props}
            toolComponentState={this.state}
            importExportPanel={importExportPanel}
            widgetPanel={widgetPanel}
        />;
        const steps = (manualTask!=null
            && manualTask.miniWorkflow!=null
            && manualTask.miniWorkflow.miniWorkflowPath!=null
            && Array.isArray(manualTask.miniWorkflow.miniWorkflowPath)!=null
        )? manualTask.miniWorkflow.miniWorkflowPath.map(el => {
            return {name: el.stepName}
        }):null;
        const currentStep = (manualTask!=null
            && manualTask.miniWorkflow!=null
            && manualTask.miniWorkflow.currentStep!=null
        )   ?manualTask.miniWorkflow.currentStep
            :null;

        const tutorialVid = (manualTask !=null
            && manualTask.miniWorkflow !=null
            && (
                (manualTask.miniWorkflow.currentTutorial!=null  && manualTask.miniWorkflow.currentTutorial.video !=null ) || (manualTask.miniWorkflow.currentHelpPanel!=null || this.state.videoLink!=null )
            ))
            ?<TutorialVideo
            visible={videoVisible}
            videoId={getParameterFromUrl((this.state.videoLink!=null)? this.state.videoLink:(manualTask.miniWorkflow.currentTutorial!=null)
                ?manualTask.miniWorkflow.currentTutorial.video
                :null,'v')}
            onEnd={() => {
                this.setState({videoVisible: false});
                if (!manualTask.miniWorkflow.currentTutorial.video.seen)
                    setTutorialVideoState(manualTask.miniWorkflow.currentMaterializedTask.uuid);
            }}/>:null;

        const manHelp = (manualTask !=null && manualTask.miniWorkflow !=null && (manualTask.miniWorkflow.currentTutorial != null || manualTask.miniWorkflow.currentHelpPanel != null))?
            <ManualToolHelpPage  visible={helpVisible && !videoVisible}
                                 onHide={() => this.setState({helpVisible:false})}
                                 onVideoLinkClick={(videoLink)=>this.setState({videoVisible:true,videoLink:videoLink})}
                                 step={manualTask.miniWorkflow.currentStep}
                                 helpUrl={(manualTask.miniWorkflow.currentTutorial!=null)?manualTask.miniWorkflow.currentTutorial.panel:null}
                                 helpPanel={manualTask.miniWorkflow.currentHelpPanel}
                                 subTask={subTask}
                                 setImageVisible={(image,imageArray)=>{this.setState({image:image, imageDialogVisible:true,imageArray:imageArray})}}
            />
            :null;

        let tableStyle = {position:"absolute",bottom:"0px",zIndex:"1004", backgroundColor:"#e8e8e8"};

        if(readOnly){
            tableStyle = {...tableStyle, width:"auto"};
        }

        return (
            <div onDragEnd={(e)=>this.dragStop(e)} id="manualToolContainer" className="ui-unselectable-text">
                {/*<Growl ref={(el) => { this.growl = el; }}/>*/}
                <Prompt
                    when={true}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                {steps != null && currentStep !=null &&
                <div id="caseMonitors" style={{display: "block", position: "fixed", top: "10px", left: "auto", zIndex: "1000"}}>
                    {(manualToolConfiguration['casesControl']!=null && !manualToolConfiguration['casesControl']['controlVisible'])
                      ?<React.Fragment/>
                      :<ContainerCaseComponent/>}
                    <MiniWorkflowStepMonitor
                      steps={steps}
                      currentStep={currentStep}
                      subStep={subTask}
                      lock = {navigationModeLabel!=="case"?Number(navigationModeLabel.substring(5))-1:null}
                    />
                </div>
                }
                {showWindowPortal && !videoVisible &&(
                    <AnnotationToolPortal
                        closeWindowPortal={() => this.setState({ showWindowPortal: false })}
                        keyListenerDown={this.keyListenerDown}
                        keyListenerUp={this.keyListenerUp}
                    >
                        <Button onClick={() => this.setState({ showWindowPortal: false })} label = "Close me !"/>
                        {viewersPanel}
                    </AnnotationToolPortal>)}
                {!showWindowPortal && !videoVisible && viewersPanel}

                {tutorialVid}
                {manHelp}
                <Dialog onHide={() => {  this.setState({image: null,imageDialogVisible:false}); }}
                        visible={this.state.imageDialogVisible}
                        header={(this.state.image != null) ? this.state.image.name : "Image preview"}
                        contentStyle={
                            {
                                width: this.state.imageWindowWidth+"px",
                                height: this.state.imageWindowHeight+"px",
                                padding:"0"
                            }
                        }
                        id={"image-view-window"}
                >
                    {this.state.image != null &&
                    <ManualToolHelpPageImagePanel
                      width={this.state.imageWindowWidth}
                      height={this.state.imageWindowHeight}
                      src={this.state.image.src}
                      alt={this.state.image.name}
                      onPrevious={()=>{
                        const ind = this.state.imageArray.findIndex(el=>el===this.state.image);
                        this.setState({
                          image: ind!=null && ind > 0? this.state.imageArray[ind-1] : this.state.imageArray[0]
                      })}}
                      onNext={()=>{
                          const ind = this.state.imageArray.findIndex(el=>el===this.state.image);
                          this.setState({
                              image: ind!=null && ind < (this.state.imageArray.length -1)? this.state.imageArray[ind+1] : this.state.imageArray[this.state.imageArray.length-1]
                          })}}
                    /> }
                </Dialog>

                {steps!=null && match.path.includes("demo") &&
                <ContainerTaskControlPanelDemo callbackOnGoToNext={(message) => messageQueue.show(
                    {
                        sticky: false,
                        severity: 'info',
                        summary: 'Info',
                        detail:message
                    }
                )}
                    onHelpButtonClick={()=>{
                        if (videoVisible){
                            this.setState({videoVisible:!videoVisible});
                        }
                        this.setState({helpVisible:!helpVisible})}}
                    onVideoButtonClick={()=>this.setState({videoVisible:!videoVisible})}
                />}
                {steps!=null && !match.path.includes("demo") &&
                <ContainerTaskControlPanel
                    videoButtonOn={videoVisible}
                    helpButtonOn={helpVisible}
                    onHelpButtonClick={()=>{
                        if (videoVisible){
                            this.setState({videoVisible:!videoVisible});
                        }
                        this.setState({helpVisible:!helpVisible})
                    }
                    }
                    onVideoButtonClick={()=>this.setState({videoVisible:!videoVisible})}
                />
                }


                {manualToolConfiguration!=null
                  && manualToolConfiguration[ANNOTATION_TABLE_TOOL]!=null
                  && manualToolConfiguration[ANNOTATION_TABLE_TOOL]['controlVisible']
                  && !videoVisible &&
                <div className="ui-g-12" style={tableStyle}>
                    <ContainerAnnotationTable activeRowOnly={true} />
                    <ContainerAnnotationForm/>
                    <i className="fa fa-bars"  style={{width:"100%",textAlign:"center"}}
                       onClick={()=>updateManualToolProperty(ANNOTATION_TABLE_TOOL,'fullTable', !manualToolState[ANNOTATION_TABLE_TOOL]['fullTable'])}
                       onDragStart={(e)=>this.dragStart(e)}
                       onDrop={(e)=>this.dragStop(e)}
                    />
                    { manualToolState!=null
                    && manualToolState[ANNOTATION_TABLE_TOOL]!=null
                    && manualToolState[ANNOTATION_TABLE_TOOL]['fullTable']
                    && <ContainerAnnotationTable activeRowOnly={false}/>
                    }
                </div>}
            </div>
        )
    }

}
export default withRouter(ManualTool);

ManualTool.defaultProps={
    initialSetIndex:0
};

ManualTool.propTypes = {
    columns: PropTypes.array.isRequired,
    manualToolState: PropTypes.object.isRequired,
    manualToolConfiguration: PropTypes.object.isRequired,
    updateManualToolProperty: PropTypes.func.isRequired,
    annotationsData: PropTypes.array.isRequired,
    annotationsReadData: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    activeROI: PropTypes.string.isRequired,
    getAnnotations: PropTypes.func.isRequired,
    updateAnnotationData:PropTypes.func.isRequired,
    rmCallback:PropTypes.func.isRequired,
    pickerCallback:PropTypes.func.isRequired,
    saveOverlayData:PropTypes.func,
    addAnnotation : PropTypes.func.isRequired,
    setActiveAnnotation:PropTypes.func.isRequired,
    setSubAnnotation:PropTypes.func.isRequired,
    addSubAnnotationForActiveAnnotation:PropTypes.func.isRequired,
    clearAnnotatorState:PropTypes.func.isRequired,
    viewersState:PropTypes.object.isRequired,  //array containing state of all viewers
    formsState:PropTypes.object.isRequired,  //array containing state of all viewers
    updateViewerProperty:PropTypes.func.isRequired,
    updateFormProperty:PropTypes.func.isRequired,
    updateViewer:PropTypes.func,
    imageOptions:PropTypes.array.isRequired,
    changeImageType:PropTypes.func.isRequired,
    images:PropTypes.array.isRequired,
    overlays:PropTypes.array.isRequired,
    changeOrientation:PropTypes.func.isRequired,
    handleKeySlicing:PropTypes.func.isRequired,
    clearViewersState:PropTypes.func.isRequired,
    clearManualToolState:PropTypes.func.isRequired,
    initialSetIndex:PropTypes.number.isRequired,  //optional parameter to choose between different initial settings of viewer configs
    predefinedToolConfiguration:PropTypes.object, //optional configuration provided programmatically in call (configuration of viewers and widgets)
    initializePredefinedConfiguration:PropTypes.func, //load images and use passed configuration
    getAnotherCase:PropTypes.func,
    updateManualSubTask:PropTypes.func.isRequired,
    subTask:PropTypes.object,
    messageQueue: PropTypes.object.isRequired,
    updateScenes:PropTypes.func.isRequired,
    initializeTemporaryResults:PropTypes.func.isRequired,
    saveOutputs:PropTypes.func.isRequired  // used for autosaving
};
