import {HOST_URL} from "../../../Constants";
import {
    SPM_FAIL, SPM_VIEW_SUCCESS, SPM_VIEW_REQUEST, CLEAR_SPM_STATE
} from "./actionType";
import {
    MANUAL_TASK_GET_REQUEST
} from "../../dashboard/action/actionType";
import store from '../.././Store';
import axios from "axios/index";

import {
  requestLUT,
  requestLUTDescription,
  successLUT,
  successLUTDescription
} from "../../visualization/action/LUTAction";
import {getNestedProp} from "../../helpers/expressions";
import {ORDINAL24, ordinalColors} from "../../helpers/colors";
import {updateManualToolSAM} from "../../analysis/action/toolAction";
import {generateColorTransferFunctionFromPredefinedVTKLookupTable} from "../../vtk/SpineVTKHelper.js";

async function getVTStub(toolTemplatePath){
  let response = await fetch(toolTemplatePath,{cache: "reload"});
  const data = await response.json();
  return await data;
}

//****************************************************************************
//
//                                Actions
//
//****************************************************************************


// Presentation actions *************************************************************

const request = () => ({
    type: SPM_VIEW_REQUEST
});

const errorAC = (err) => ({
    type: SPM_FAIL,
    error: err
});

const success = (result) => ({
    type: SPM_VIEW_SUCCESS,
    result: result
});

const requestManualTask = () => ({
    type: MANUAL_TASK_GET_REQUEST
});

const clearSingleResultState = () => ({
    type: CLEAR_SPM_STATE,
});


// This is an action creator
export const clearSingleResultStateAC = ()=>{
    return (dispatch) => {
        dispatch(clearSingleResultState());
    }
};

// This is an action creator
export const getSPMResults = (spmExperimentId, pval) => {
    console.log("getting single result view")

    return async (dispatch, getState) => {
        dispatch(request())
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        axios.get(HOST_URL + '/api/run-spm-test/'+spmExperimentId+"/contrast/1/result?pval="+pval.toString())
            .then(async (response) => {
                console.log('spm review :: response ::', response)
                if (response.status !== 200) {
                  dispatch(errorAC(response.data))
                } else {
                  // Create workfload for manual tool
                  let mtc = await getVTStub('/dist/jsonDocs/tools/visualizationToolStubEmpty3D.json');

                  const roi = response.data.inputsForReview.roi;
                  const imgSegId = response.data.inputsForReview.spm_uncorrected_pval;

                  // Finish tool definition
                  // Inputs
                  mtc.miniWorkflow.currentTool.inputs["input_" + response.data.inputsForReview.image] = {
                    "name": "Input image",
                    "description": "Input image",
                    "isList": false,
                    "type": "imageEntityInOut",
                    "imageEntityInOut_Type": "ANATOMICAL",
                    "imageEntityInOut_FileFormat": "nii.gz",
                    "required": true
                  };

                  // mtc.miniWorkflow.currentTool.inputs["overlay_"+imgSegId] = {
                  //   "name": "pval image as overlay",
                  //   "description": "pval image as overlay",
                  //   "isList": false,
                  //   "type": "roiInOut",
                  //   "typeROI": "EXPLICIT",
                  //   "imageEntityInOut_FileFormat": "nii.gz",
                  //   "required": true
                  // };

                  // if we have thresholded t statistic
                  if(!response.data.inputsForReview.spm_thresholded_missing) {
                    mtc.miniWorkflow.currentTool.inputs["overlay_2"] = {
                      "name": "t statistic thresholded",
                      "description": "t statistic thresholded",
                      "isList": false,
                      "type": "roiInOut",
                      "typeROI": "EXPLICIT",
                      "imageEntityInOut_FileFormat": "nii.gz",
                      "required": true
                    };
                  }

                  // if we have thalamus nuclei
                  if(response.data.inputsForReview.hasOwnProperty("thalamus_nuclei")) {
                    mtc.miniWorkflow.currentTool.inputs["thalamus_nuclei"] = {
                      "name": "thalamus nuclei",
                      "description": "thalamus nuclei",
                      "isList": false,
                      "type": "roiInOut",
                      "typeROI": "EXPLICIT",
                      "imageEntityInOut_FileFormat": "nii.gz",
                      "required": true
                    };

                    mtc.miniWorkflow.currentTool.inputs["thalamus_nuclei_lutDescriptionKey"] = { // d7fa6e9be912a9112d0a8b8d30001e21
                        "name": "Input description of LUT data",
                        "description": "Input LUT",
                        "isList": false,
                        "type": "lookUpTableDescription",
                        "required": true
                    }

                    mtc.miniWorkflow.currentTool.inputs["thalamus_nuclei_lutKey"] = { // d7fa6e9be912a9112d0a8b8d30001a41
                        "name": "Input LUT data",
                        "description": "Input LUT data for overlay displaying.",
                        "isList": false,
                        "type": "lookUpTable",
                        "required": true
                    }
                  }




                  // Scene 1
                  // Primary image
                  mtc.miniWorkflow.currentTool.configuration.scenes["sceneKey1"].primaryImageInputKey = "input_" + response.data.inputsForReview.image;
                  // Primary image Overlay
                  // mtc.miniWorkflow.currentTool.configuration.rois.overlays["overlay"] = {
                  //   "fromInputs": true,
                  //   "imageInputKey": "overlay_" + imgSegId,
                  //   "lutKey": "lut_map",
                  //   "lutDescriptionKey": "lutDescription_map",
                  //   "label": "uncorrected p-value",
                  // };




                  mtc.miniWorkflow.currentTool.configuration.luts["lut_map"] = {
                    "fromInputs": false,
                    "type": "none"
                  };
                  mtc.miniWorkflow.currentTool.configuration.lutDescriptions["lutDescription_map"] = {
                    "fromInputs": false,
                    "type": "none"
                  };

                  //mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlay");

                  
                  //mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlay_thr")


                  // Materialized task inputs
                  mtc.miniWorkflow.currentMaterializedTask.inputs["input_" + response.data.inputsForReview.image] = {
                    "value":response.data.inputsForReview.image,
                    "label": "brain image"
                  };
                  // mtc.miniWorkflow.currentMaterializedTask.inputs["overlay_" + imgSegId] = {
                  //   "value":  imgSegId,
                  //   "label": "uncorrected pval",
                  // };

                  // if we have thresholded t statistic
                  if(!response.data.inputsForReview.spm_thresholded_missing) {
                    mtc.miniWorkflow.currentTool.configuration.rois.overlays["overlay2"] = {
                      "fromInputs": true,
                      "imageInputKey": "overlay_2",
                      "lutKey": "lut_map",
                      "lutDescriptionKey": "lutDescription_map",
                      "label": "thresholded t statistic",
                    };
                    mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlay2");
                    mtc.miniWorkflow.currentMaterializedTask.inputs["overlay_2"] = {
                      "value":  response.data.inputsForReview.spm_thresholded,
                      "label": "thresholded t statistic",
                    };
                  }

                  // if we have thalamus_nuclei
                  if(response.data.inputsForReview.hasOwnProperty("thalamus_nuclei")) {
                    mtc.miniWorkflow.currentTool.configuration.rois.overlays["thalamus_nuclei"] = {
                      "fromInputs": true,
                      "imageInputKey": "thalamus_nuclei",
                      "lutKey": "thalamus_nuclei_lutKey",
                      "lutDescriptionKey": "thalamus_nuclei_lutDescriptionKey",
                      "label": "thalamus_nuclei",
                    };

                    mtc.miniWorkflow.currentTool.configuration.luts["thalamus_nuclei_lutKey"] = {
                      fromInputs: true,
                      lutInputKey: "thalamus_nuclei_lutKey"
                    };
                    mtc.miniWorkflow.currentTool.configuration.lutDescriptions["thalamus_nuclei_lutDescriptionKey"] = {
                      fromInputs: true,
                      lutDescriptionInputKey: "thalamus_nuclei_lutDescriptionKey"
                    };

                    mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("thalamus_nuclei");
                    mtc.miniWorkflow.currentMaterializedTask.inputs["thalamus_nuclei"] = {
                      "value":  response.data.inputsForReview.thalamus_nuclei,
                      "label": "thalamus nuclei",
                    };


                    mtc.miniWorkflow.currentMaterializedTask.inputs["thalamus_nuclei_lutKey"] = {
                      "value":  response.data.inputsForReview.thalamus_nuclei_lutKey,
                    };
                    mtc.miniWorkflow.currentMaterializedTask.inputs["thalamus_nuclei_lutDescriptionKey"] = {
                      "value":  response.data.inputsForReview.thalamus_nuclei_lutDescriptionKey,
                    };

                  }

                  if(response.data.inputsForReview.hasOwnProperty("fmaps")) {
                    for (let i=0;i<response.data.inputsForReview.fmaps.length;i++) {
                      const label = response.data.inputsForReview.fmaps[i].label;
                      const uuid = response.data.inputsForReview.fmaps[i].uuid;
                      mtc.miniWorkflow.currentTool.inputs["fmap"+i.toString()] = {
                        "name": label,
                        "description": label,
                        "isList": false,
                        "type": "roiInOut",
                        "typeROI": "EXPLICIT",
                        "imageEntityInOut_FileFormat": "nii.gz",
                        "required": true
                      };
                      mtc.miniWorkflow.currentTool.configuration.rois.overlays["overlayfmap"+i.toString()] = {
                        "fromInputs": true,
                        "imageInputKey": "fmap"+i.toString(),
                        "lutKey": "lut_map",
                        "lutDescriptionKey": "lutDescription_map",
                        "label": label,
                      };

                      mtc.miniWorkflow.currentMaterializedTask.inputs["fmap"+i.toString()] = {
                        "value":  uuid,
                        "label": label,
                      };

                      mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlayfmap"+i.toString());
                    }
                  }

                  // Switch orientation control on
                  mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[0].displayControls.orientation.controlVisible = true;
                  mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[0].displayControls.orientation.controlEnabled = true;
                  mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[1].displayControls.orientation.controlVisible = true;
                  mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[1].displayControls.orientation.controlEnabled = true;
                  mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[2].displayControls.orientation.controlVisible = true;
                  mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[2].displayControls.orientation.controlEnabled = true;

                  // linking tool
                  mtc.miniWorkflow.currentTool.configuration.widgets.linkAllTool =
                  {
                    controlEnabled: true,
                    controlVisible: true,
                    defaultValue: false
                  }

                  mtc.miniWorkflow.currentTool.configuration.widgets.echoTool = {
                    controlVisible: true,
                    controlEnabled: true,
                    defaultValue: false
                  }


                  // Old code for second scene
                  // mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[0].displayScenes.possibleScenesToDisplay.push("sceneKey2")
                  // mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[0].displayScenes.defaultSceneToDisplay = "sceneKey2"
                  // mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[1].displayScenes.possibleScenesToDisplay.push("sceneKey2")
                  // mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[2].displayScenes.possibleScenesToDisplay.push("sceneKey2")
                  // mtc.miniWorkflow.currentTool.configuration.viewers.renderWindows.[3].displayScenes.possibleScenesToDisplay.push("sceneKey2")

                  // Old code to display spm maps as rois
                  // const colorMapName = "heated_object"
                  // const colorMap = generateColorTransferFunctionFromPredefinedVTKLookupTable(colorMapName)
                  // let table = [];
                  // const size = 255;
                  // colorMap.getTable(0,255,size,table)
                  // console.log(table)
                  // const threshold = 0;
                  // const maxValue = 50;
                  // const step = (maxValue-threshold)/(size-1)
                  //
                  // let lut = [];
                  // let lutDescription = [];
                  //
                  // if (getNestedProp(["properties", "explicit", "labelMap"], roi) != null) {
                  //   const intensity = getNestedProp(["properties", "explicit", "labelMap", "labelIntensity"], roi) != null
                  //     ? getNestedProp(["properties", "explicit", "labelMap", "labelIntensity"], roi)
                  //     : 1;
                  //
                  //
                  // for (let i=1;i<=size;i++) {
                  //
                  //     const color = '#'+
                  //     (Math.round(table[(i-1)*3]*255)).toString(16).padStart(2, '0')+
                  //     (Math.round(table[(i-1)*3+1]*255)).toString(16).padStart(2, '0')+
                  //     (Math.round(table[(i-1)*3+2]*255)).toString(16).padStart(2, '0');
                  //
                  //     lut.push({
                  //       "value": (threshold + step*(i-1)),
                  //       "color": color
                  //     })
                  //
                  //     lutDescription.push({
                  //       "value": (threshold + step*(i-1)),
                  //       "label": i.toString()
                  //     })
                  //
                  //   }

                    // lut.push({
                    //   "value": 65,
                    //   "color": ordinalColors(ORDINAL24)[13]
                    // })
                    // lut.push({
                    //   "value": 14,
                    //   "color": ordinalColors(ORDINAL24)[14]
                    // })
                    //
                    // lutDescription.push({
                    //   "value": 65,
                    //   "label": "65"
                    // })
                    // lutDescription.push({
                    //   "value": 14,
                    //   "label": "14"
                    // })



                    //console.log(lut)


                    // dispatch(requestLUT("lut_" + segKey));
                    // dispatch(successLUT("lut_" + segKey, lut));
                    //
                    // dispatch(requestLUTDescription("lutDescription_" + segKey));
                    // dispatch(successLUTDescription("lutDescription_" + segKey, lutDescription));
                    //
                    //
                    // // Although the lookup tables are not from inputs, mark them as they are - to avoid clearing in ManualTool::initializeNonInputs
                    // mtc.miniWorkflow.currentTool.configuration.luts["lut_"+segKey] = {fromInputs: true, lutInputKey:"lut_"+segKey};
                    // mtc.miniWorkflow.currentTool.configuration.lutDescriptions["lutDescription_"+segKey] = {fromInputs: true, lutDescriptionInputKey:"lutDescription_"+segKey};
                    // // mtc.miniWorkflow.currentTool.inputs["input_"+segKey] = {
                    //   "name": "Segmentation",
                    //   "description": "Segmentation",
                    //   "isList": false,
                    //   "type": "roiInOut",
                    //   "typeROI": "EXPLICIT",
                    //   "required": true,
                    //   "imageEntityInOut_FileFormat": "nii.gz"
                    // };
                    // mtc.miniWorkflow.currentTool.configuration.rois.overlays["overlay_"+segKey] = {
                    //   "fromInputs": true,
                    //   "imageInputKey": "input_"+segKey,
                    //   "lutKey": "lut_"+segKey,
                    //   "lutDescriptionKey": "lutDescription_"+segKey,
                    //   "label": "Region of interest "
                    // };
                    // mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlay_"+segKey);
                    // mtc.miniWorkflow.currentMaterializedTask.inputs["input_"+segKey] = {
                    //   "value": getNestedProp(["properties","explicit","labelMap","imageEntityId"],roi),
                    //   "format": "nii.gz"
                    // };


                  console.log(mtc)
                  dispatch(updateManualToolSAM(mtc));
                  dispatch(success(mtc))
                }
            }).catch(error => {
              dispatch(errorAC(error))
              console.log('spm result view error:', error);
        });
    }
};
