import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {matchPath} from "react-router-dom";
import { Trans } from 'react-i18next';
import {getNestedProp} from "../helpers/expressions";

/**
 * Render name of Project in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given project (projectId)
 * @param appState - Redux store
 * @returns {*}
 * @constructor
 */
const ProjectBreadcrumb = ({match, appState}) => {
 let project = appState.project.projects.projects.find((el)=>el.uuid === match.params.projectId);
return  <React.Fragment>{project ? <span>{project.name}</span>:<span></span>}</React.Fragment>; // use match param userId to fetch/display user name
};

/**
 * Render name of Experiment in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given exp (experimentId)
 * @param appState
 * @returns {*}
 * @constructor
 */
const ExperimentBreadcrumb = ({match, appState}) => {
    let experiment = appState.project.experiments.experiments.find((el)=>el.uuid === match.params.experimentId);
    if(!experiment)
        experiment =  appState.expDesign.experiment;
    return  <span>{experiment.question}</span>; // use match param userId to fetch/display user name

};

/**
 * Render name of Demo in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given exp (experimentId)
 * @param location - React-Router variable providing property set in DemosPage where link is created
 * @returns {*}
 * @constructor
 */
const DemoBreadcrumb = ({match,location,register}) => {
    if(location.demoProps){
        return  location.demoProps;
    } else {
        const demoName = register[match.params.demoId].title;
        return <span>{demoName}</span>;
    }
};

/**
 * Render name of workflow in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given exp (id)
 * @param appState
 * @returns {*}
 * @constructor
 */
const WorkflowBreadcrumb = ({match,appState}) => {
    let wf = appState.expDesign.workflows.workflows.find(el=>el._id===match.params.id);
    return  <span>{wf!=null?wf.name:""}</span>;
};


/**
 * Render name of Annotation element in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given exp (id)
 * @returns {*}
 * @constructor
 */
const AnnotationBreadcrumb = ({match}) => {
    return  <span>{match.params.type.charAt(0).toUpperCase() + match.params.type.slice(1)}</span>;
};

/**
 * Render name of Analysis element in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given exp (id)
 * @returns {*}
 * @constructor
 */
const AnalysisBreadcrumb = ({match,location}) => {
    return  <span>{location.pathname.includes("snapshot")?"Predefined Analysis":"Analysis"}</span>;
};

/**
 * Render literal with title
 * @param match
 * @returns {*}
 * @constructor
 */
const DashboardBreadcrumb = ({match}) =>
    <span><Trans>dashboard.label</Trans></span>;


/**
 * Render name of cohort in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given cohort (cohortId)
 * @param appState - Redux store
 * @returns {*}
 * @constructor
 */
const CohortBreadcrumb = ({match, appState}) => {
    let studyName = getNestedProp(["visu","study","study","name"],appState); // TODO
    return  <React.Fragment>{studyName!=null?studyName:""}</React.Fragment>; // use match param userId to fetch/display user name
};

const routes = [
    {path: '/home', breadcrumb: DashboardBreadcrumb},
    {path: 'users/:userId', breadcrumb: <span>Projects</span>},
    {path: '/project', breadcrumb: <span>Projects</span>},
    {path: '/project/:projectId', breadcrumb: ProjectBreadcrumb},
    {path: '/project/:projectId/expdesign', breadcrumb: <span>New Experiment</span>},
    {path: '/project/:projectId/experiment/:experimentId', breadcrumb: ExperimentBreadcrumb},
    {path: '/project/:projectId/experiment/:experimentId/analysis', breadcrumb: AnalysisBreadcrumb},
    {path: '/project/:projectId/experiment/:experimentId/spineqmonitor', breadcrumb: <span>SpineQ Progress Monitor</span>},
    {path: '/news', breadcrumb:  <span>News</span>},
    {path: '/demo', breadcrumb:  <span>Demos</span>},
    {path: '/event', breadcrumb:  <span><Trans>events.header</Trans></span>},
    {path: '/livePresenter', breadcrumb:  <span>Presentations</span>},
    {path: '/workflowManager', breadcrumb:  <span>Workflows</span>},
    {path: '/workflowManager/:id', breadcrumb:  WorkflowBreadcrumb},
    {path: '/annotationsBuilder', breadcrumb:<span>Annotations</span>},
    {path: '/annotationsBuilder/:type', breadcrumb:AnnotationBreadcrumb},
    {path: '/singleResultReview', breadcrumb:<span>Workflow Executor</span>},
    {path: '/users', breadcrumb:<span>Users</span>},
    {path: '/cohorts', breadcrumb:<span>Cohorts</span>},
    {path: '/cohorts/:cohortId', breadcrumb:CohortBreadcrumb},
    {path: '/helpEditor', breadcrumb:<span>Help editor</span>},
    {path: '/demo/:demoId', breadcrumb:  DemoBreadcrumb}
];

const renderer = ({breadcrumb, match,appState,location,register}) => {
    if (typeof breadcrumb === 'function') {
        return breadcrumb({match,appState,location,register});
    }
    return breadcrumb;
};

export const getBreadcrumbs = ({routes, pathname, appState,location,register}) => {
    const matches = [];

    pathname
        .replace(/\/$/, '')
        .split('/')
        .reduce((previous, current) => {
            const pathSection = `${previous}/${current}`;

            let breadcrumbMatch;

            routes.some(({breadcrumb, path}) => {
                const match = matchPath(pathSection, {exact: true, path});

                if (match) {
                    breadcrumbMatch = {
                        breadcrumb: renderer({breadcrumb, match,appState,location,register}),
                        path,
                        match,
                    };
                    return true;
                }

                return false;
            });

            if (breadcrumbMatch) {
                matches.push(breadcrumbMatch);
            }

            return pathSection;
        });

    return matches;
};
const Breadcrumbs = ({breadcrumbs}) => {
    const lastEl = breadcrumbs.length - 1;
    return (
        <div>
            {breadcrumbs.map(({breadcrumb, path, match}, index) => (
                <span key={path}>
                     {lastEl !== index &&
                     <NavLink to={match.url}>
                         {breadcrumb}
                     </NavLink>
                     }
                    {lastEl === index &&
                        breadcrumb
                    }
                    {lastEl !== index &&
                    <span> &gt; </span>}
      </span>
            ))}
        </div>
    );
};

/**
 * "Dumb" component responsible for displaying breadcrumb.
 * Uses Breadcrumbs function and requires array of routes to be matched with current location.
 * Each route can use its own renderer for Link.
 * @param location - React Router location object (required)
 * @returns {*} - render function
 */
export const Breadcrumb = ({location, appState, register}) => {

    let result = getBreadcrumbs({
        pathname: location.pathname,
        routes,
        appState,
        location,
        register
    });

    return (
        <div style={{fontSize: '16px', color: '#fff', marginTop: '20px', marginLeft: '25px', display: 'inline-block'}}>
            <Breadcrumbs breadcrumbs={result}/>
        </div>
    )
};

Breadcrumb.propTypes = {
    location: PropTypes.object.isRequired
};