import React from 'react'
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from 'primereact/components/button/Button';
import {RESULTS_DOWNLOAD_REQUEST} from '../action/actionType';

class Results extends React.Component {

  constructor(){
    super();
    this.renderExperimentsResults = this.renderExperimentsResults.bind(this);
  }

  renderExperimentsResults(experiments) {
    const {downloadResultsStatus} = this.props;
    return experiments.map((experiment, key)=>{
      const {experimentTitle, type,
             workflowId, miniWorkflowSetId, experimentId, miniWorkflowKey,
             inputAnnotationTableDefinition_key1, inputAnnotationFormDefinition_key1} = experiment;
      const agreementUrl = `/home/agreement/${workflowId}/${miniWorkflowSetId}/${experimentId}/${miniWorkflowKey}/${inputAnnotationTableDefinition_key1}/${inputAnnotationFormDefinition_key1}`
      return <div className='spineRowStyle'>
      <div className="spineColStyle col4" >{experimentTitle}</div>
      <div className="spineColStyle col4" >{type}</div>
      <div className="spineColStyle col4">
        <Link to={agreementUrl}>
          <span style={{ textDecorationLine: "underline"}}>See results</span>
        </Link>
      </div>
      <div className="spineColStyle col4">
        {downloadResultsStatus[agreementUrl] === RESULTS_DOWNLOAD_REQUEST?
        <div> <i className="fa fa-spinner fa-spin"/></div>
        :
        <Button onClick={()=>{
            this.props.onDownloadResults(
              experimentId, workflowId, miniWorkflowKey, miniWorkflowSetId, agreementUrl,
              ({message})=>{
                this.props.messageQueue.show({
                  sticky: true,
                  severity: 'error',
                  summary: 'Error',
                  detail: message
                });}
            );
          }}>
          <span>Download results</span>
        </Button>
        }
      </div>

    </div>
    });
  };

  render() {
    // :workflowId/:miniWorkflowSetId/:experimentId/:miniWorkflowKey/:inputAnnotationTableDefinition_key1/:inputAnnotationFormDefinition_key1
    const experiments = [
      { // /home/agreement/2e9bb71ded349acfed021503bb009b50/12914275-28af-4c15-b217-59b7225dac91/ef8c47475fb0bb40ba9deb55c704c558/mw1/7e0c58ae948decbb445f6c495e0042b6/b7fc97eb044d96113b87f38b2d000d93
        experimentTitle: 'Leptomeningeal metastases',
        type: 'Agreement analysis',
        workflowId: '2e9bb71ded349acfed021503bb009b50',
        miniWorkflowSetId: '12914275-28af-4c15-b217-59b7225dac91',
        experimentId: 'ef8c47475fb0bb40ba9deb55c704c558',
        miniWorkflowKey: 'mw1',
        inputAnnotationTableDefinition_key1: '7e0c58ae948decbb445f6c495e0042b6',
        inputAnnotationFormDefinition_key1: 'b7fc97eb044d96113b87f38b2d000d93'
      },
      { // /home/agreement/1f3f4e99-8dda-4d3c-a4d1-6688e4ef15b3/8cad6e00-a463-43aa-974e-77b58a2f97b6/60dfc658-440a-4b01-8a9a-5850238d187a/mw1/c5b526b510dceac69b15f39c7301671e/dba09720e56c87239aefa823e50072eb
        experimentTitle: 'ENE identification/characterization',
        type: 'Agreement analysis',
        workflowId: '1f3f4e99-8dda-4d3c-a4d1-6688e4ef15b3',
        miniWorkflowSetId: '8cad6e00-a463-43aa-974e-77b58a2f97b6',
        experimentId: '60dfc658-440a-4b01-8a9a-5850238d187a',
        miniWorkflowKey: 'mw1',
        inputAnnotationTableDefinition_key1: 'c5b526b510dceac69b15f39c7301671e',
        inputAnnotationFormDefinition_key1: 'dba09720e56c87239aefa823e50072eb'
      },
      { // /home/agreement/8e50a264-7218-4d16-a061-6c6a2e044fb5/a7dddfa0ef38c426622d211df400d3a3/ab9e89fe9b4dcc0c918b498d2b02c623/mw1/a7dddfa0ef38c426622d211df4005fa7/86d46c5a25b77a14f3a4b8aaf1000033
        experimentTitle: 'Bright Focus EPVS manual detection',
        type: 'Agreement analysis',
        workflowId: '8e50a264-7218-4d16-a061-6c6a2e044fb5',
        miniWorkflowSetId: 'a7dddfa0ef38c426622d211df400d3a3',
        experimentId: 'ab9e89fe9b4dcc0c918b498d2b02c623',
        miniWorkflowKey: 'mw1',
        inputAnnotationTableDefinition_key1: 'a7dddfa0ef38c426622d211df4005fa7',
        inputAnnotationFormDefinition_key1: '86d46c5a25b77a14f3a4b8aaf1000033'
      }
    ];
    return (
      <div style={{width:'96%',margin:'2%'}}>
        <span className="reg-headtitle">{"My results"}</span>
        <div className="spineTableStyle">
          <div className='spineRowHeaderStyle'>
            <div className="spineColStyle col4">{"Experiment"}</div>
            <div className="spineColStyle col4">{"Type"}</div>
            <div className="spineColStyle col4">{"Link"}</div>
            <div className="spineColStyle col4">{"Download data"}</div>
          </div>
          <div className="spineTableScrollableContent">
            <div className="spineTableBody">
              {this.renderExperimentsResults(experiments)}
            </div>
          </div>
        </div>
      </div>
    );
  }
};


export default  withTranslation()(Results);
Results.propTypes = {

};