import React from "react";
import {withRouter} from "react-router";
import {ContainerManualTool} from "../containers/ContainerManualTool";
import {
    ContainerTabletGameExperiment} from "../../expDesign/containers/ContainerTabletGameExperiment";
import {ContainerScriptPanel} from "../../expDesign/containers/ContainerScriptPanel";
import {
    ContainerAvatarScatterPlotPanel,
    ContainerMultiAvatarScatterPlotPanel
} from "../../expDesign/containers/ContainerAvatarScatterPlotPanel";
import {ContainerDynamicHistogramDicePanel} from "../../expDesign/containers/ContainerDynamicHistogramDicePanel";
import SampleSizeCalculator from "../../expDesign/component/SampleSizeCalculator";
import AgreementModel from "../../expDesign/component/AgreementModel";
import {ContainerMultiplotPanel} from "../../expDesign/containers/ContainerMultiplotPanel";
import {ContainerResultsPageINOS} from "../containers/ContainerResultsPage";
import {
    ContainerMultiAgreementModelsPanel
} from "../../expDesign/containers/ContainerAgreementModelsPanel";
import {ContainerMultiHistogramPanel} from "../../expDesign/containers/ContainerMultiHistogramPanel";

//================Lazy loading START=============================================================
//Lazy loading with prefetching see https://hackernoon.com/lazy-loading-and-preloading-components-in-react-16-6-804de091c82d

const reactPaintPromise = import("../../vtk/paintbrush/ReactPaint"); //webpack chunk name
const ReactPaint = React.lazy(() => reactPaintPromise); //dynamic import
//================Lazy loading END=============================================================

export async function getConfiguration(name){
    let response = await fetch('/dist/jsonDocs/tools/'+name+'.json',{cache: "reload"});
    const data = await response.json();
    return await data;
}
async function loadRegister() {
    let response = await fetch('/dist/jsonDocs/tools/register.json',{cache: "reload"});
    const data = await response.json();
    return await data;
}

class TaskView extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value:null,
            isLoading:true,
            conf:null,
            sidebarVisible:false,
            overlayOpacity:100,
            fontSize:8,
            markerSize:4
        };
    }
    componentDidMount() {
        const {match} = this.props;
        if (match!=null && match.params !=null && match.params.id !=null ) {
            loadRegister().then(reg=>{

                const conf = reg[match.params.id];
                if (conf['type'] === 'MANUAL_TASK'){
                    getConfiguration(match.params.id).then(data => {
                            this.setState({
                                value: data,
                                isLoading: false,
                                conf:conf
                            })
                        }
                    );
                }
                else{
                    this.setState({
                        isLoading: false,
                        conf:conf
                    })
                }
            });
        }
    }


    render(){
        const {match} = this.props;
        const {conf} = this.state;
        const slideProperties={
            "experimentId": "80e5873b-c6b6-4ecc-8d75-48dba3fd8520",
            "leftRoomId": "nonexpert",
            "rightRoomId": "expert",
            "refreshRate_seconds": "20",
            "triggerBy": "automatic",
            "bins": 4,
            "thresholdMinValue": 190,
            "thresholdMaxValue": 210,
            "resultPropertyName": "volume"
        };

        return (
            <React.Fragment>
                {this.state.isLoading &&
                <div className="spinner-centered" > Loading dataset: <i className="fa fa-spinner fa-spin"/></div>}


                {conf !=null  && !this.state.isLoading && conf['type']==="MANUAL_TASK" &&
                <ContainerManualTool
                    predefinedToolConfiguration={this.state.value}
                    />
                }

                {match.params.id === "resultPage"&&
                <ContainerResultsPageINOS slideProperties={slideProperties}/>}

                {match.params.id === "histogram1"&&
                <ContainerTabletGameExperiment slideProperties={slideProperties}/>}

                {match.params.id === "rEditor" &&
                    <React.Suspense fallback={<p>Loading</p>}>
                        <ContainerScriptPanel/>
                    </React.Suspense>
                }
                {match.params.id === "painter" &&
                    <React.Suspense fallback={<p>Loading</p>}>
                           <ReactPaint style={{background:'tomato'}}/>
                    </React.Suspense>
                }
                {match.params.id === "diceHistogram" &&
                <ContainerDynamicHistogramDicePanel
                    slideProperties={slideProperties}/>
                }

                {match.params.id === "avatarPlot" &&
                <ContainerAvatarScatterPlotPanel slideProperties={slideProperties}/>}

                {match.params.id === "multiplePlot" &&
                <ContainerMultiplotPanel slideProperties={slideProperties}/>}

                {match.params.id === "multipleAvatarPlot" &&
                <ContainerMultiAvatarScatterPlotPanel slideProperties={slideProperties}/>}

                {match.params.id === "multipleBlandAltmanPlot" &&
                <ContainerMultiAgreementModelsPanel experimentProperties={{
                    inputAnnotationTableDefinition_key1: "7e0c58ae948decbb445f6c495e0042b6",
                    inputAnnotationFormDefinition_key1: "b7fc97eb044d96113b87f38b2d000d93",
                    // TODO: Add base uuids for demo
                    lut_key: "656355c2-a771-4f1e-b35f-f800e66d7d57",
                    lutDescription_key: "5a1deda3d848809560e34de6694544fa"
                }} slideProperties={slideProperties}/>}

                {match.params.id === "multipleDynamicHistogram" &&
                <ContainerMultiHistogramPanel
                  slideProperties={slideProperties}
                />}
                {match.params.id === "ssCalculator" &&
                <SampleSizeCalculator />}

                {match.params.id === "agreement" &&
                <AgreementModel />}
            </React.Fragment>
        )
    }

}
export default withRouter(TaskView);
